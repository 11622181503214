import { logGlassbox, buildGlassboxPayload } from '../glassbox.client.logger';
function _log(schemaId, eventType, eventName, params) {
  return logGlassbox(buildGlassboxPayload('Analytics', schemaId, 'PreziGram', eventType, eventName, 'Web', params));
}
export function logAddCollaboratorFailed(params) {
  return _log(17277, 'AddCollaboratorFailed', 'AddCollaboratorFailedWebPreziGram', params);
}
export function logAddCollaborator(params) {
  return _log(23719, 'AddCollaborator', 'AddCollaboratorWebPreziGram', params);
}
export function logAddGramLayout(params) {
  return _log(18789, 'AddGramLayout', 'AddGramLayoutWebPreziGram', params);
}
export function logAddedComment(params) {
  return _log(22553, 'AddedComment', 'AddedCommentWebPreziGram', params);
}
export function logAdjustImageFilter(params) {
  return _log(16547, 'AdjustImageFilter', 'AdjustImageFilterWebPreziGram', params);
}
export function logAppliedBrandkit(params) {
  return _log(23101, 'AppliedBrandkit', 'AppliedBrandkitWebPreziGram', params);
}
export function logApplyBrandkit(params) {
  return _log(23107, 'ApplyBrandkit', 'ApplyBrandkitWebPreziGram', params);
}
export function logBackFromTemplateChooser() {
  return _log(16392, 'BackFromTemplateChooser', 'BackFromTemplateChooserWebPreziGram');
}
export function logChangeColorPickerGradientAngle(params) {
  return _log(25412, 'ChangeColorPickerGradientAngle', 'ChangeColorPickerGradientAngleWebPreziGram', params);
}
export function logChangeColorPickerType(params) {
  return _log(25416, 'ChangeColorPickerType', 'ChangeColorPickerTypeWebPreziGram', params);
}
export function logChangeGramCalloutBehaviour(params) {
  return _log(16538, 'ChangeGramCalloutBehaviour', 'ChangeGramCalloutBehaviourWebPreziGram', params);
}
export function logChangeGramCalloutPosition(params) {
  return _log(16542, 'ChangeGramCalloutPosition', 'ChangeGramCalloutPositionWebPreziGram', params);
}
export function logChangeOrientation(params) {
  return _log(17570, 'ChangeOrientation', 'ChangeOrientationWebPreziGram', params);
}
export function logChangeSlideTransition(params) {
  return _log(23243, 'ChangeSlideTransition', 'ChangeSlideTransitionWebPreziGram', params);
}
export function logChangeTeamAccess(params) {
  return _log(17287, 'ChangeTeamAccess', 'ChangeTeamAccessWebPreziGram', params);
}
export function logChangedAnalyticsTracking(params) {
  return _log(17158, 'ChangedAnalyticsTracking', 'ChangedAnalyticsTrackingWebPreziGram', params);
}
export function logChangedComment(params) {
  return _log(22559, 'ChangedComment', 'ChangedCommentWebPreziGram', params);
}
export function logChangedViewLinkName(params) {
  return _log(17144, 'ChangedViewLinkName', 'ChangedViewLinkNameWebPreziGram', params);
}
export function logChangedViewerIdentification(params) {
  return _log(17169, 'ChangedViewerIdentification', 'ChangedViewerIdentificationWebPreziGram', params);
}
export function logClickCta(params) {
  return _log(17378, 'ClickCta', 'ClickCtaWebPreziGram', params);
}
export function logClickExternalLink(params) {
  return _log(17613, 'ClickExternalLink', 'ClickExternalLinkWebPreziGram', params);
}
export function logClickOnWatermark(params) {
  return _log(18143, 'ClickOnWatermark', 'ClickOnWatermarkWebPreziGram', params);
}
export function logClickUseThisTemplate(params) {
  return _log(28627, 'ClickUseThisTemplate', 'ClickUseThisTemplateWebPreziGram', params);
}
export function logClickedCreateBrandkitCta(params) {
  return _log(23897, 'ClickedCreateBrandkitCta', 'ClickedCreateBrandkitCtaWebPreziGram', params);
}
export function logCloseCollaboratorsDialog(params) {
  return _log(17290, 'CloseCollaboratorsDialog', 'CloseCollaboratorsDialogWebPreziGram', params);
}
export function logCloseColorPicker(params) {
  return _log(25408, 'CloseColorPicker', 'CloseColorPickerWebPreziGram', params);
}
export function logCloseDesignExportToVideoDialog(params) {
  return _log(22202, 'CloseDesignExportToVideoDialog', 'CloseDesignExportToVideoDialogWebPreziGram', params);
}
export function logClosePresenterNotes(params) {
  return _log(26999, 'ClosePresenterNotes', 'ClosePresenterNotesWebPreziGram', params);
}
export function logClosePresenterView(params) {
  return _log(25697, 'ClosePresenterView', 'ClosePresenterViewWebPreziGram', params);
}
export function logClosePrivacyDialog(params) {
  return _log(24028, 'ClosePrivacyDialog', 'ClosePrivacyDialogWebPreziGram', params);
}
export function logCloseWatermarkSidebar(params) {
  return _log(18152, 'CloseWatermarkSidebar', 'CloseWatermarkSidebarWebPreziGram', params);
}
export function logConfigureWatermark(params) {
  return _log(18158, 'ConfigureWatermark', 'ConfigureWatermarkWebPreziGram', params);
}
export function logConfirmExportDesignToVideo(params) {
  return _log(22217, 'ConfirmExportDesignToVideo', 'ConfirmExportDesignToVideoWebPreziGram', params);
}
export function logConnectDesignLiveDataSource(params) {
  return _log(20812, 'ConnectDesignLiveDataSource', 'ConnectDesignLiveDataSourceWebPreziGram', params);
}
export function logContactSupport() {
  return _log(16719, 'ContactSupport', 'ContactSupportWebPreziGram');
}
export function logContinueDesignToVideoOnboardingPopup() {
  return _log(20520, 'ContinueDesignToVideoOnboardingPopup', 'ContinueDesignToVideoOnboardingPopupWebPreziGram');
}
export function logContinueToNewBrandkit() {
  return _log(20544, 'ContinueToNewBrandkit', 'ContinueToNewBrandkitWebPreziGram');
}
export function logCopyPublishLink(params) {
  return _log(17355, 'CopyPublishLink', 'CopyPublishLinkWebPreziGram', params);
}
export function logCopyViewLink(params) {
  return _log(24065, 'CopyViewLink', 'CopyViewLinkWebPreziGram', params);
}
export function logCreateCommentThread(params) {
  return _log(22565, 'CreateCommentThread', 'CreateCommentThreadWebPreziGram', params);
}
export function logCreateNewVideo(params) {
  return _log(20554, 'CreateNewVideo', 'CreateNewVideoWebPreziGram', params);
}
export function logCreatedFromCustomSize(params) {
  return _log(23728, 'CreatedFromCustomSize', 'CreatedFromCustomSizeWebPreziGram', params);
}
export function logCreatedFromTemplate(params) {
  return _log(29439, 'CreatedFromTemplate', 'CreatedFromTemplateWebPreziGram', params);
}
export function logCreatedViewLink(params) {
  return _log(17198, 'CreatedViewLink', 'CreatedViewLinkWebPreziGram', params);
}
export function logDeactivatedViewLink(params) {
  return _log(17178, 'DeactivatedViewLink', 'DeactivatedViewLinkWebPreziGram', params);
}
export function logDeleteComment(params) {
  return _log(22571, 'DeleteComment', 'DeleteCommentWebPreziGram', params);
}
export function logDeleteObject(params) {
  return _log(29097, 'DeleteObject', 'DeleteObjectWebPreziGram', params);
}
export function logDownloadGramObject(params) {
  return _log(18194, 'DownloadGramObject', 'DownloadGramObjectWebPreziGram', params);
}
export function logDownloadGramProject(params) {
  return _log(21008, 'DownloadGramProject', 'DownloadGramProjectWebPreziGram', params);
}
export function logDownloadImage(params) {
  return _log(16739, 'DownloadImage', 'DownloadImageWebPreziGram', params);
}
export function logDownloadVideoApp(params) {
  return _log(28948, 'DownloadVideoApp', 'DownloadVideoAppWebPreziGram', params);
}
export function logDragObject(params) {
  return _log(29103, 'DragObject', 'DragObjectWebPreziGram', params);
}
export function logEditComment(params) {
  return _log(22577, 'EditComment', 'EditCommentWebPreziGram', params);
}
export function logEmptyLibraryOnboarding(params) {
  return _log(20294, 'EmptyLibraryOnboarding', 'EmptyLibraryOnboardingWebPreziGram', params);
}
export function logEmptyLibraryReadMore(params) {
  return _log(20286, 'EmptyLibraryReadMore', 'EmptyLibraryReadMoreWebPreziGram', params);
}
export function logEndCommentThread(params) {
  return _log(22583, 'EndCommentThread', 'EndCommentThreadWebPreziGram', params);
}
export function logExportDesignToVideo(params) {
  return _log(20514, 'ExportDesignToVideo', 'ExportDesignToVideoWebPreziGram', params);
}
export function logGenerateGramAutoLayout(params) {
  return _log(22901, 'GenerateGramAutoLayout', 'GenerateGramAutoLayoutWebPreziGram', params);
}
export function logGroupObjects(params) {
  return _log(16600, 'GroupObjects', 'GroupObjectsWebPreziGram', params);
}
export function logInsertChart(params) {
  return _log(16597, 'InsertChart', 'InsertChartWebPreziGram', params);
}
export function logInsertConnector(params) {
  return _log(16526, 'InsertConnector', 'InsertConnectorWebPreziGram', params);
}
export function logInsertGramElement(params) {
  return _log(17685, 'InsertGramElement', 'InsertGramElementWebPreziGram', params);
}
export function logInsertImage(params) {
  return _log(27507, 'InsertImage', 'InsertImageWebPreziGram', params);
}
export function logInsertIntegration(params) {
  return _log(16522, 'InsertIntegration', 'InsertIntegrationWebPreziGram', params);
}
export function logInsertMap(params) {
  return _log(16535, 'InsertMap', 'InsertMapWebPreziGram', params);
}
export function logInsertMedia(params) {
  return _log(20646, 'InsertMedia', 'InsertMediaWebPreziGram', params);
}
export function logInsertMyLibraryItem(params) {
  return _log(25315, 'InsertMyLibraryItem', 'InsertMyLibraryItemWebPreziGram', params);
}
export function logInsertPresenterNotes(params) {
  return _log(25664, 'InsertPresenterNotes', 'InsertPresenterNotesWebPreziGram', params);
}
export function logInsertShape(params) {
  return _log(16622, 'InsertShape', 'InsertShapeWebPreziGram', params);
}
export function logInsertTextbox(params) {
  return _log(16646, 'InsertTextbox', 'InsertTextboxWebPreziGram', params);
}
export function logInsertVideo(params) {
  return _log(18016, 'InsertVideo', 'InsertVideoWebPreziGram', params);
}
export function logLeaveFullScreen(params) {
  return _log(17403, 'LeaveFullScreen', 'LeaveFullScreenWebPreziGram', params);
}
export function logLikePublishPage(params) {
  return _log(17361, 'LikePublishPage', 'LikePublishPageWebPreziGram', params);
}
export function logLoadedDownloadVideoAppPopup() {
  return _log(22636, 'LoadedDownloadVideoAppPopup', 'LoadedDownloadVideoAppPopupWebPreziGram');
}
export function logLoadedEditor(params) {
  return _log(29450, 'LoadedEditor', 'LoadedEditorWebPreziGram', params);
}
export function logLoadedNewBrandkitPopup() {
  return _log(20539, 'LoadedNewBrandkitPopup', 'LoadedNewBrandkitPopupWebPreziGram');
}
export function logLoadedPage(params) {
  return _log(24097, 'LoadedPage', 'LoadedPageWebPreziGram', params);
}
export function logLoadedPalletsBrandkit(params) {
  return _log(18965, 'LoadedPalletsBrandkit', 'LoadedPalletsBrandkitWebPreziGram', params);
}
export function logLoadedPaywallDialog(params) {
  return _log(29551, 'LoadedPaywallDialog', 'LoadedPaywallDialogWebPreziGram', params);
}
export function logLoadedPresenterView(params) {
  return _log(24172, 'LoadedPresenterView', 'LoadedPresenterViewWebPreziGram', params);
}
export function logLoadedPublicPublishPage(params) {
  return _log(20263, 'LoadedPublicPublishPage', 'LoadedPublicPublishPageWebPreziGram', params);
}
export function logLoadedWatermarkSidebar(params) {
  return _log(18160, 'LoadedWatermarkSidebar', 'LoadedWatermarkSidebarWebPreziGram', params);
}
export function logModifiedPresenterNote(params) {
  return _log(25671, 'ModifiedPresenterNote', 'ModifiedPresenterNoteWebPreziGram', params);
}
export function logModifyGramCallout(params) {
  return _log(16576, 'ModifyGramCallout', 'ModifyGramCalloutWebPreziGram', params);
}
export function logModifyGramPage(params) {
  return _log(22897, 'ModifyGramPage', 'ModifyGramPageWebPreziGram', params);
}
export function logModifyGramProject(params) {
  return _log(16582, 'ModifyGramProject', 'ModifyGramProjectWebPreziGram', params);
}
export function logModifyObjectAnimationSetting(params) {
  return _log(29134, 'ModifyObjectAnimationSetting', 'ModifyObjectAnimationSettingWebPreziGram', params);
}
export function logModifyObjectAnimationTrigger(params) {
  return _log(29138, 'ModifyObjectAnimationTrigger', 'ModifyObjectAnimationTriggerWebPreziGram', params);
}
export function logModifyObject(params) {
  return _log(29130, 'ModifyObject', 'ModifyObjectWebPreziGram', params);
}
export function logModifyTintingIntensity(params) {
  return _log(24431, 'ModifyTintingIntensity', 'ModifyTintingIntensityWebPreziGram', params);
}
export function logNavigateInPresenterView(params) {
  return _log(25702, 'NavigateInPresenterView', 'NavigateInPresenterViewWebPreziGram', params);
}
export function logNavigatePresentationStructureDesignVideoPreview(params) {
  return _log(22212, 'NavigatePresentationStructureDesignVideoPreview', 'NavigatePresentationStructureDesignVideoPreviewWebPreziGram', params);
}
export function logOpenColorPicker(params) {
  return _log(25404, 'OpenColorPicker', 'OpenColorPickerWebPreziGram', params);
}
export function logOpenCommentThread(params) {
  return _log(22591, 'OpenCommentThread', 'OpenCommentThreadWebPreziGram', params);
}
export function logOpenCreateViewLinkDialog(params) {
  return _log(17207, 'OpenCreateViewLinkDialog', 'OpenCreateViewLinkDialogWebPreziGram', params);
}
export function logOpenLearnMoreBrandkit() {
  return _log(18901, 'OpenLearnMoreBrandkit', 'OpenLearnMoreBrandkitWebPreziGram');
}
export function logOpenObjectGramCallout(params) {
  return _log(16587, 'OpenObjectGramCallout', 'OpenObjectGramCalloutWebPreziGram', params);
}
export function logOpenPresenterNotes(params) {
  return _log(27017, 'OpenPresenterNotes', 'OpenPresenterNotesWebPreziGram', params);
}
export function logOpenPresenterView(params) {
  return _log(27177, 'OpenPresenterView', 'OpenPresenterViewWebPreziGram', params);
}
export function logOpenPreziVideoDesktop(params) {
  return _log(22641, 'OpenPreziVideoDesktop', 'OpenPreziVideoDesktopWebPreziGram', params);
}
export function logOpenSocialShare(params) {
  return _log(26672, 'OpenSocialShare', 'OpenSocialShareWebPreziGram', params);
}
export function logOpenTemplatePreview(params) {
  return _log(27698, 'OpenTemplatePreview', 'OpenTemplatePreviewWebPreziGram', params);
}
export function logOpenViewLinkDialog(params) {
  return _log(17187, 'OpenViewLinkDialog', 'OpenViewLinkDialogWebPreziGram', params);
}
export function logOpenWatermarkSidebar(params) {
  return _log(18176, 'OpenWatermarkSidebar', 'OpenWatermarkSidebarWebPreziGram', params);
}
export function logOpenWatermark(params) {
  return _log(18167, 'OpenWatermark', 'OpenWatermarkWebPreziGram', params);
}
export function logPasteObject(params) {
  return _log(29167, 'PasteObject', 'PasteObjectWebPreziGram', params);
}
export function logPausePresenterViewTimer(params) {
  return _log(24188, 'PausePresenterViewTimer', 'PausePresenterViewTimerWebPreziGram', params);
}
export function logPlayDesignToVideoOnboarding() {
  return _log(20526, 'PlayDesignToVideoOnboarding', 'PlayDesignToVideoOnboardingWebPreziGram');
}
export function logPresentInFullScreen(params) {
  return _log(27465, 'PresentInFullScreen', 'PresentInFullScreenWebPreziGram', params);
}
export function logReadMoreOnGenericLibrary() {
  return _log(20311, 'ReadMoreOnGenericLibrary', 'ReadMoreOnGenericLibraryWebPreziGram');
}
export function logRemoveGramCallout(params) {
  return _log(16590, 'RemoveGramCallout', 'RemoveGramCalloutWebPreziGram', params);
}
export function logRemoveGramLayout(params) {
  return _log(18786, 'RemoveGramLayout', 'RemoveGramLayoutWebPreziGram', params);
}
export function logRemoveItemFromLibrary(params) {
  return _log(25321, 'RemoveItemFromLibrary', 'RemoveItemFromLibraryWebPreziGram', params);
}
export function logRemovedComment(params) {
  return _log(22597, 'RemovedComment', 'RemovedCommentWebPreziGram', params);
}
export function logResetImageFilter(params) {
  return _log(16550, 'ResetImageFilter', 'ResetImageFilterWebPreziGram', params);
}
export function logSearchInDesignTemplates(params) {
  return _log(22238, 'SearchInDesignTemplates', 'SearchInDesignTemplatesWebPreziGram', params);
}
export function logSelectColorPickerGradientBar(params) {
  return _log(25420, 'SelectColorPickerGradientBar', 'SelectColorPickerGradientBarWebPreziGram', params);
}
export function logSelectExportBackgroundSetting(params) {
  return _log(22197, 'SelectExportBackgroundSetting', 'SelectExportBackgroundSettingWebPreziGram', params);
}
export function logSelectEyeDrop(params) {
  return _log(24435, 'SelectEyeDrop', 'SelectEyeDropWebPreziGram', params);
}
export function logSelectImageFilterPreset(params) {
  return _log(16726, 'SelectImageFilterPreset', 'SelectImageFilterPresetWebPreziGram', params);
}
export function logSelectMyLibraryAssetCollectionOption(params) {
  return _log(20326, 'SelectMyLibraryAssetCollectionOption', 'SelectMyLibraryAssetCollectionOptionWebPreziGram', params);
}
export function logSelectMyLibraryAssetTypeOption(params) {
  return _log(25058, 'SelectMyLibraryAssetTypeOption', 'SelectMyLibraryAssetTypeOptionWebPreziGram', params);
}
export function logSetCommentVisibility(params) {
  return _log(22603, 'SetCommentVisibility', 'SetCommentVisibilityWebPreziGram', params);
}
export function logSetPrivacy(params) {
  return _log(27967, 'SetPrivacy', 'SetPrivacyWebPreziGram', params);
}
export function logSetTileLayerInDesign(params) {
  return _log(27126, 'SetTileLayerInDesign', 'SetTileLayerInDesignWebPreziGram', params);
}
export function logSharePublish(params) {
  return _log(17674, 'SharePublish', 'SharePublishWebPreziGram', params);
}
export function logShowEmptyLibrary(params) {
  return _log(20298, 'ShowEmptyLibrary', 'ShowEmptyLibraryWebPreziGram', params);
}
export function logShowGramOverflow(params) {
  return _log(18793, 'ShowGramOverflow', 'ShowGramOverflowWebPreziGram', params);
}
export function logShowMyLibraryOptions(params) {
  return _log(20304, 'ShowMyLibraryOptions', 'ShowMyLibraryOptionsWebPreziGram', params);
}
export function logStartPresenterViewTimer(params) {
  return _log(24180, 'StartPresenterViewTimer', 'StartPresenterViewTimerWebPreziGram', params);
}
export function logSwitchPresentationModeDesignVideoPreview(params) {
  return _log(22207, 'SwitchPresentationModeDesignVideoPreview', 'SwitchPresentationModeDesignVideoPreviewWebPreziGram', params);
}
export function logToggleAutoplayPresentation(params) {
  return _log(24521, 'ToggleAutoplayPresentation', 'ToggleAutoplayPresentationWebPreziGram', params);
}
export function logToggleInfiniteLoop(params) {
  return _log(24517, 'ToggleInfiniteLoop', 'ToggleInfiniteLoopWebPreziGram', params);
}
export function logUnlikePublishPage(params) {
  return _log(17375, 'UnlikePublishPage', 'UnlikePublishPageWebPreziGram', params);
}
export function logUpdateCollaborator(params) {
  return _log(23770, 'UpdateCollaborator', 'UpdateCollaboratorWebPreziGram', params);
}
export function logUpgradeToTeam(params) {
  return _log(23869, 'UpgradeToTeam', 'UpgradeToTeamWebPreziGram', params);
}
export function logViewProjectInWebView(params) {
  return _log(16735, 'ViewProjectInWebView', 'ViewProjectInWebViewWebPreziGram', params);
}