import * as React from 'react';
import { StyledViews } from './Styles';
import { Icon } from '../Icon';
export var ViewsButton = props => {
  var viewCount = props.viewCount,
    tooltipText = props.tooltipText,
    className = props.className;
  var altText = props.alt || 'Number of times this content has been viewed';
  if (!!viewCount && viewCount > 0) {
    return React.createElement(StyledViews, {
      title: tooltipText,
      "data-toggle": "tooltip",
      "data-placement": "bottom",
      className: className || ''
    }, React.createElement(Icon, {
      iconUrl: "eye_icon.svg",
      alt: altText
    }), Intl.NumberFormat().format(viewCount));
  }
  return null;
};