import { i18n } from '@lingui/core';
import * as Cookies from 'es-cookie';
import catalogs, { plurals } from './locale';
export var getCurrentLanguage = () => {
  try {
    var _document, _document$documentEle;
    return ((_document = document) === null || _document === void 0 ? void 0 : (_document$documentEle = _document.documentElement) === null || _document$documentEle === void 0 ? void 0 : _document$documentEle.getAttribute('lang')) || Cookies.get('django_language') || 'en';
  } catch (e) {
    return 'en';
  }
};
var i18nInitialized = false;
export function setupI18n() {
  var locale = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : getCurrentLanguage();
  var messages = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : catalogs;
  if (!i18nInitialized) {
    i18n.load(messages);
    i18n.loadLocaleData(plurals);
    i18n.activate(locale);
    i18nInitialized = true;
    return i18n;
  } else {
    return i18n;
  }
}
export var localI18n = setupI18n();