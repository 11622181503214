import "core-js/modules/es.symbol";
import "core-js/modules/es.symbol.description";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import React from 'react';
import ReactCSSTransitionGroup from 'react-addons-css-transition-group';
import { Avatar } from '@prezi/avatar';
import { Title } from './Common';
import { ResponsiveMode } from '../models/common';
import { boldTextStyle, baseTextStyle } from './Styles';
export function MetadataRow(props) {
  var rowStyle = {
    display: 'flex',
    position: 'relative'
  };
  var avatarStyle = {};
  var rightBlockStyle = {
    marginLeft: '9px'
  };
  var ownerLineStyle = _objectSpread(_objectSpread({}, boldTextStyle()), {}, {
    letterSpacing: 1,
    fontSize: 15
  });
  var modifiedDateStyle = _objectSpread(_objectSpread({}, baseTextStyle()), {}, {
    letterSpacing: 1.2
  });
  var _props$owner = props.owner,
    username = _props$owner.username,
    firstName = _props$owner.firstName,
    lastName = _props$owner.lastName;
  return React.createElement("div", {
    className: "viewer-common-metadata-row",
    style: rowStyle
  }, React.createElement("div", {
    className: "viewer-common-owner-avatar",
    style: avatarStyle
  }, React.createElement(Avatar, {
    size: 36,
    username: username,
    firstName: firstName,
    lastName: lastName
  })), React.createElement("div", {
    className: "viewer-common-metadata-right-block",
    style: rightBlockStyle
  }, React.createElement("div", {
    style: ownerLineStyle
  }, props.byOwner), React.createElement("div", {
    style: modifiedDateStyle
  }, props.lastModifiedDateString)));
}
export function MetadataBlock(props) {
  var margin = 0;
  switch (props.responsiveMode) {
    case ResponsiveMode.LARGE:
      margin = 60;
      break;
    case ResponsiveMode.MIDDLE:
      margin = 30;
      break;
    case ResponsiveMode.SMALL:
      margin = 12;
      break;
    default:
      margin = 12;
      break;
  }
  var style = {
    position: 'absolute',
    top: margin,
    left: margin,
    right: margin
  };
  return React.createElement("div", {
    className: "viewer-common-metadata-block",
    style: style
  }, React.createElement(Title, {
    title: props.title,
    responsiveMode: props.responsiveMode
  }), React.createElement(ReactCSSTransitionGroup, {
    transitionName: "viewer-common-metadata",
    transitionAppear: true,
    transitionAppearTimeout: 500,
    transitionEnter: false,
    transitionLeave: false
  }, React.createElement(MetadataRow, {
    byOwner: props.byOwner,
    lastModifiedDateString: props.lastModifiedDateString,
    owner: props.owner
  })));
}