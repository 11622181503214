import React from 'react';
import { SocialNetwork } from '../../api/socialNetwork';
var FacebookSvg = _ref => {
  var size = _ref.size;
  return React.createElement("svg", {
    width: (size === null || size === void 0 ? void 0 : size.width) || '28',
    height: (size === null || size === void 0 ? void 0 : size.height) || '28',
    viewBox: "0 0 28 28",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("path", {
    d: "M28 14C28 6.26719 21.7328 0 14 0C6.26719 0 0 6.26719 0 14C0 20.9891 5.11875 26.7805 11.8125 27.8305V18.0469H8.25781V14H11.8125V10.9156C11.8125 7.40742 13.9016 5.46875 17.1008 5.46875C18.632 5.46875 20.2344 5.74219 20.2344 5.74219V9.1875H18.468C16.7289 9.1875 16.1875 10.2676 16.1875 11.375V14H20.0703L19.4496 18.0469H16.1875V27.8305C22.8813 26.7805 28 20.9891 28 14Z",
    fill: "#1877F2"
  }), React.createElement("path", {
    d: "M19.4496 18.0469L20.0703 14H16.1875V11.375C16.1875 10.2676 16.7289 9.1875 18.468 9.1875H20.2344V5.74219C20.2344 5.74219 18.632 5.46875 17.1008 5.46875C13.9016 5.46875 11.8125 7.40742 11.8125 10.9156V14H8.25781V18.0469H11.8125V27.8305C12.5262 27.9426 13.2562 28 14 28C14.7438 28 15.4738 27.9426 16.1875 27.8305V18.0469H19.4496Z",
    fill: "white"
  }));
};
var TwitterSvg = _ref2 => {
  var size = _ref2.size;
  return React.createElement("svg", {
    width: (size === null || size === void 0 ? void 0 : size.width) || '28',
    height: (size === null || size === void 0 ? void 0 : size.height) || '28',
    viewBox: "0 0 28 28",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("path", {
    d: "M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z",
    fill: "#1DA1F2"
  }), React.createElement("path", {
    d: "M11.4381 21.3847C17.6471 21.3847 21.0421 16.2396 21.0421 11.7806C21.0421 11.6336 21.0421 11.4866 21.0351 11.3466C21.6931 10.8706 22.2671 10.2756 22.7221 9.59665C22.1201 9.86265 21.4691 10.0446 20.7831 10.1286C21.4831 9.70865 22.0151 9.05065 22.2671 8.25965C21.6161 8.64465 20.8951 8.92465 20.1251 9.07865C19.5091 8.42065 18.6341 8.01465 17.6611 8.01465C15.7991 8.01465 14.2871 9.52665 14.2871 11.3886C14.2871 11.6546 14.3151 11.9136 14.3781 12.1586C11.5711 12.0186 9.08606 10.6746 7.42005 8.63065C7.13305 9.12765 6.96505 9.70865 6.96505 10.3246C6.96505 11.4936 7.56005 12.5296 8.47005 13.1316C7.91705 13.1176 7.39905 12.9636 6.94405 12.7116C6.94405 12.7256 6.94405 12.7396 6.94405 12.7536C6.94405 14.3916 8.10605 15.7496 9.65306 16.0646C9.37306 16.1416 9.07206 16.1836 8.76405 16.1836C8.54705 16.1836 8.33705 16.1626 8.12705 16.1206C8.55405 17.4647 9.80006 18.4376 11.2771 18.4656C10.1221 19.3686 8.66605 19.9076 7.08405 19.9076C6.81105 19.9076 6.54505 19.8937 6.27905 19.8587C7.75605 20.8247 9.53406 21.3847 11.4381 21.3847Z",
    fill: "white"
  }));
};
var LinkedInSvg = _ref3 => {
  var size = _ref3.size;
  return React.createElement("svg", {
    width: (size === null || size === void 0 ? void 0 : size.width) || '33',
    height: (size === null || size === void 0 ? void 0 : size.height) || '28',
    viewBox: "0 0 8.7312498 7.4083335",
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("path", {
    d: "M 7.4019059,6.8718837 C 7.4111459,7.2206211 7.0524081,7.451292 6.7298877,7.4016136 4.6523009,7.3997936 2.5745356,7.4052536 0.49706055,7.3988782 0.17358517,7.3791552 -0.04042599,7.0532138 0.00642501,6.7463862 0.00830316,4.65835 0.00266655,2.5701185 0.00924762,0.48220493 0.03604578,0.14430029 0.39031923,-0.03793471 0.69936081,0.00663902 2.7699755,0.00845471 4.8407682,0.00299255 6.9112718,0.00937009 c 0.3235076,0.01972223 0.5374923,0.34565885 0.4906341,0.6524918 0,2.07000581 0,4.14001141 1e-6,6.21001581 z",
    fill: "#006699"
  }), React.createElement("path", {
    fill: "#ffffff",
    d: "M 2.3431062,6.004402 V 2.9081512 H 1.3074407 V 6.004402 Z M 1.8252687,2.4854104 c 0.3611598,0 0.5859472,-0.2377575 0.5859472,-0.5348851 C 2.404493,1.6467101 2.1864276,1.4155485 1.8321193,1.4155485 c -0.3542677,0 -0.5859012,0.2311594 -0.5859012,0.5349768 0,0.297128 0.2247437,0.5348851 0.5723015,0.5348851 h 0.00672 z",
    id: "path12"
  }), React.createElement("path", {
    fill: "#ffffff",
    d: "M 2.9161727,6.004402 H 3.9518291 V 4.2753244 c 0,-0.092539 0.00672,-0.1849853 0.03406,-0.251132 0.074869,-0.1848919 0.2452774,-0.376382 0.5313816,-0.376382 0.3747569,0 0.5246695,0.283936 0.5246695,0.7001699 V 6.004402 h 1.035573 V 4.2290556 c 0,-0.951029 -0.5109406,-1.3935598 -1.1923269,-1.3935598 -0.5587107,0 -0.8039881,0.3103199 -0.9402635,0.5216918 h 0.0069 V 2.9081512 H 2.9162003 c 0.013579,0.2905297 0,3.0962508 0,3.0962508 z",
    id: "path14"
  }), React.createElement("text", {
    y: "7.41",
    x: "7.6677742",
    fontSize: "1.14379px",
    fontFamily: "sans-serif",
    fill: "#006699"
  }, React.createElement("tspan", null, "\xAE")));
};
var GoogleClassroomSvg = _ref4 => {
  var size = _ref4.size;
  return React.createElement("svg", {
    width: (size === null || size === void 0 ? void 0 : size.width) || '33',
    height: (size === null || size === void 0 ? void 0 : size.height) || '28',
    viewBox: "0 0 28 28",
    fill: "none",
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("rect", {
    width: "28",
    height: "28",
    fill: "#FFC112"
  }), React.createElement("rect", {
    x: "2",
    y: "2",
    width: "24",
    height: "24",
    fill: "#21A465"
  }), React.createElement("path", {
    d: "M9.19998 14.8999C8.00169 14.8999 5.59998 15.4264 5.59998 16.4749V17.5999H12.8V16.4749C12.8 15.4264 10.3983 14.8999 9.19998 14.8999Z",
    fill: "#57BB8A"
  }), React.createElement("circle", {
    cx: "9.20002",
    cy: "12.1999",
    r: "1.8",
    fill: "#57BB8A"
  }), React.createElement("path", {
    d: "M18.8 14.8999C17.6017 14.8999 15.2 15.4264 15.2 16.4749V17.5999H22.4V16.4749C22.4 15.4264 19.9982 14.8999 18.8 14.8999Z",
    fill: "#57BB8A"
  }), React.createElement("ellipse", {
    cx: "18.8",
    cy: "12.1999",
    rx: "1.8",
    ry: "1.8",
    fill: "#57BB8A"
  }), React.createElement("path", {
    d: "M14 14C12.4022 14 9.19995 14.702 9.19995 16.1V17.6H18.7999V16.1C18.7999 14.702 15.5977 14 14 14Z",
    fill: "white"
  }), React.createElement("ellipse", {
    cx: "14",
    cy: "10.4",
    rx: "2.4",
    ry: "2.4",
    fill: "white"
  }), React.createElement("rect", {
    x: "16",
    y: "24",
    width: "7",
    height: "2",
    fill: "#F1F1F1"
  }));
};
export var SocialNetworkIcon = _ref5 => {
  var network = _ref5.network,
    size = _ref5.size;
  switch (network) {
    case SocialNetwork.FACEBOOK:
      return React.createElement(FacebookSvg, {
        size: size
      });
    case SocialNetwork.LINKEDIN:
      return React.createElement(LinkedInSvg, {
        size: size
      });
    case SocialNetwork.TWITTER:
      return React.createElement(TwitterSvg, {
        size: size
      });
    case SocialNetwork.GOOGLE_CLASSROOM:
      return React.createElement(GoogleClassroomSvg, {
        size: size
      });
    default:
      return null;
  }
};