function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }
function _defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, _toPropertyKey(descriptor.key), descriptor); } }
function _createClass(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); Object.defineProperty(Constructor, "prototype", { writable: false }); return Constructor; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function"); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, writable: true, configurable: true } }); Object.defineProperty(subClass, "prototype", { writable: false }); if (superClass) _setPrototypeOf(subClass, superClass); }
function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }
function _possibleConstructorReturn(self, call) { if (call && (typeof call === "object" || typeof call === "function")) { return call; } else if (call !== void 0) { throw new TypeError("Derived constructors may only return object or undefined"); } return _assertThisInitialized(self); }
function _assertThisInitialized(self) { if (self === void 0) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return self; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
function _getPrototypeOf(o) { _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function _getPrototypeOf(o) { return o.__proto__ || Object.getPrototypeOf(o); }; return _getPrototypeOf(o); }
import { Trans } from "@lingui/react";
import styled from 'styled-components';
import React from 'react';
import { Sparkles } from './Sparkles';
import { NavBarButton } from '@prezi/header';
var AIBannerBreakpoint = '740px';
var Wrapper = styled.div.withConfig({
  displayName: "PromoteAIBanner__Wrapper",
  componentId: "sc-1i6lv71-0"
})(["position:relative;padding:12px 32px 14px 32px;background:linear-gradient(90deg,#008a9f 0%,#044e80 100%);letter-spacing:0.2px;z-index:5;color:white;display:grid;grid-template-columns:auto 140px;column-gap:16px;@media (max-width:", "){grid-template-columns:unset;grid-template-rows:auto;& > #see-how-ai{border-radius:3px;width:100%;place-self:end center !important;}}"], AIBannerBreakpoint);
var Text = styled.div.withConfig({
  displayName: "PromoteAIBanner__Text",
  componentId: "sc-1i6lv71-1"
})(["margin:0;"]);
var Line = styled.div.withConfig({
  displayName: "PromoteAIBanner__Line",
  componentId: "sc-1i6lv71-2"
})(["display:flex;@media (max-width:", "){flex-wrap:wrap;}"], AIBannerBreakpoint);
var Detail = styled.p.withConfig({
  displayName: "PromoteAIBanner__Detail",
  componentId: "sc-1i6lv71-3"
})(["font-size:16px;margin-top:8px;margin-bottom:0;text-align:left;"]);
var InnerText = styled.p.withConfig({
  displayName: "PromoteAIBanner__InnerText",
  componentId: "sc-1i6lv71-4"
})(["font-size:16px;font-weight:bolder;margin:auto;padding-top:2px;"]);
var AILogo = styled.div.withConfig({
  displayName: "PromoteAIBanner__AILogo",
  componentId: "sc-1i6lv71-5"
})(["height:30px;display:inline-flex;padding:2px 0 4px 0;border:0;border-radius:4px;background:none;text-align:center;position:relative;top:-2px;left:-4px;"]);
var AI = styled.b.withConfig({
  displayName: "PromoteAIBanner__AI",
  componentId: "sc-1i6lv71-6"
})(["font-weight:bold;padding-top:2px;"]);
var TextLeft = styled(InnerText).withConfig({
  displayName: "PromoteAIBanner__TextLeft",
  componentId: "sc-1i6lv71-7"
})(["margin:0;"]);
var TextRight = styled(InnerText).withConfig({
  displayName: "PromoteAIBanner__TextRight",
  componentId: "sc-1i6lv71-8"
})(["margin:0;"]);
var AiButton = styled(NavBarButton).withConfig({
  displayName: "PromoteAIBanner__AiButton",
  componentId: "sc-1i6lv71-9"
})(["height:auto;align-self:center;justify-self:end;background:#000 !important;border-color:#000 !important;margin-top:8px;margin-bottom:8px;"]);
export var PromoteAIBanner = function (_React$Component) {
  _inherits(PromoteAIBanner, _React$Component);
  var _super = _createSuper(PromoteAIBanner);
  function PromoteAIBanner() {
    _classCallCheck(this, PromoteAIBanner);
    return _super.apply(this, arguments);
  }
  _createClass(PromoteAIBanner, [{
    key: "render",
    value: function render() {
      var onClickSeeHow = event => {
        event.preventDefault();
        window.location.href = 'https://prezi.com/features/ai/';
      };
      return React.createElement(Wrapper, null, React.createElement(Text, null, React.createElement(Line, null, React.createElement(TextLeft, null, React.createElement(Trans, {
        id: "Introducing"
      }), "\xA0"), React.createElement(AILogo, null, React.createElement(Sparkles, null), React.createElement(AI, null, "Prezi AI.")), React.createElement(TextRight, null, React.createElement(Trans, {
        id: "Your new presentation assistant."
      }))), React.createElement(Detail, null, React.createElement(Trans, {
        id: "Refine, enhance, and tailor your content, source relevant images, and edit visuals quicker than ever before."
      }))), React.createElement(AiButton, {
        id: 'see-how-ai',
        onClick: onClickSeeHow
      }, React.createElement(Trans, {
        id: "See how \u2192"
      })));
    }
  }]);
  return PromoteAIBanner;
}(React.Component);