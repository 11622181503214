import styled from 'styled-components';
import { Colors } from '@prezi/dragon';
export var CreatorBlock = styled.div.attrs({
  className: 'creator-block'
}).withConfig({
  displayName: "Styles__CreatorBlock",
  componentId: "sc-12sux8y-0"
})(["display:flex;flex-direction:row;margin-bottom:22px;.user-avatar{margin-right:12px;}"]);
export var AuthorNameAndCreationDate = styled.div.attrs({
  className: 'details-author-and-date'
}).withConfig({
  displayName: "Styles__AuthorNameAndCreationDate",
  componentId: "sc-12sux8y-1"
})(["display:flex;flex-direction:column;"]);
export var CreatedBy = styled.h3.attrs({
  className: 'details-created-by'
}).withConfig({
  displayName: "Styles__CreatedBy",
  componentId: "sc-12sux8y-2"
})(["font-size:16px;font-weight:bold;line-height:24px;color:", ";margin:0;"], Colors.neutral500);
export var CreatedAt = styled.p.attrs({
  className: 'details-created-at'
}).withConfig({
  displayName: "Styles__CreatedAt",
  componentId: "sc-12sux8y-3"
})(["font-size:13px;line-height:18px;color:", ";margin:0;"], Colors.neutral500);
export var UserAvatarLink = styled.a.attrs({
  className: 'user-avatar-link'
}).withConfig({
  displayName: "Styles__UserAvatarLink",
  componentId: "sc-12sux8y-4"
})(["border-radius:50%;text-decoration:none;margin-right:12px;.user-avatar{margin-right:0;}:hover{color:initial;text-decoration:none;}"]);