import "core-js/modules/es.symbol";
import "core-js/modules/es.symbol.description";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }
function _defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, _toPropertyKey(descriptor.key), descriptor); } }
function _createClass(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); Object.defineProperty(Constructor, "prototype", { writable: false }); return Constructor; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import { ClientLogger, LogLevel } from '@prezi/client-logger-module';
export var isDevelopmentEnv = () => {
  var hostname = window.location.hostname;
  return hostname === 'localhost' || hostname.endsWith('prezi.dev');
};
export var StructuredLogger = function () {
  function StructuredLogger() {
    _classCallCheck(this, StructuredLogger);
    var clientLoggingConfig = {
      logApiEndpoint: '//log.prezi.com/log/json',
      defaultLogLevel: LogLevel.INFO,
      defaultSource: '',
      isProduction: !isDevelopmentEnv()
    };
    this.clientLogger = new ClientLogger(clientLoggingConfig);
  }
  _createClass(StructuredLogger, [{
    key: "log",
    value: function log(message) {
      return this.clientLogger.log(message);
    }
  }]);
  return StructuredLogger;
}();