import * as React from 'react';
import { StyledCurationButton } from './Styles';
var TagSvg = () => {
  return React.createElement("svg", {
    width: "24",
    height: "24",
    viewBox: "0 0 24 24",
    xmlns: "http://www.w3.org/2000/svg"
  }, React.createElement("path", {
    fillRule: "evenodd",
    clipRule: "evenodd",
    d: "M4.69663 6.30386C4.5091 6.49139 4.40374 6.74575 4.40374 7.01096V11.1111C4.40374 11.3763 4.50909 11.6307 4.69663 11.8182L12.7323 19.8539C13.1229 20.2445 13.756 20.2445 14.1466 19.8539L20.1252 13.8753C20.5157 13.4848 20.5157 12.8516 20.1252 12.4611L12.0895 4.42536C11.902 4.23782 11.6476 4.13246 11.3824 4.13246L7.28224 4.13246C7.01702 4.13246 6.76267 4.23782 6.57513 4.42536L4.69663 6.30386ZM9.74305 9.47189C10.4235 8.7914 10.4235 7.6881 9.74305 7.00761C9.06256 6.32711 7.95926 6.32711 7.27876 7.00761C6.59827 7.6881 6.59827 8.7914 7.27876 9.47189C7.95926 10.1524 9.06256 10.1524 9.74305 9.47189Z"
  }));
};
export var CurationButton = props => {
  var onClick = props.onClick,
    curationExists = props.curationExists,
    tooltipText = props.tooltipText;
  return React.createElement(StyledCurationButton, {
    onClick: onClick,
    curationExists: curationExists,
    "data-toggle": "tooltip",
    title: tooltipText,
    "data-placement": "bottom"
  }, React.createElement(TagSvg, null));
};