import * as React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { glassboxWebJs as glassboxLogger } from '@prezi/client-logger-module';
import { getSize, ResponsiveMode, Overlay } from '@prezi/common-components';
import { Colors } from '@prezi/dragon';

import { HeaderProps, SearchBarData, SearchTermsContainerData } from '../../models/header';
import SearchBar, { SEARCH_BAR_TEXT_INPUT_ID } from '../SearchBar/SearchBar';
import { SearchBarButton, SearchBarButtonSpan, SearchIcon } from '../SearchBar/Styles';
import { SearchSuggestionsContainer } from '../SearchSuggestionsContainer/SearchSuggestionsContainer';
import {
    BackButtonLink,
    DownArrowNav,
    LoggedInNavName,
    LoginLink,
    LogoLink,
    NavBarContainer,
    NavBarLinks,
    NavBarLinksWrapper,
    Row,
} from './Styles';
import { BackSvg } from './BackSvg';
import { NameContainer, DropdownMenuItem, DropdownMenu } from '../DropdownStyles';

export interface SearchTerms {
    'sales-marketing': Array<string>;
    teacher: Array<string>;
    student: Array<string>;
    other: Array<string>;
    [key: string]: Array<string>;
}

export interface SearchHeaderProps extends HeaderProps {
    searchBarData: SearchBarData;
    searchTerms?: SearchTerms;
    authorSegment?: string;
    searchTermsContainerData: SearchTermsContainerData;
    CTAButton: React.ReactNode;
    productType: 'PRESENT' | 'VIDEO' | 'GRAM';
    showSearchOnMobile: boolean;
}

export interface SearchHeaderState {
    toggleMenu: boolean;
    mobileSearchExpanded: boolean;
}

export class SearchHeader extends React.Component<SearchHeaderProps, SearchHeaderState> {
    private node: any;
    private inputRef: HTMLElement | null;
    private searchBarButtonRef: HTMLButtonElement | null;

    constructor(props: SearchHeaderProps) {
        super(props);
        this.state = {
            toggleMenu: false,
            mobileSearchExpanded: false,
        };
        this.inputRef = null;
        this.searchBarButtonRef = null;
    }

    onClickLogin = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        const targetUrl = '/login/';
        const params: glassboxLogger.LogClickCtaParams = {
            cta_location: 'header',
            cta_text: this.props.links.loginLinkText,
            cta_type: 'LINK',
            target_url: targetUrl,
        };
        glassboxLogger
            .logVisitPricingPage(params)
            .then(() => {
                window.location.assign(targetUrl);
            })
            .catch(() => {
                window.location.assign(targetUrl);
            });
    };

    onClickPreziLogo = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        const targetUrl = '/';
        glassboxLogger
            .logClickPreziLogo()
            .then(() => {
                window.location.assign(targetUrl);
            })
            .catch(() => {
                window.location.assign(targetUrl);
            });
    };

    toggleDropdownMenu = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        const { toggleMenu } = this.state;
        this.setState({ toggleMenu: !toggleMenu });
    };

    onOutsideClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        if (this.node.contains(event.target)) {
            return;
        }

        const { toggleMenu } = this.state;
        if (toggleMenu) {
            this.setState({ toggleMenu: false });
        }
    };

    onClickSearchInput = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        event.preventDefault();
        glassboxLogger.logSearchPrezisClicked();
    };

    onQuerySubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        const { mobileSearchExpanded } = this.state;

        if (getSize(window.innerWidth) === ResponsiveMode.EXTRA_SMALL && !mobileSearchExpanded) {
            this.setSearchExpanded(true);
            if (this.inputRef) {
                this.inputRef.focus();
            }
            return;
        }

        // TODO: we should use react hooks for getting the input from the search bar text field
        const searchBarTextInput = document.getElementById(SEARCH_BAR_TEXT_INPUT_ID) as HTMLInputElement;
        const searchQuery = (searchBarTextInput && searchBarTextInput.value) || '';
        const route = searchQuery === '' ? '' : `search/?search=${encodeURIComponent(searchQuery)}`;
        const logParams: glassboxLogger.LogSearchPresentationsParams = {
            search_term: searchQuery,
            search_type: this.props.productType,
        };
        glassboxLogger
            .logSearchPresentations(logParams)
            .then(() => {
                window.location.href = `/explore/${route}`;
            })
            .catch(() => {
                window.location.href = `/explore/${route}`;
            });
    };

    setSearchExpanded = (expanded: boolean) => {
        if (!expanded) {
            this.searchBarButtonRef?.focus();
        }
        this.setState({ mobileSearchExpanded: expanded });
    };

    getLogo = () => {
        const { onBackClickedCallback, showSearchOnMobile } = this.props;
        const { mobileSearchExpanded } = this.state;
        const hasOnBackClickedCallback = onBackClickedCallback != null;

        if (mobileSearchExpanded) {
            return null;
        }

        // tslint:disable:max-line-length
        const src =
            'https://assets.prezicdn.net/assets-versioned/prezipage-versioned/861-fb42ede1750ea41fa02b2db7a244354b83be3f80/common/img/logo/prezi-logo-white.svg?a7dddf476313';
        return (
            <LogoLink
                showSearchOnMobile={showSearchOnMobile}
                className={hasOnBackClickedCallback ? 'has-back-button' : ''}
            >
                <a
                    href="/"
                    onClick={this.onClickPreziLogo}
                    title={this.props.searchBarData.homePageLinkTitle ?? 'Link to the prezi homepage'}
                >
                    <img src={src} className="logo-img" alt="Prezi logo" role="link" />
                </a>
            </LogoLink>
        );
    };

    getNavBarLinks = () => {
        const { loggedIn, viewerInfo, links } = this.props;
        const { toggleMenu, mobileSearchExpanded } = this.state;

        const dropDownClass = toggleMenu ? 'open-pull-down' : 'close-pull-down';

        if (mobileSearchExpanded) {
            return null;
        }

        if (loggedIn) {
            return (
                <NavBarLinks>
                    <NavBarLinksWrapper>
                        <div
                            className="dropdown"
                            role="menu"
                            ref={node => (this.node = node)}
                            onClick={this.toggleDropdownMenu}
                        >
                            <div data-toggle="dropdown">
                                <NameContainer>
                                    <LoggedInNavName className="name">
                                        {viewerInfo && viewerInfo.viewerName}
                                    </LoggedInNavName>
                                    <DownArrowNav className="icon-triangle-down" />
                                </NameContainer>
                            </div>
                        </div>
                        <div>
                            <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
                                <DropdownMenu className={dropDownClass}>
                                    <div className="top-triangle-with-shadow" />
                                    <div className="logged-in-dropdown-menu">
                                        <DropdownMenuItem
                                            className="your-page"
                                            href="/dashboard/next/?click_source=logged_element&amp;page_location=header_dropdown&amp;element_type=link&amp;element_text=my_prezis"
                                        >
                                            {links.myPrezisLinkText}
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                            className="settings-page"
                                            href="/settings/?click_source=logged_element&amp;page_location=header_dropdown&amp;element_type=link&amp;element_text=account_settings"
                                        >
                                            {links.accountSettingsLinkText}
                                        </DropdownMenuItem>
                                        <DropdownMenuItem
                                            className="logout-page"
                                            data-logout="true"
                                            data-click-source="logged_element&amp;page_location=header_dropdown&amp;element_text=logout"
                                            href="/logout"
                                        >
                                            {links.logoutLinkText}
                                        </DropdownMenuItem>
                                    </div>
                                </DropdownMenu>
                            </OutsideClickHandler>
                        </div>
                    </NavBarLinksWrapper>
                </NavBarLinks>
            );
        }

        return (
            <NavBarLinks>
                <NavBarLinksWrapper>
                    <LoginLink href="/login" onClick={this.onClickLogin}>
                        {links.loginLinkText}
                    </LoginLink>
                    {this.props.CTAButton}
                </NavBarLinksWrapper>
            </NavBarLinks>
        );
    };

    receiveInputRef = (ref: HTMLElement | null) => {
        if (ref) {
            this.inputRef = ref;
        }
    };

    receiveSearchBarButtonRef = (ref: HTMLButtonElement | null) => {
        if (ref) {
            this.searchBarButtonRef = ref;
        }
    };

    render() {
        const {
            authorSegment,
            searchTerms,
            searchBarData,
            searchTermsContainerData,
            onBackClickedCallback,
            productType,
            showSearchOnMobile,
        } = this.props;
        const { mobileSearchExpanded } = this.state;
        const suggestedSearchTerms: Array<string> = searchTerms
            ? authorSegment
                ? searchTerms[authorSegment]
                : []
            : [];

        const hasOnBackClickedCallback = onBackClickedCallback != null;
        return (
            <>
                <Overlay isOpen={mobileSearchExpanded} />
                <NavBarContainer className="btm-24" id="prezi-next-search-header">
                    <Row className="btm-12">
                        {this.getLogo()}
                        {hasOnBackClickedCallback && !mobileSearchExpanded && (
                            <BackButtonLink>
                                <a onClick={onBackClickedCallback}>
                                    <BackSvg />
                                </a>
                            </BackButtonLink>
                        )}
                        <SearchBar
                            onClickSearchInput={this.onClickSearchInput}
                            onQuerySubmit={this.onQuerySubmit}
                            textInputHeight={'49px'}
                            searchBarPlaceholderText={searchBarData.searchBarPlaceholder}
                            searchBarPlaceholderTextShort={searchBarData.searchBarPlaceholderShort}
                            searchBarButtonText={searchBarData.searchBarButton}
                            searchTerm={searchBarData.searchTerm}
                            showSearchOnMobile={showSearchOnMobile}
                            mobileSearchExpanded={mobileSearchExpanded}
                            setSearchExpanded={this.setSearchExpanded}
                            receiveInputRef={this.receiveInputRef}
                            searchBarButton={
                                <SearchBarButtonSpan className="input-group-btn" right={'50px'} top={'6px'}>
                                    <SearchBarButton
                                        ref={this.receiveSearchBarButtonRef}
                                        className="btn-xs search-btn"
                                        height={'37px'}
                                        type="button"
                                        onClick={this.onQuerySubmit}
                                        name={this.props.searchBarData.startSearchLabel ?? 'Start search'}
                                        aria-label={this.props.searchBarData.startSearchLabel ?? 'Start search'}
                                    >
                                        <span>
                                            <SearchIcon
                                                className="btn-icon icon-search"
                                                color={Colors.white}
                                                fontSize={'18px'}
                                                fontWeight={'600'}
                                            />
                                        </span>
                                    </SearchBarButton>
                                </SearchBarButtonSpan>
                            }
                            closeSearchBarButtonLabel={this.props.searchBarData.closeSearchBarButtonLabel}
                            startSearchLabel={this.props.searchBarData.startSearchLabel}
                            searchBarLabel={this.props.searchBarData.searchBarLabel}
                        />
                        {this.getNavBarLinks()}
                    </Row>
                    {!!suggestedSearchTerms.length && (
                        <SearchSuggestionsContainer
                            searchTermsContainerString={searchTermsContainerData.searchTermsContainerString}
                            suggestedSearchTerms={suggestedSearchTerms}
                            productType={productType}
                        />
                    )}
                </NavBarContainer>
            </>
        );
    }
}
