import styled from 'styled-components';
export var TooltipArrow = styled.div.withConfig({
  displayName: "Styles__TooltipArrow",
  componentId: "sc-934i1o-0"
})(["top:50%;left:0;margin-top:-5px;border-width:5px 5px 5px 0;border-right-color:rgba(21,34,53,0.8);position:absolute;width:0;height:0;border-color:transparent;border-right-color:transparent;border-style:solid;"]);
export var TooltipInnerContent = styled.div.withConfig({
  displayName: "Styles__TooltipInnerContent",
  componentId: "sc-934i1o-1"
})(["max-width:200px;padding:4px 10px;color:#fff;text-align:center;background-color:rgba(21,34,53,0.8);border-radius:4px;"]);
export var TooltipDiv = styled.div.withConfig({
  displayName: "Styles__TooltipDiv",
  componentId: "sc-934i1o-2"
})(["position:relative;font-size:12px;line-height:16px;text-align:left;text-align:start;text-shadow:none;text-transform:none;white-space:normal;word-break:normal;word-spacing:normal;word-wrap:normal;font-size:12px;letter-spacing:0.5px;font-weight:bold;&.on{display:inline-block;}&.off{display:none;}&.top{margin-bottom:5px;& ", "{top:auto;bottom:-6px;left:50%;margin-left:-5px;border-width:5px 5px 0;border-top-color:rgba(21,34,53,0.8);}}&.right{margin-left:5px;& ", "{top:50%;left:auto;margin-left:-5px;border-width:5px 5px 5px 0;border-right-color:rgba(21,34,53,0.8);}}&.bottom{margin-top:5px;& ", "{top:0;left:50%;margin-left:-5px;border-width:0 5px 5px;border-bottom-color:rgba(21,34,53,0.8);}}&.left{margin-right:5px;& ", "{top:50%;margin-top:-5px;border-width:5px 0 5px 5px;border-left-color:rgba(21,34,53,0.8);right:-6px;left:auto;}}& .", "{max-width:200px;padding:4px 10px;color:#fff;text-align:center;background-color:rgba(21,34,53,0.8);border-radius:4px;}"], TooltipArrow, TooltipArrow, TooltipArrow, TooltipArrow, TooltipInnerContent);