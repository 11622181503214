import * as React from 'react';
import styled from 'styled-components';

import { glassboxWebPrezigram } from '@prezi/client-logger-module';
import { WindowSizeBreakpoints } from '@prezi/common-components';

import * as Features from '../features/Features';
import { WrappedToolbox as Toolbox } from './Toolbox/Toolbox';
import { DesignPageContext, DesignViewPageModal } from '../context';
import { Viewer } from '../components/Viewer';
import { VIEWER_ID } from '../constants';
import { ModalProvider } from 'styled-react-modal';
import { EmbedGeneratorContainer } from './modals/EmbedGeneratorContainer';
import { FullScreenOverlay } from './modals/FullScreenOverlay';

/*
 * The single entry point component to the application application's DOM.
 */

const MainContent = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
`;

const ViewerAndToolboxWrapper = styled.div`
    margin-top: 12px;
    width: 100%;

    @media (min-width: ${WindowSizeBreakpoints.MEDIUM}px) and (max-width: ${WindowSizeBreakpoints.LARGE}px) {
        margin: 0px 12px;
    }

    @media (min-width: ${WindowSizeBreakpoints.LARGE}px) {
        width: 940px;
    }

    #${VIEWER_ID} {
        margin-bottom: 0px;

        @media (min-width: ${WindowSizeBreakpoints.MEDIUM}px) {
            margin-bottom: 12px;
        }

        @media (min-width: ${WindowSizeBreakpoints.LARGE}px) {
            margin-bottom: 18px;
        }
    }
`;

export class Page extends React.Component<{}> {
    static contextType = DesignPageContext;
    declare context: React.ContextType<typeof DesignPageContext>;
    private currentOrientation: string;

    constructor(props: {}) {
        super(props);

        this.currentOrientation = this.getCurrentOrientation();
    }

    private getCurrentOrientation() {
        return window.innerHeight > window.innerWidth ? 'portrait' : 'landscape';
    }

    componentDidMount() {
        if (Features.isActive('bb-track-orientation-changes-on-view-pages')) {
            window.addEventListener('resize', this.handleChangeOrientation);
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleChangeOrientation);
    }

    handleChangeOrientation = () => {
        const orientation = this.getCurrentOrientation();
        if (this.currentOrientation !== orientation) {
            this.currentOrientation = orientation;

            glassboxWebPrezigram.logChangeOrientation({
                prezi_oid: this.context.presentationMetadata.oid,
                screen_orientation: orientation,
            });
        }
    };

    render() {
        const { presentationMetadata, linkID, modalManager, activeModal } = this.context;
        return (
            <ModalProvider>
                <MainContent id="main-content">
                    <ViewerAndToolboxWrapper id="viewer-toolbox-wrapper" data-lookup="design-viewer-toolbox-wrapper">
                        <Viewer oid={presentationMetadata.oid} linkID={linkID} />
                        <Toolbox />
                    </ViewerAndToolboxWrapper>
                    {modalManager.renderModals()}
                    <FullScreenOverlay isVisible={activeModal === DesignViewPageModal.EMBED_MODAL}>
                        <EmbedGeneratorContainer preziOid={linkID || presentationMetadata.oid} />
                    </FullScreenOverlay>
                </MainContent>
            </ModalProvider>
        );
    }
}
