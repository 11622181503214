function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
import * as React from 'react';
import { ShareButton as StyledShareButton } from './Styles';
import { Icon } from '../Icon';
export var ShareButton = props => {
  var dataAttributes = {
    'data-lookup': props.isMobile ? 'mobile-share' : 'desktop-share-flyout',
    className: props.isMobile ? 'mobile-share-button' : 'desktop-share-button'
  };
  if (!props.isMobile) {
    dataAttributes['data-toggle'] = 'tooltip';
    dataAttributes['data-placement'] = 'bottom';
  }
  var altText = props.alt || 'Button to share content';
  return React.createElement(StyledShareButton, _extends({
    onClick: props.onClick,
    title: props.tooltipText
  }, dataAttributes), React.createElement(Icon, {
    iconUrl: "share_icon.svg",
    alt: altText
  }));
};