import "core-js/modules/es.symbol";
import "core-js/modules/es.symbol.description";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import * as React from 'react';
export function baseTextStyle() {
  return {
    fontFamily: 'RalewayRegular, Helvetica, sans-serif',
    lineHeight: 1.38,
    letterSpacing: '0.5px',
    textAlign: 'left',
    color: '#ffffff',
    fontSize: '13px'
  };
}
export function mediumTextStyle() {
  return _objectSpread(_objectSpread({}, baseTextStyle()), {}, {
    fontFamily: 'RalewayMedium, Helvetica, sans-serif'
  });
}
export function boldTextStyle() {
  return _objectSpread(_objectSpread({}, baseTextStyle()), {}, {
    fontFamily: 'RalewayBold, Helvetica, sans-serif'
  });
}
export function commonAnimationKeyframes() {
  return React.createElement('style', {}, '' + '    @keyframes rotate {\n' + '        from {\n' + '            transform: rotate(0);\n' + '        }\n' + '        to {\n' + '            transform: rotate(360deg);\n' + '        }\n' + '    }\n' + '\n' + '    @keyframes fade-in {\n' + '        from {\n' + '            opacity: 0.6;\n' + '        }\n' + '        to {\n' + '            opacity: 1;\n' + '        }\n' + '    }\n');
}
export function hoverStyles() {
  var infoOverlayFilledBtnClass = 'viewer-common-info-overlay-button-filled';
  var infoOverlayFilledBtnHoverStyle = 'background: rgba(25, 102, 204, 1) !important;';
  var infoOverlayTransparentBtnClass = 'viewer-common-info-overlay-button-transparent';
  var infoOverlayTransparentBtnHoverStyle = 'background: rgba(255, 255, 255, .0980392) !important;';
  return React.createElement('style', {}, `
        .${infoOverlayFilledBtnClass}:hover { ${infoOverlayFilledBtnHoverStyle} }
        .${infoOverlayTransparentBtnClass}:hover { ${infoOverlayTransparentBtnHoverStyle} }
    `);
}
export function infoOverlayTransitions() {
  return React.createElement('style', {}, `
        .viewer-common-metadata-appear {
            opacity: .01;
            top: -20px;
        }
        .viewer-common-metadata-appear.viewer-common-metadata-appear-active {
            opacity: 1;
            top: 0;
            transition: opacity .5s ease-in, top .5s ease;
        }
        .viewer-common-info-overlay {
            pointer-events: auto;
        }
        .viewer-common-info-overlay .viewer-common-info-overlay-center-block {
            pointer-events: auto;
        }
        .viewer-common-info-overlay-transition-enter {
            opacity: .01;
        }
        .viewer-common-info-overlay-transition-enter.viewer-common-info-overlay-transition-enter-active {
            opacity: 1;
            transition: opacity .5s ease;
        }
        .viewer-common-info-overlay-transition-leave {
            opacity: 1;
        }
        .viewer-common-info-overlay-transition-leave.viewer-common-info-overlay-transition-leave-active {
            opacity: .01;
            transition: opacity .5s ease;
        `);
}
var baseForegroundStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0
};
export var foregroundStyle = _objectSpread({
  zIndex: 3
}, baseForegroundStyle);
export var centeringBlockStyle = _objectSpread({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'none'
}, baseForegroundStyle);