import "core-js/modules/es.array.index-of";
import "core-js/modules/es.array.iterator";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.promise";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.replace";
import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/web.url";
import "regenerator-runtime/runtime";
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }
function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }
import * as Cookie from 'es-cookie';
export var LIKE_API_ENDPOINT = '/api/v4/presentation/{oid}/like/';
export var LIKE_WITH_VIEW_LINK_API_ENDPOINT = '/api/v4/presentation/like/?auth_key={view_link_id}&key_type=prezilink';
export var getCsrfToken = () => {
  var csrfToken = Cookie.get('csrftoken');
  if (!csrfToken) {
    throw new Error('Cannot make request without CSRF token.');
  }
  return csrfToken;
};
export var getRequestHeaders = () => {
  var requestHeaders = {
    'Content-Type': 'application/json',
    'X-CSRFToken': getCsrfToken()
  };
  if (window.location.hostname.indexOf('preprod.prezi') !== -1) {
    var url = new URL(window.location.origin);
    var password = url.password;
    var username = url.username;
    url.password = '';
    url.username = '';
    requestHeaders.Authorization = `Basic ${btoa(username + ':' + password)}`;
  }
  return requestHeaders;
};
export var getFormattedUrl = (oid, viewLinkId) => {
  var path = !!viewLinkId ? LIKE_WITH_VIEW_LINK_API_ENDPOINT.replace('{view_link_id}', viewLinkId) : LIKE_API_ENDPOINT.replace('{oid}', oid);
  return new URL(window.location.origin + path);
};
export var like = function () {
  var _ref = _asyncToGenerator(regeneratorRuntime.mark(function _callee(oid, viewLinkId) {
    var urlToCall, requestHeaders, likeApiResponse;
    return regeneratorRuntime.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            urlToCall = getFormattedUrl(oid, viewLinkId).href;
            requestHeaders = getRequestHeaders();
            _context.next = 4;
            return fetch(urlToCall, {
              method: 'PUT',
              headers: requestHeaders
            });
          case 4:
            likeApiResponse = _context.sent;
            if (likeApiResponse.ok) {
              _context.next = 7;
              break;
            }
            return _context.abrupt("return", Promise.reject(likeApiResponse.statusText));
          case 7:
            return _context.abrupt("return", Promise.resolve());
          case 8:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));
  return function like(_x, _x2) {
    return _ref.apply(this, arguments);
  };
}();
export var unlike = function () {
  var _ref2 = _asyncToGenerator(regeneratorRuntime.mark(function _callee2(oid, viewLinkId) {
    var urlToCall, requestHeaders, unlikeApiResponse;
    return regeneratorRuntime.wrap(function _callee2$(_context2) {
      while (1) {
        switch (_context2.prev = _context2.next) {
          case 0:
            urlToCall = getFormattedUrl(oid, viewLinkId).href;
            requestHeaders = getRequestHeaders();
            _context2.next = 4;
            return fetch(urlToCall, {
              method: 'DELETE',
              headers: requestHeaders
            });
          case 4:
            unlikeApiResponse = _context2.sent;
            if (unlikeApiResponse.ok) {
              _context2.next = 7;
              break;
            }
            return _context2.abrupt("return", Promise.reject(unlikeApiResponse.statusText));
          case 7:
            return _context2.abrupt("return", Promise.resolve());
          case 8:
          case "end":
            return _context2.stop();
        }
      }
    }, _callee2);
  }));
  return function unlike(_x3, _x4) {
    return _ref2.apply(this, arguments);
  };
}();