import "core-js/modules/es.array.iterator";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.promise";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.search";
import "core-js/modules/web.dom-collections.iterator";
import "core-js/modules/web.url";
import { getRequestHeaders } from '../../api/likes';
import * as Cookies from 'es-cookie';
var SessionStatus = function (SessionStatus) {
  SessionStatus["open"] = "open";
  SessionStatus["complete"] = "complete";
  SessionStatus["expired"] = "expired";
  return SessionStatus;
}(SessionStatus || {});
export default function isPageWithValidCheckoutSession(preziOid) {
  var checkoutSessionId = getCheckoutSessionIdFromUrl();
  if (checkoutSessionId) {
    retrieveCheckoutSession(checkoutSessionId).then(session => {
      if (!session) {
        return false;
      }
      return isCheckoutSessionValid(session, preziOid);
    });
  }
  return Promise.resolve(false);
}
export function isCheckoutSessionIdValid(sessionID, preziOid) {
  return retrieveCheckoutSession(sessionID).then(session => {
    if (!session) {
      return false;
    }
    return isCheckoutSessionValid(session, preziOid);
  });
}
export function isCheckoutSessionValid(session, preziOid) {
  var _session$metadata;
  var isComplete = session.status === SessionStatus.complete;
  var isOidCorrect = ((_session$metadata = session.metadata) === null || _session$metadata === void 0 ? void 0 : _session$metadata['prezi-oid']) === preziOid;
  return isComplete && isOidCorrect;
}
export function retrieveCheckoutSession(sessionID) {
  var url = new URL('/pdf-access/retrieve-checkout-session', window.location.origin);
  url.searchParams.append('session-id', sessionID);
  var headers = getRequestHeaders();
  return fetch(url.toString(), {
    method: 'GET',
    credentials: 'include',
    headers
  }).then(res => res.json()).catch(() => null);
}
export function getCheckoutSessionIdFromUrl() {
  var urlParams = new URLSearchParams(window.location.search);
  return urlParams.get('session-id');
}
export function removeCheckoutSessionIdFromUrl() {
  var urlParams = new URLSearchParams(window.location.search);
  urlParams.delete('session-id');
  var newUrl = `${window.location.pathname}${urlParams.values.length ? '?' + urlParams.toString() : ''}${window.location.hash}`;
  window.history.replaceState({}, document.title, newUrl);
}
export function isPdfPreviouslyPurchasedByPutmaId(preziOid, isZuora) {
  var url = new URL(isZuora ? '/pdf-access/is-purchased-zuora' : '/pdf-access/is-purchased', window.location.origin);
  var putmaId = Cookies.get('__putma') || '';
  url.searchParams.append('putma-id', putmaId);
  url.searchParams.append('prezi-oid', preziOid);
  var headers = getRequestHeaders();
  return fetch(url.toString(), {
    method: 'GET',
    credentials: 'include',
    headers
  }).then(res => res.json()).then(data => {
    return data.isPurchased;
  });
}
export function postCheckoutCompletion(checkoutSessionId) {
  var url = new URL('/pdf-access/checkout-complete', window.location.origin);
  var headers = getRequestHeaders();
  return fetch(url.toString(), {
    method: 'POST',
    credentials: 'include',
    headers,
    body: JSON.stringify({
      'session-id': checkoutSessionId
    })
  }).then(() => {});
}