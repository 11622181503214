import "core-js/modules/es.object.to-string";
import "core-js/modules/es.promise";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import React, { useRef, useState, useEffect } from 'react';
import { makeCancelable } from './utils';
import { glassboxWebJs } from '@prezi/client-logger-module';
import CloseButton from './CloseButton';
import { getRequestHeaders } from '../../api/likes';
import { ProgressIndicator } from '../ProgressIndicator';
var CONVERSION_SUCCESS = 5;
var CONVERSION_FAILURE = 6;
var CONVERSION_TIMEOUT = 8;
var initialState = values => {
  var downloadStatus = values.pdfAutoExport ? 'started' : null;
  return {
    preziOid: values.preziOid,
    preziTitle: values.preziTitle,
    preziIsMine: values.preziIsMine,
    downloadButtonTargetUrl: undefined,
    downloadText: values.texts.exportModal.button.conversionStarting,
    downloadStatus,
    errorText: ''
  };
};
function sendPdfLinkEmail(email, preziOid, preziTitle, pdfLink) {
  var headers = getRequestHeaders();
  var body = JSON.stringify({
    email,
    'prezi-link': window.location.href,
    'prezi-title': preziTitle,
    'pdf-link': pdfLink
  });
  var res = fetch('/pdf-access/send-pdf-link-email/', {
    method: 'POST',
    credentials: 'include',
    headers,
    body
  });
  return res;
}
export var PdfExportDialog = props => {
  var pollConversionServiceHandler = useRef(null);
  var cancelablePromises = useRef([]);
  var _useState = useState(() => initialState(props)),
    _useState2 = _slicedToArray(_useState, 2),
    pdfState = _useState2[0],
    setPdfSetState = _useState2[1];
  var mainTexts = props.newPurchase ? props.texts.exportModal.newPurchase : props.texts.exportModal.alreadyPurchased;
  useEffect(() => {
    if (props.pdfAutoExport) {
      exportToPdf();
    }
    cancelablePromises.current = cancelablePromises.current || [];
    return function cancel() {
      clearTimeout(pollConversionServiceHandler.current);
      cancelablePromises.current.forEach(p => p.cancel());
      cancelablePromises.current = [];
    };
  }, []);
  var openUrl = function openUrl(downloadButtonTargetUrl) {
    var closeDialog = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;
    var pdfUrl = downloadButtonTargetUrl || pdfState.downloadButtonTargetUrl;
    window.open(pdfUrl);
    if (closeDialog) {
      props.onClosePDFExportClick();
    }
    glassboxWebJs.logOpenExportedPdf({
      prezi_oid: props.preziOid,
      export_action_source: 'VIEW_PAGE_AFTER_PAYMENT'
    });
  };
  var parseS3Uri = s3Uri => {
    var path = s3Uri.split(':')[1];
    return 'https://media.preziusercontent.com/' + path;
  };
  var pollConversionJob = (jobUrl, jobHeader, depth) => {
    if (depth === 0) {
      setPdfSetState(_objectSpread(_objectSpread({}, pdfState), {}, {
        downloadStatus: 'polling'
      }));
    }
    var cancelablePromise = makeCancelable(fetch(jobUrl, {
      method: 'POST',
      headers: jobHeader
    }));
    cancelablePromise.promise.then(response => {
      if (httpNotOk(response.status)) {
        throw new Error('Poll failed: ' + response.statusText);
      }
      response.json().then(jobResponse => {
        if (jobResponse.status === CONVERSION_SUCCESS) {
          var pdfUrl = parseS3Uri(jobResponse.results[0].uri);
          if (props.customerEmail) {
            sendPdfLinkEmail(props.customerEmail, pdfState.preziOid, pdfState.preziTitle, pdfUrl);
          }
          openUrl(pdfUrl, false);
          setPdfSetState(_objectSpread(_objectSpread({}, pdfState), {}, {
            downloadText: props.texts.exportModal.button.conversionComplete,
            downloadStatus: 'success',
            downloadButtonTargetUrl: pdfUrl
          }));
          glassboxWebJs.logFinishedExportingPdf({
            prezi_oid: pdfState.preziOid,
            export_action_source: 'VIEW_PAGE'
          });
        } else if (jobResponse.status === CONVERSION_FAILURE) {
          setPdfSetState(_objectSpread(_objectSpread({}, pdfState), {}, {
            downloadText: props.texts.exportModal.button.generalError,
            downloadStatus: 'failed',
            downloadButtonTargetUrl: undefined,
            errorText: props.texts.exportModal.generalError
          }));
        } else if (jobResponse.status === CONVERSION_TIMEOUT) {
          setPdfSetState(_objectSpread(_objectSpread({}, pdfState), {}, {
            downloadText: props.texts.exportModal.button.generalError,
            downloadStatus: 'failed',
            downloadButtonTargetUrl: undefined,
            errorText: props.texts.exportModal.timeoutError
          }));
        } else {
          pollConversionServiceHandler.current = setTimeout(() => {
            pollConversionJob(jobUrl, jobHeader, depth + 1);
          }, 1500);
        }
      });
    }).catch(error => {
      handleErrorResponse(error);
    });
    cancelablePromises.current.push(cancelablePromise);
  };
  var httpNotOk = status => {
    return !(status >= 200 && status < 300);
  };
  var handleErrorResponse = error => {
    setPdfSetState(_objectSpread(_objectSpread({}, pdfState), {}, {
      downloadText: props.texts.exportModal.button.generalError,
      downloadStatus: 'failed',
      downloadButtonTargetUrl: undefined,
      errorText: props.texts.exportModal.generalError
    }));
    throw new Error(error);
  };
  var exportToPdf = () => {
    glassboxWebJs.logStartedExportingPdf({
      prezi_oid: pdfState.preziOid,
      export_action_source: 'VIEW_PAGE'
    });
    setPdfSetState(_objectSpread(_objectSpread({}, pdfState), {}, {
      downloadText: props.texts.exportModal.button.conversionStarting,
      downloadStatus: 'started'
    }));
    var exportCancelablePromise = makeCancelable(fetch(`/backend/export/${pdfState.preziOid}/pdf/`, {
      method: 'POST',
      credentials: 'include'
    }));
    exportCancelablePromise.promise.then(response => {
      if (httpNotOk(response.status)) {
        throw new Error('Export fetch failed: ' + response.statusText);
      }
      response.json().then(jsonData => {
        var url = jsonData.conversion_token.url;
        var headers = {
          Authorization: jsonData.conversion_token.header
        };
        var conversionJobCancelablePromise = makeCancelable(fetch(url, {
          method: 'POST',
          headers,
          credentials: 'include'
        }));
        conversionJobCancelablePromise.promise.then(conversionResponse => {
          if (httpNotOk(conversionResponse.status)) {
            throw new Error('Job fetch failed: ' + conversionResponse.statusText);
          }
          conversionResponse.json().then(conversionJson => {
            var jobUrl = conversionJson.conversion_status_token.url;
            var jobHeader = {
              Authorization: conversionJson.conversion_status_token.header
            };
            pollConversionJob(jobUrl, jobHeader, 0);
          });
        }).catch(error => {
          handleErrorResponse(error);
        });
        cancelablePromises.current.push(conversionJobCancelablePromise);
      });
    }).catch(error => {
      handleErrorResponse(error);
    });
    cancelablePromises.current.push(exportCancelablePromise);
  };
  return React.createElement("div", {
    className: "modal-content",
    style: {
      padding: 18,
      maxHeight: '90vh',
      overflowY: 'auto',
      width: 'inherit'
    }
  }, React.createElement("div", {
    className: "modal-header"
  }, React.createElement(CloseButton, props)), React.createElement("div", {
    className: "modal-body"
  }, React.createElement("h2", {
    className: "prezi-title bold btm-0"
  }, mainTexts.title), mainTexts.body, React.createElement("button", {
    className: (pdfState.downloadStatus === 'polling' || pdfState.downloadStatus === 'started' ? 'disabled ' : '') + 'btn-xs blue pdf-button',
    onClick: () => {
      setPdfSetState(_objectSpread(_objectSpread({}, pdfState), {}, {
        errorText: ''
      }));
      if (pdfState.downloadButtonTargetUrl) {
        openUrl();
      } else {
        exportToPdf();
      }
    }
  }, pdfState.downloadStatus === 'polling' ? React.createElement(ProgressIndicator, {
    size: 25,
    progressPercentage: 23,
    strokeWidth: 5,
    marginBottom: 0,
    maxHeight: 16
  }) : pdfState.downloadText), React.createElement("div", null, pdfState.errorText && React.createElement("div", {
    className: "pdf-error-container"
  }, pdfState.errorText))));
};