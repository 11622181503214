function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }
function _defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, _toPropertyKey(descriptor.key), descriptor); } }
function _createClass(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); Object.defineProperty(Constructor, "prototype", { writable: false }); return Constructor; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function"); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, writable: true, configurable: true } }); Object.defineProperty(subClass, "prototype", { writable: false }); if (superClass) _setPrototypeOf(subClass, superClass); }
function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }
function _possibleConstructorReturn(self, call) { if (call && (typeof call === "object" || typeof call === "function")) { return call; } else if (call !== void 0) { throw new TypeError("Derived constructors may only return object or undefined"); } return _assertThisInitialized(self); }
function _assertThisInitialized(self) { if (self === void 0) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return self; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
function _getPrototypeOf(o) { _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function _getPrototypeOf(o) { return o.__proto__ || Object.getPrototypeOf(o); }; return _getPrototypeOf(o); }
import styled from 'styled-components';
import React from 'react';
var Sparkle = styled.svg.withConfig({
  displayName: "Sparkles__Sparkle",
  componentId: "oj3777-0"
})(["margin:auto 4px auto 0;"]);
export var Sparkles = function (_React$Component) {
  _inherits(Sparkles, _React$Component);
  var _super = _createSuper(Sparkles);
  function Sparkles() {
    _classCallCheck(this, Sparkles);
    return _super.apply(this, arguments);
  }
  _createClass(Sparkles, [{
    key: "render",
    value: function render() {
      return React.createElement(Sparkle, {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.07143 8.87142L7.5 10.8L6.92857 8.87142L5 8.29999L6.92857 7.72856L7.5 5.79999L8.07143 7.72856L10 8.29999L8.07143 8.87142ZM16 20L17.6 14.6L23 13L17.6 11.4L16 6.00002L14.4 11.4L9 13L14.4 14.6L16 20ZM7.5 21L8.3 18.3L11 17.5L8.3 16.7L7.5 14L6.7 16.7L4 17.5L6.7 18.3L7.5 21Z",
        fill: "white"
      }));
    }
  }]);
  return Sparkles;
}(React.Component);
export var SparklesDark = function (_React$Component2) {
  _inherits(SparklesDark, _React$Component2);
  var _super2 = _createSuper(SparklesDark);
  function SparklesDark() {
    _classCallCheck(this, SparklesDark);
    return _super2.apply(this, arguments);
  }
  _createClass(SparklesDark, [{
    key: "render",
    value: function render() {
      return React.createElement(Sparkle, {
        width: "24",
        height: "24",
        viewBox: "0 0 24 24",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg"
      }, React.createElement("path", {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M8.07143 8.87142L7.5 10.8L6.92857 8.87142L5 8.29999L6.92857 7.72856L7.5 5.79999L8.07143 7.72856L10 8.29999L8.07143 8.87142ZM16 20L17.6 14.6L23 13L17.6 11.4L16 6.00002L14.4 11.4L9 13L14.4 14.6L16 20ZM7.5 21L8.3 18.3L11 17.5L8.3 16.7L7.5 14L6.7 16.7L4 17.5L6.7 18.3L7.5 21Z",
        fill: "black"
      }));
    }
  }]);
  return SparklesDark;
}(React.Component);