import * as React from 'react';
export function ProgressIndicator(props) {
  var strokeWidth = props.strokeWidth || 10;
  var radius = (props.size - strokeWidth) / 2;
  var compositeStyle = {
    width: props.size,
    height: props.size,
    marginBottom: typeof props.marginBottom !== 'undefined' ? props.marginBottom : 15
  };
  var containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    maxHeight: props.maxHeight
  };
  var svgStyle = {
    transform: 'rotate(270deg)',
    animation: 'rotate 1.5s linear infinite'
  };
  var progressIndicatorCircleStyle = {
    strokeWidth,
    stroke: '#3784FB',
    strokeDashoffset: calculateDashOffset(radius, props.progressPercentage),
    transition: 'stroke-dashoffset 1s ease-in-out',
    strokeLinecap: 'round'
  };
  var circumference = Math.PI * (radius * 2);
  return React.createElement("div", {
    className: "viewer-common-progress-indicator",
    style: containerStyle
  }, React.createElement("div", {
    className: "viewer-common-progress-composite",
    key: "progress-composite",
    style: compositeStyle
  }, React.createElement("svg", {
    viewBox: `0 0 ${props.size} ${props.size}`,
    style: svgStyle
  }, React.createElement("circle", {
    r: radius,
    cx: props.size / 2,
    cy: props.size / 2,
    fill: "transparent",
    strokeDasharray: circumference,
    style: progressIndicatorCircleStyle
  }))));
}
function calculateDashOffset(radius, percentage) {
  if (isNaN(percentage)) {
    percentage = 0;
  } else if (percentage < 0) {
    percentage = 0;
  } else if (percentage > 100) {
    percentage = 100;
  }
  var circumference = Math.PI * (radius * 2);
  var logarithmic = Math.log(1 + percentage) / Math.log(101);
  return (1 - logarithmic) * circumference;
}