import styled from 'styled-components';
import { Colors, Fonts } from '@prezi/dragon';

export const NameContainer = styled.div`
    display: flex;
    align-items: center;
    color: white;

    &:hover {
        color: ${Colors.gray3};
        text-decoration: none;
        cursor: pointer;
    }
`;

export const DropdownMenuItem = styled.a`
    font-size: 13px;
    line-height: 13px;
    letter-spacing: 1px;
    color: ${Colors.gray2};
    text-decoration: none;
    display: block;
    font-family: ${Fonts.ralewayBold};

    :not(:last-child) {
        margin-bottom: 12px;
    }

    &:hover {
        color: ${Colors.blueLight};
        text-decoration: none;
    }
`;

export const DropdownMenu = styled.div`
    position: absolute;
    top: 45px;
    left: auto;
    right: -10px;
    z-index: 1000;
    float: left;
    min-width: 160px;
    list-style: none;
    font-size: 16px;
    text-align: left;
    background: #fff;
    box-shadow: 0 1px 6px 0 rgba(21, 34, 53, 0.4);
    border-radius: 3px;
    padding: 30px;
    box-sizing: unset;

    &.open-pull-down {
        transform-origin: top;
        transform: scale(1, 1);
        transition: transform 200ms ease-out;
    }

    &.close-pull-down {
        transform-origin: top;
        transform: scale(1, 0);
        transition: transform 200ms ease-out;
    }
`;
