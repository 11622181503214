import * as React from 'react';
import { Avatar } from '@prezi/avatar';
import { AuthorNameAndCreationDate, CreatedAt, CreatedBy, CreatorBlock, UserAvatarLink } from './Styles';
export var CreatorBlockComponent = props => {
  var displayName = props.owner.publicDisplayName ? props.owner.publicDisplayName : `${props.owner.firstName} ${props.owner.lastName}`;
  return React.createElement(CreatorBlock, null, props.owner.oid && props.owner.oid !== '' ? React.createElement(UserAvatarLink, {
    href: `/user/${props.owner.oid}/`
  }, React.createElement(Avatar, {
    size: 42,
    firstName: props.owner.firstName,
    lastName: props.owner.lastName,
    username: props.owner.username
  })) : React.createElement(Avatar, {
    size: 42,
    firstName: props.owner.firstName,
    lastName: props.owner.lastName,
    username: props.owner.username
  }), React.createElement(AuthorNameAndCreationDate, null, props.owner.oid && props.owner.oid !== '' ? React.createElement("a", {
    href: `/user/${props.owner.oid}/`
  }, React.createElement(CreatedBy, {
    "data-lookup": "details-created-by"
  }, displayName)) : React.createElement(CreatedBy, {
    "data-lookup": "details-created-by"
  }, displayName), React.createElement(CreatedAt, {
    "data-lookup": "details-created-at"
  }, React.createElement("time", null, props.lastModified))));
};