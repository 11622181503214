import "core-js/modules/es.object.to-string";
import "core-js/modules/es.promise";
import "core-js/modules/es.promise.finally";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import * as React from 'react';
import { glassboxWebJs } from '@prezi/client-logger-module';
import { DownloadButtonStyled } from './Styles';
import { DownloadModal } from '../PDF/DownloadPDFModal';
import { getCheckoutSessionIdFromUrl, isCheckoutSessionValid, postCheckoutCompletion, removeCheckoutSessionIdFromUrl, retrieveCheckoutSession } from '../PDF/payment';
import { AbandonedFlowModal } from '../PDF/AbandonedFlowModal';
import { PDFIcon } from '../PDF/Assets/PDFIcon';
var ValidSessionModalOpenState = function (ValidSessionModalOpenState) {
  ValidSessionModalOpenState[ValidSessionModalOpenState["NotOpen"] = 0] = "NotOpen";
  ValidSessionModalOpenState[ValidSessionModalOpenState["Open"] = 1] = "Open";
  ValidSessionModalOpenState[ValidSessionModalOpenState["Opened"] = 2] = "Opened";
  return ValidSessionModalOpenState;
}(ValidSessionModalOpenState || {});
export var DownloadButton = props => {
  var _React$useState = React.useState(false),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    openPdfExportDialog = _React$useState2[0],
    setOpenPdfExportDialog = _React$useState2[1];
  var _React$useState3 = React.useState(false),
    _React$useState4 = _slicedToArray(_React$useState3, 2),
    openAbandonedFlowDialog = _React$useState4[0],
    setOpenAbandonedFlowDialog = _React$useState4[1];
  var _React$useState5 = React.useState({
      state: 'LOADING'
    }),
    _React$useState6 = _slicedToArray(_React$useState5, 2),
    checkoutSessionState = _React$useState6[0],
    setCheckoutSessionState = _React$useState6[1];
  var _React$useState7 = React.useState(ValidSessionModalOpenState.NotOpen),
    _React$useState8 = _slicedToArray(_React$useState7, 2),
    validSessionModalOpenState = _React$useState8[0],
    setValidSessionModalOpenState = _React$useState8[1];
  function updateStateWithSessionIdRemovedFromUrl() {
    var sessionId = getCheckoutSessionIdFromUrl();
    if (!sessionId) {
      setCheckoutSessionState({
        state: 'NON_EXISTENT'
      });
      return;
    }
    retrieveCheckoutSession(sessionId).then(session => {
      if (!session || !isCheckoutSessionValid(session, props.pdfExport.preziOid)) {
        setCheckoutSessionState({
          state: 'INVALID'
        });
        return;
      }
      postCheckoutCompletion(sessionId);
      setCheckoutSessionState({
        state: 'VALID',
        sessionData: session
      });
      setOpenPdfExportDialog(true);
      setValidSessionModalOpenState(ValidSessionModalOpenState.Open);
      glassboxWebJs.logSuccessfulStripeTransaction({
        checkout_flow: 'MICRO_PDF_DOWNLOAD'
      });
    }).finally(() => {
      removeCheckoutSessionIdFromUrl();
    });
  }
  React.useEffect(() => {
    updateStateWithSessionIdRemovedFromUrl();
  }, []);
  var onDownloadClick = () => {
    setOpenPdfExportDialog(true);
  };
  var onClosePDFExportClick = () => {
    setOpenPdfExportDialog(false);
    if (validSessionModalOpenState === ValidSessionModalOpenState.Open) {
      setValidSessionModalOpenState(ValidSessionModalOpenState.Opened);
    }
    setOpenAbandonedFlowDialog(true);
  };
  return React.createElement(React.Fragment, null, React.createElement(DownloadButtonStyled, {
    onClick: onDownloadClick,
    "data-lookup": 'download-button'
  }, props.showIcon && React.createElement(PDFIcon, null), " ", props.texts.openModalCTAButton), openPdfExportDialog && React.createElement(DownloadModal, {
    preziOid: props.pdfExport.preziOid,
    preziTitle: props.pdfExport.title,
    preziIsMine: true,
    onClosePDFExportClick: onClosePDFExportClick,
    newPurchase: validSessionModalOpenState === ValidSessionModalOpenState.Open,
    customerEmail: checkoutSessionState.state === 'VALID' ? checkoutSessionState.sessionData.customer_details.email : undefined,
    pdfAutoExport: true,
    texts: props.texts,
    isZuora: props.isZuora
  }), openAbandonedFlowDialog && props.abandonedFlowProps !== undefined && React.createElement(AbandonedFlowModal, {
    onCloseAbandonedFlowModalClick: () => {
      setOpenAbandonedFlowDialog(false);
    },
    abandonedFlowProps: props.abandonedFlowProps
  }));
};