function _extends() { _extends = Object.assign ? Object.assign.bind() : function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import React, { useEffect } from 'react';
import * as Styles from '../Styles';
import { PdfExportDialog } from './PdfExportDialog';
import { isPdfPreviouslyPurchasedByPutmaId } from './payment';
import LoadingModal from './LoadingModal';
import { StripeCheckoutForm } from './StripeCheckoutForm';
import { glassboxWebJs } from '@prezi/client-logger-module';
import ZuoraCheckoutForm from './ZuoraCheckoutForm';
var PreviousPaymentStatus = function (PreviousPaymentStatus) {
  PreviousPaymentStatus[PreviousPaymentStatus["Unknown"] = 0] = "Unknown";
  PreviousPaymentStatus[PreviousPaymentStatus["None"] = 1] = "None";
  PreviousPaymentStatus[PreviousPaymentStatus["Existing"] = 2] = "Existing";
  return PreviousPaymentStatus;
}(PreviousPaymentStatus || {});
export var DownloadModal = props => {
  var _React$useState = React.useState(PreviousPaymentStatus.Unknown),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    paymentStatus = _React$useState2[0],
    setPaymentStatus = _React$useState2[1];
  useEffect(() => {
    if (!props.newPurchase) {
      isPdfPreviouslyPurchasedByPutmaId(props.preziOid, props.isZuora).then(isPurchased => {
        setPaymentStatus(isPurchased ? PreviousPaymentStatus.Existing : PreviousPaymentStatus.None);
      });
    }
    glassboxWebJs.logExportPdf({
      prezi_oid: props.preziOid,
      export_action_source: 'VIEW_PAGE'
    });
  }, [props.newPurchase]);
  var renderStep = () => {
    if (props.newPurchase || paymentStatus === PreviousPaymentStatus.Existing) {
      return React.createElement(PdfExportDialog, _extends({}, props, {
        newPurchase: props.newPurchase,
        customerEmail: props.customerEmail
      }));
    } else if (paymentStatus === PreviousPaymentStatus.Unknown) {
      return React.createElement(LoadingModal, props);
    } else if (paymentStatus === PreviousPaymentStatus.None) {
      return props.isZuora ? React.createElement(ZuoraCheckoutForm, {
        preziOid: props.preziOid,
        preziTitle: props.preziTitle,
        onClosePDFExportClick: props.onClosePDFExportClick
      }) : React.createElement(StripeCheckoutForm, {
        texts: props.texts.checkoutModal,
        preziOid: props.preziOid,
        preziTitle: props.preziTitle,
        onClosePDFExportClick: props.onClosePDFExportClick
      });
    }
  };
  return React.createElement(React.Fragment, null, React.createElement(Styles.commonAnimationKeyframes, null), React.createElement("div", {
    id: "pdf-export-dialog"
  }, React.createElement("div", {
    className: "modal",
    id: "pdf-export-modal",
    style: {
      display: 'block',
      zIndex: 1050,
      color: '#475262'
    }
  }, React.createElement("div", {
    className: "modal-vertical-alignment-helper"
  }, React.createElement("div", {
    className: "modal-dialog modal-vertical-align-center"
  }, renderStep()))), React.createElement("div", {
    className: "modal-backdrop fade in",
    style: {
      zIndex: 1050 - 10
    }
  })));
};