import * as React from 'react';
import styled from 'styled-components';
import { Colors } from '@prezi/dragon';
import { WindowSizeBreakpoints } from '@prezi/common-components/src/common/consts';

export interface SearchIconProps {
    color: string;
    fontSize: string;
    fontWeight: string;
}

export interface SearchBarButtonProps {
    height: string;
}

export interface SearchBarButtonSpanProps {
    top: string;
    right: string;
}

export interface SearchBarTextInputProps {
    height: string;
}

export const SearchBarContainer = styled.div`
    flex: 2 1;
    max-width: 790px;
    display: flex;

    @media screen and (max-width: ${WindowSizeBreakpoints.SMALL}px) {
        flex: 1;

        &.expanded {
            width: 100%;
        }
    }
`;

export const SearchBarTextInput = styled.input.attrs({ type: 'text' })<SearchBarTextInputProps>`
    width: 100%;
    height: ${props => props.height};
    border-radius: 3px;
    background-color: ${Colors.white};

    :focus {
        background-color: ${Colors.white};
    }

    @media screen and (max-width: ${WindowSizeBreakpoints.SMALL}px) {
        transition: width 0.25s;
        height: 30px;
        border: none;
        border-radius: 16px;
        float: right;

        &:not(.expanded) {
            width: 30px;
            color: rgba(0, 0, 0, 0);
        }
    }
`;

export const SearchBarButtonSpan = styled.span<SearchBarButtonSpanProps>`
    position: absolute;
    top: ${props => props.top};
    right: ${props => props.right};
    z-index: 2;

    @media screen and (max-width: ${WindowSizeBreakpoints.SMALL}px) {
        top: 0;
        right: -5px;
        width: 42px;
    }
`;

export const SearchBarButton = styled.button<SearchBarButtonProps>`
    height: ${props => props.height};

    @media screen and (max-width: ${WindowSizeBreakpoints.SMALL}px) {
        :active {
            top: unset;
        }

        background: none;
        border: none;
        height: 30px;
    }
`;

export const SearchIcon = styled.i<SearchIconProps>`
    color: ${props => props.color};
    font-size: ${props => props.fontSize};
    font-weight: ${props => props.fontWeight};
`;

export const SearchInputAndButton = styled.div`
    position: relative;
    flex: 1;
`;

export const CloseButton = styled.button`
    color: ${Colors.white};
    margin-left: 4px;
    border: none;
    background: none;
`;

export function SearchPlaceholderStyle() {
    const searchPlaceholderClass = 'search-placeholder-style';
    const searchPlaceholderStyle = `color: ${Colors.gray3} !important; opacity: 1;`;

    return React.createElement(
        'style',
        {},
        `
        .${searchPlaceholderClass}::-webkit-input-placeholder { ${searchPlaceholderStyle} }
        .${searchPlaceholderClass}::-moz-placeholder { ${searchPlaceholderStyle} }
        .${searchPlaceholderClass}:-ms-input-placeholder { ${searchPlaceholderStyle} }
        .${searchPlaceholderClass}:-moz-placeholder { ${searchPlaceholderStyle} }
    `,
    );
}
