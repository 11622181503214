import "regenerator-runtime/runtime";
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }
function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }
function _classCallCheck(instance, Constructor) { if (!(instance instanceof Constructor)) { throw new TypeError("Cannot call a class as a function"); } }
function _defineProperties(target, props) { for (var i = 0; i < props.length; i++) { var descriptor = props[i]; descriptor.enumerable = descriptor.enumerable || false; descriptor.configurable = true; if ("value" in descriptor) descriptor.writable = true; Object.defineProperty(target, _toPropertyKey(descriptor.key), descriptor); } }
function _createClass(Constructor, protoProps, staticProps) { if (protoProps) _defineProperties(Constructor.prototype, protoProps); if (staticProps) _defineProperties(Constructor, staticProps); Object.defineProperty(Constructor, "prototype", { writable: false }); return Constructor; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
function _inherits(subClass, superClass) { if (typeof superClass !== "function" && superClass !== null) { throw new TypeError("Super expression must either be null or a function"); } subClass.prototype = Object.create(superClass && superClass.prototype, { constructor: { value: subClass, writable: true, configurable: true } }); Object.defineProperty(subClass, "prototype", { writable: false }); if (superClass) _setPrototypeOf(subClass, superClass); }
function _setPrototypeOf(o, p) { _setPrototypeOf = Object.setPrototypeOf ? Object.setPrototypeOf.bind() : function _setPrototypeOf(o, p) { o.__proto__ = p; return o; }; return _setPrototypeOf(o, p); }
function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }
function _possibleConstructorReturn(self, call) { if (call && (typeof call === "object" || typeof call === "function")) { return call; } else if (call !== void 0) { throw new TypeError("Derived constructors may only return object or undefined"); } return _assertThisInitialized(self); }
function _assertThisInitialized(self) { if (self === void 0) { throw new ReferenceError("this hasn't been initialised - super() hasn't been called"); } return self; }
function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }
function _getPrototypeOf(o) { _getPrototypeOf = Object.setPrototypeOf ? Object.getPrototypeOf.bind() : function _getPrototypeOf(o) { return o.__proto__ || Object.getPrototypeOf(o); }; return _getPrototypeOf(o); }
import * as React from 'react';
import classnames from 'classnames';
import { Icon } from '../Icon';
import { Likes } from './Styles';
var LikeButtonAnimationType = function (LikeButtonAnimationType) {
  LikeButtonAnimationType["LIKED"] = "liked-animation";
  LikeButtonAnimationType["UNLIKED"] = "unliked-animation";
  LikeButtonAnimationType["ERROR"] = "error-animation";
  LikeButtonAnimationType["DEFAULT"] = "";
  return LikeButtonAnimationType;
}(LikeButtonAnimationType || {});
export var LikeButton = function (_React$PureComponent) {
  _inherits(LikeButton, _React$PureComponent);
  var _super = _createSuper(LikeButton);
  function LikeButton(props) {
    var _this;
    _classCallCheck(this, LikeButton);
    _this = _super.call(this, props);
    _this.componentDidUpdate = (prevProps, _) => {
      if (prevProps.oid !== _this.props.oid) {
        _this.setState({
          likesCount: _this.props.likesCount || 0,
          hasLiked: _this.props.hasLiked
        });
      }
    };
    _this.onLikeHandler = event => {
      event.preventDefault();
      if (!_this.props.isAuthenticated) {
        _this.props.onLoggedOutLike(_this.props.oid);
        return;
      }
      if (_this.state.hasLiked) {
        return _this.unlike();
      }
      return _this.like();
    };
    _this.like = _asyncToGenerator(regeneratorRuntime.mark(function _callee() {
      var currentLikesCount;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              currentLikesCount = _this.state.likesCount;
              _context.prev = 1;
              _this.setState({
                hasLiked: true,
                likesCount: currentLikesCount + 1,
                animationType: LikeButtonAnimationType.LIKED
              });
              _context.next = 5;
              return _this.props.onLike(_this.props.oid);
            case 5:
              _context.next = 10;
              break;
            case 7:
              _context.prev = 7;
              _context.t0 = _context["catch"](1);
              _this.setState({
                hasLiked: false,
                likesCount: currentLikesCount,
                animationType: LikeButtonAnimationType.ERROR
              });
            case 10:
            case "end":
              return _context.stop();
          }
        }
      }, _callee, null, [[1, 7]]);
    }));
    _this.unlike = _asyncToGenerator(regeneratorRuntime.mark(function _callee2() {
      var currentLikesCount;
      return regeneratorRuntime.wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              currentLikesCount = _this.state.likesCount;
              _context2.prev = 1;
              _this.setState({
                hasLiked: false,
                likesCount: currentLikesCount - 1,
                animationType: LikeButtonAnimationType.UNLIKED
              });
              _context2.next = 5;
              return _this.props.onUnlike(_this.props.oid);
            case 5:
              setTimeout(() => {
                _this.setState({
                  animationType: LikeButtonAnimationType.DEFAULT
                });
              }, 2000);
              _context2.next = 11;
              break;
            case 8:
              _context2.prev = 8;
              _context2.t0 = _context2["catch"](1);
              _this.setState({
                hasLiked: true,
                likesCount: currentLikesCount,
                animationType: LikeButtonAnimationType.ERROR
              });
            case 11:
            case "end":
              return _context2.stop();
          }
        }
      }, _callee2, null, [[1, 8]]);
    }));
    _this.getAltText = () => {
      if (_this.state.hasLiked) {
        return _this.props.alt || 'Button to unlike this content';
      } else {
        return _this.props.alt || 'Button to like this content';
      }
    };
    _this.state = {
      hasLiked: props.hasLiked,
      animationType: LikeButtonAnimationType.DEFAULT,
      likesCount: props.likesCount || 0
    };
    return _this;
  }
  _createClass(LikeButton, [{
    key: "render",
    value: function render() {
      if (this.props.likesCount === undefined) {
        return null;
      }
      var className = classnames(this.state.animationType, {
        liked: this.state.hasLiked
      });
      var iconImage = this.state.animationType === LikeButtonAnimationType.UNLIKED ? 'heartbroken.svg' : 'heart_icon.svg';
      return React.createElement(Likes, {
        hasLikes: this.state.likesCount > 0,
        onClick: this.onLikeHandler,
        className: `${className} ${this.props.className || ''}`,
        id: this.props.id || '',
        title: this.props.tooltipText,
        "data-toggle": "tooltip",
        "data-placement": "bottom",
        "data-lookup": "viewpage-like-button"
      }, React.createElement(Icon, {
        iconUrl: iconImage,
        alt: this.getAltText()
      }), React.createElement("span", {
        className: "likes-count",
        "data-lookup": "viewpage-like-button-count"
      }, this.state.likesCount ? Intl.NumberFormat().format(this.state.likesCount) : null));
    }
  }]);
  return LikeButton;
}(React.PureComponent);