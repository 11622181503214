import * as React from 'react';
import { TooltipDiv, TooltipArrow, TooltipInnerContent } from './Styles';
var ToolTip = props => {
  var visibility = props.visible ? 'on' : 'off';
  return React.createElement(TooltipDiv, {
    className: `${visibility} ${props.type}`
  }, React.createElement(TooltipArrow, {
    className: "tooltip-arrow"
  }), React.createElement(TooltipInnerContent, {
    className: "tooltip-inner"
  }, props.tooltipText));
};
export default ToolTip;