function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
import React, { useState } from 'react';
import styled from 'styled-components';
import CloseButton from './CloseButton';
import { ProgressIndicator } from '../ProgressIndicator';
var ModalContent = styled.div.withConfig({
  displayName: "ZuoraCheckoutForm__ModalContent",
  componentId: "sc-1moq51m-0"
})(["width:882px !important;@media (max-width:993px){width:552px !important;}@media (max-width:560px){width:352px !important;}@media (max-width:376px){width:312px !important;}"]);
var FormIframe = styled.iframe.withConfig({
  displayName: "ZuoraCheckoutForm__FormIframe",
  componentId: "sc-1moq51m-1"
})(["border:0;height:700px;margin:auto;width:880px;@media (max-width:994px){width:540px;}@media (max-width:560px){width:340px;}@media (max-width:376px){width:300px;}"]);
export var ZuoraCheckoutForm = props => {
  var _useState = useState(true),
    _useState2 = _slicedToArray(_useState, 2),
    isLoading = _useState2[0],
    setIsLoading = _useState2[1];
  return React.createElement(ModalContent, {
    className: "modal-content"
  }, React.createElement("div", {
    className: "modal-header"
  }, React.createElement(CloseButton, props)), isLoading && React.createElement(ProgressIndicator, {
    size: 80,
    progressPercentage: 70
  }), React.createElement(FormIframe, {
    id: "zuora-visitor-mtx-form",
    src: `/upgrade/embedded/plan/visitors/micropayment/?feature=pdf_download_micropayment&prezi_oid=${props.preziOid}`,
    onLoad: () => setIsLoading(false),
    style: isLoading ? {
      display: 'none'
    } : {}
  }));
};
export default ZuoraCheckoutForm;