import * as React from 'react';
import { Icon } from '../Icon';
import { StyledEmbedButton } from './Styles';
export var EmbedButton = props => {
  var altText = props.alt || 'Button to embed this content on another site';
  if (props.isMobile) {
    return null;
  }
  return React.createElement(StyledEmbedButton, {
    title: props.tooltipText,
    onClick: props.onClick,
    "data-lookup": "viewpage-embed-button",
    "data-placement": "bottom",
    "data-toggle": "tooltip",
    className: props.className || ''
  }, React.createElement(Icon, {
    iconUrl: "embed.svg",
    alt: altText
  }));
};