import React from 'react';
import * as Styles from '../Styles';
import { CTAButton } from '../Buttons/Styles';
export var AbandonedFlowMode = function (AbandonedFlowMode) {
  AbandonedFlowMode[AbandonedFlowMode["GetStarted"] = 0] = "GetStarted";
  AbandonedFlowMode[AbandonedFlowMode["MakeACopy"] = 1] = "MakeACopy";
  return AbandonedFlowMode;
}({});
export var AbandonedFlowModal = props => {
  var abandonedFlowProps = props.abandonedFlowProps,
    onCloseAbandonedFlowModalClick = props.onCloseAbandonedFlowModalClick;
  var renderModalContent = () => {
    var modalTexts = (abandonedFlowProps === null || abandonedFlowProps === void 0 ? void 0 : abandonedFlowProps.abandonedFlowMode) === AbandonedFlowMode.MakeACopy ? abandonedFlowProps === null || abandonedFlowProps === void 0 ? void 0 : abandonedFlowProps.modalTexts.makeACopy : abandonedFlowProps === null || abandonedFlowProps === void 0 ? void 0 : abandonedFlowProps.modalTexts.getStarted;
    return React.createElement(React.Fragment, null, React.createElement("div", {
      className: "modal-content"
    }, React.createElement("h2", null, modalTexts === null || modalTexts === void 0 ? void 0 : modalTexts.heading), React.createElement("p", null, modalTexts === null || modalTexts === void 0 ? void 0 : modalTexts.body), (abandonedFlowProps === null || abandonedFlowProps === void 0 ? void 0 : abandonedFlowProps.abandonedFlowMode) === AbandonedFlowMode.MakeACopy ? React.createElement(abandonedFlowProps.makeACopyCTA, null) : React.createElement(CTAButton, {
      href: abandonedFlowProps === null || abandonedFlowProps === void 0 ? void 0 : abandonedFlowProps.modalTexts.signUpURL
    }, abandonedFlowProps === null || abandonedFlowProps === void 0 ? void 0 : abandonedFlowProps.modalTexts.getStartedCTA), React.createElement("div", {
      className: "modal-header"
    }, React.createElement("button", {
      className: "close",
      onClick: () => {
        onCloseAbandonedFlowModalClick();
      }
    }, React.createElement("i", {
      className: "icon-close"
    })))));
  };
  return React.createElement(React.Fragment, null, React.createElement(Styles.commonAnimationKeyframes, null), React.createElement("div", {
    id: "abandoned-flow-dialog"
  }, React.createElement("div", {
    className: "modal",
    style: {
      display: 'block',
      zIndex: 1050,
      color: '#475262'
    }
  }, React.createElement("div", {
    className: "modal-vertical-alignment-helper"
  }, React.createElement("div", {
    className: "modal-dialog modal-vertical-align-center"
  }, renderModalContent()))), React.createElement("div", {
    className: "modal-backdrop fade in",
    style: {
      zIndex: 1050 - 10
    }
  })));
};