import styled from 'styled-components';
import { Colors, Fonts } from '@prezi/dragon';
import { SearchBarContainer } from '../SearchBar/Styles';
import { WindowSizeBreakpoints } from '@prezi/common-components/src/common/consts';
interface LogoLinkProps {
    showSearchOnMobile: boolean;
}

export const NavBarLinks = styled.div`
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    flex: 1;

    @media screen and (max-width: ${WindowSizeBreakpoints.SMALL}px) {
        margin: 0 6px 0 9px;
        flex: 0 1 auto;
    }
`;

export const LoginLink = styled.a`
    margin-right: 30px;
    color: white;
    font-family: ${Fonts.ralewayBold};
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.5px;
    margin-bottom: 0;

    @media screen and (max-width: ${WindowSizeBreakpoints.SMALL}px) {
        display: none;
    }
`;

export const LogoLink = styled.div<LogoLinkProps>`
    flex: 1;
    text-align: start;
    min-width: 0;

    ${props =>
        props.showSearchOnMobile &&
        `@media screen and (max-width: ${WindowSizeBreakpoints.SMALL}px) {
            position: static;
            img {
                height: 24px;
            }
        }`}

    &.has-back-button {
        @media screen and (max-width: ${WindowSizeBreakpoints.SMALL}px) {
            display: none;
        }
    }

    a:active,
    a:focus {
        outline: 1px solid;
        outline-offset: 2px;
    }
`;

export const BackButtonLink = styled.div`
    flex: 1;
    display: block;

    a {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: pointer;
    }

    @media screen and (min-width: ${WindowSizeBreakpoints.SMALL}px) {
        display: none;
    }
`;

export const Row = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    width: 100%;
    height: 49px;
    align-items: center;

    @media screen and (max-width: ${WindowSizeBreakpoints.SMALL}px) {
        height: 48px;
    }
`;

export const LoggedInNavName = styled.p`
    font-size: 13px;
    line-height: 12px;
    letter-spacing: 1px;
    margin: 0;
`;

export const NameContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const DownArrowNav = styled.i`
    font-size: 18px;
`;

export const NavBarContainer = styled.div`
    text-align: center;
    padding: 6px 30px 0px 30px;
    background-color: ${Colors.neutral800};
    position: relative;
    z-index: 5;

    @media screen and (max-width: 910px) and (min-width: ${WindowSizeBreakpoints.SMALL}) {
        & ${SearchBarContainer} {
            flex-basis: 42%;
            width: 42%;
        }
    }

    @media screen and (max-width: ${WindowSizeBreakpoints.SMALL}px) {
        padding: 0 9px 0px 15px;

        .input-group .search-input {
            background: #374151;
        }
        .input-group.expanded .search-input {
            color: ${Colors.white};
        }
    }

    @media screen and (min-width: ${WindowSizeBreakpoints.SMALL + 1}px) {
        .search-btn {
            background-color: ${Colors.greenLight};
            border-color: ${Colors.greenLight};

            :hover {
                background-color: ${Colors.green};
                border-color: ${Colors.green};
            }
        }
    }
`;

export const NavBarButton = styled.button.attrs({ className: 'btn-xs blue', type: 'button' })`
    height: 30px;
    width: 132px;

    @media screen and (max-width: ${WindowSizeBreakpoints.SMALL}px) {
        border-radius: 16px;
        padding: 5px 12px;
        font-size: 12px;
        width: unset;
    }
`;

export const NavBarLinksWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
    padding-left: 16px;

    @media screen and (max-width: ${WindowSizeBreakpoints.SMALL}px) {
        padding-left: 0px;
    }
`;
