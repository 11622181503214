import "core-js/modules/es.symbol";
import "core-js/modules/es.symbol.description";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import * as React from 'react';
import * as Styles from './Styles';
import { ResponsiveMode } from '../models/common';
export function RoundButton(props) {
  var containerCommonStyle = {
    width: 168,
    height: 168,
    borderRadius: '50%',
    border: 'solid 1px rgba(255, 255, 255, 0.9)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer'
  };
  var containerStyle = props.backgroundColor ? _objectSpread(_objectSpread({}, containerCommonStyle), {}, {
    backgroundColor: props.backgroundColor,
    border: ''
  }) : containerCommonStyle;
  var iconStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };
  var buttonClassName = 'viewer-common-info-overlay-button' + (props.backgroundColor ? '-filled' : '-transparent');
  return React.createElement("div", {
    className: buttonClassName,
    style: containerStyle,
    onClick: props.onClick
  }, React.createElement("div", {
    className: "viewer-common-info-overlay-button-icon",
    style: iconStyle
  }, props.iconElement));
}
export function Button(props) {
  var _React$useState = React.useState(false),
    _React$useState2 = _slicedToArray(_React$useState, 2),
    hovering = _React$useState2[0],
    setHovering = _React$useState2[1];
  var containerCommonStyle = _objectSpread({
    borderRadius: '5px',
    border: props.type === 'outline' ? 'solid 1px rgba(255, 255, 255, 0.9)' : 'none',
    background: props.type === 'filled' ? props.backgroundColor : 'none',
    color: props.type === 'filled' ? props.textColor : 'initial',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    cursor: 'pointer',
    padding: '12px 24px',
    margin: '0 auto',
    transition: 'transform 100ms linear'
  }, props.style);
  var hoverStyle = _objectSpread({
    transform: props.size === 'big' ? 'scale(1.02)' : 'none'
  }, props.hoverStyle);
  var iconStyle = {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  };
  var labelStyle = _objectSpread(_objectSpread({}, Styles.mediumTextStyle()), {}, {
    fontSize: props.size === 'big' ? 22 : 18,
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 1)',
    marginLeft: props.iconUrl || props.iconElement ? 18 : 0
  });
  var buttonClassName = 'viewer-common-info-overlay-button-small' + (props.backgroundColor ? '-filled' : '-transparent');
  var style = hovering ? _objectSpread(_objectSpread({}, containerCommonStyle), hoverStyle) : containerCommonStyle;
  return React.createElement("div", {
    className: buttonClassName,
    style: style,
    onClick: props.onClick,
    onMouseEnter: () => setHovering(true),
    onMouseLeave: () => setHovering(false)
  }, React.createElement("div", {
    className: "viewer-common-info-overlay-button-icon-small",
    style: iconStyle
  }, props.iconElement, React.createElement("div", {
    style: labelStyle,
    className: "viewer-common-info-overlay-button-label"
  }, props.label)));
}
export function Label(props) {
  var buttonLabelStyle = _objectSpread(_objectSpread({
    marginTop: 24
  }, Styles.boldTextStyle()), {}, {
    fontSize: 25,
    lineHeight: 0.72,
    textAlign: 'center',
    color: 'rgba(255, 255, 255, 1)'
  });
  var loadingLabelStyle = _objectSpread(_objectSpread({}, Styles.mediumTextStyle()), {}, {
    animation: 'fade-in 2s ease-in alternate infinite',
    fontSize: 18,
    letterSpacing: 1.2,
    textAlign: 'center'
  });
  var labelStyle = props.text === 'Loading…' ? loadingLabelStyle : buttonLabelStyle;
  var labelClassName = props.text === 'Loading…' ? 'viewer-common-progress-text' : 'viewer-common-info-overlay-button-label';
  return React.createElement("div", {
    className: labelClassName,
    style: labelStyle
  }, props.text);
}
export function Title(props) {
  var style = _objectSpread(_objectSpread({}, Styles.mediumTextStyle()), {}, {
    fontSize: props.responsiveMode === ResponsiveMode.SMALL ? 24 : 40,
    lineHeight: 1.2,
    letterSpacing: 1,
    marginBottom: props.responsiveMode === ResponsiveMode.SMALL ? 12 : 24,
    marginTop: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  });
  return React.createElement("h1", {
    style: style
  }, props.title);
}