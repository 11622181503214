import styled from 'styled-components';

export const SearchTermLinks = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    justify-content: center;
    height: 36px;
    overflow-y: hidden;
`;
