import "core-js/modules/es.symbol";
import "core-js/modules/es.symbol.description";
import "core-js/modules/es.number.constructor";
import "core-js/modules/es.object.to-string";
function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }
function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return typeof key === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (typeof input !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (typeof res !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
import styled from 'styled-components';
export var BadgeLocation = function (BadgeLocation) {
  BadgeLocation[BadgeLocation["TopRight"] = 1] = "TopRight";
  BadgeLocation[BadgeLocation["TopLeft"] = 2] = "TopLeft";
  BadgeLocation[BadgeLocation["BottomRight"] = 3] = "BottomRight";
  BadgeLocation[BadgeLocation["BottomLeft"] = 4] = "BottomLeft";
  return BadgeLocation;
}({});
export var Badge = styled.div.attrs(props => {
  return _objectSpread(_objectSpread({}, props), {}, {
    className: props.className
  });
}).withConfig({
  displayName: "Badge",
  componentId: "sc-1mmrkg7-0"
})(["", " position:absolute;background-color:white;border:1px solid black;border-radius:50%;text-align:center;white-space:nowrap;font-size:12px;width:", " height:", " line-height:32px;z-index:2;"], props => {
  if (props.location === BadgeLocation.TopRight) {
    return `top: ${props.offset}px; right: ${props.offset}px;`;
  } else if (props.location === BadgeLocation.TopLeft) {
    return `top: ${props.offset}px; left: ${props.offset}px;`;
  } else if (props.location === BadgeLocation.BottomRight) {
    return `bottom: ${props.offset}px; right: ${props.offset}px;`;
  } else if (props.location === BadgeLocation.BottomLeft) {
    return `bottom: ${props.offset}px; left: ${props.offset}px;`;
  } else {
    return '';
  }
}, props => `${props.size}px;`, props => `${props.size}px;`);