import { logGlassbox, buildGlassboxPayload } from '../glassbox.client.logger';
function _log(schemaId, eventType, eventName, params) {
  return logGlassbox(buildGlassboxPayload('PreziAirAnalytics', schemaId, 'PreziAirWeb', eventType, eventName, 'Web', params));
}
export function logAddContent(params) {
  return _log(22420, 'AddContent', 'AddContentWebPreziAirWeb', params);
}
export function logAddTime(params) {
  return _log(24315, 'AddTime', 'AddTimeWebPreziAirWeb', params);
}
export function logAllowAccessCameraAndMicrophone(params) {
  return _log(22615, 'AllowAccessCameraAndMicrophone', 'AllowAccessCameraAndMicrophoneWebPreziAirWeb', params);
}
export function logAppliedVirtualBackground(params) {
  return _log(23618, 'AppliedVirtualBackground', 'AppliedVirtualBackgroundWebPreziAirWeb', params);
}
export function logAssetInsertionError(params) {
  return _log(22320, 'AssetInsertionError', 'AssetInsertionErrorWebPreziAirWeb', params);
}
export function logBackToContentPicker(params) {
  return _log(22959, 'BackToContentPicker', 'BackToContentPickerWebPreziAirWeb', params);
}
export function logBackToVideoList() {
  return _log(21071, 'BackToVideoList', 'BackToVideoListWebPreziAirWeb');
}
export function logBlockedCameraAccess() {
  return _log(22091, 'BlockedCameraAccess', 'BlockedCameraAccessWebPreziAirWeb');
}
export function logCancelPreziImport(params) {
  return _log(23204, 'CancelPreziImport', 'CancelPreziImportWebPreziAirWeb', params);
}
export function logCancelShareScreen() {
  return _log(22739, 'CancelShareScreen', 'CancelShareScreenWebPreziAirWeb');
}
export function logCanceledCloudImport(params) {
  return _log(24374, 'CanceledCloudImport', 'CanceledCloudImportWebPreziAirWeb', params);
}
export function logCanceledImport(params) {
  return _log(23337, 'CanceledImport', 'CanceledImportWebPreziAirWeb', params);
}
export function logCanceledSlideImport(params) {
  return _log(22457, 'CanceledSlideImport', 'CanceledSlideImportWebPreziAirWeb', params);
}
export function logChangeCameraMirroring(params) {
  return _log(22814, 'ChangeCameraMirroring', 'ChangeCameraMirroringWebPreziAirWeb', params);
}
export function logChangeCamera(params) {
  return _log(23214, 'ChangeCamera', 'ChangeCameraWebPreziAirWeb', params);
}
export function logChangeMicrophone(params) {
  return _log(22795, 'ChangeMicrophone', 'ChangeMicrophoneWebPreziAirWeb', params);
}
export function logChangeNameTagTextColor(params) {
  return _log(23475, 'ChangeNameTagTextColor', 'ChangeNameTagTextColorWebPreziAirWeb', params);
}
export function logChangeOrientation(params) {
  return _log(21113, 'ChangeOrientation', 'ChangeOrientationWebPreziAirWeb', params);
}
export function logChangeTitle() {
  return _log(22105, 'ChangeTitle', 'ChangeTitleWebPreziAirWeb');
}
export function logChangedChromaKeyColor(params) {
  return _log(23584, 'ChangedChromaKeyColor', 'ChangedChromaKeyColorWebPreziAirWeb', params);
}
export function logChangedLiteToReuse(params) {
  return _log(21093, 'ChangedLiteToReuse', 'ChangedLiteToReuseWebPreziAirWeb', params);
}
export function logChangedResolution(params) {
  return _log(16357, 'ChangedResolution', 'ChangedResolutionWebPreziAirWeb', params);
}
export function logChangedVideoDescription(params) {
  return _log(21095, 'ChangedVideoDescription', 'ChangedVideoDescriptionWebPreziAirWeb', params);
}
export function logChangedVideoTag(params) {
  return _log(21100, 'ChangedVideoTag', 'ChangedVideoTagWebPreziAirWeb', params);
}
export function logChangedVideoTitle(params) {
  return _log(21106, 'ChangedVideoTitle', 'ChangedVideoTitleWebPreziAirWeb', params);
}
export function logChapterCardShown(params) {
  return _log(19643, 'ChapterCardShown', 'ChapterCardShownWebPreziAirWeb', params);
}
export function logClearContent() {
  return _log(21118, 'ClearContent', 'ClearContentWebPreziAirWeb');
}
export function logClickGetVideoAppButton() {
  return _log(21129, 'ClickGetVideoAppButton', 'ClickGetVideoAppButtonWebPreziAirWeb');
}
export function logClickImport(params) {
  return _log(22909, 'ClickImport', 'ClickImportWebPreziAirWeb', params);
}
export function logClickInAppMsg(params) {
  return _log(22884, 'ClickInAppMsg', 'ClickInAppMsgWebPreziAirWeb', params);
}
export function logClickKeyboardShortcuts() {
  return _log(23795, 'ClickKeyboardShortcuts', 'ClickKeyboardShortcutsWebPreziAirWeb');
}
export function logClickMuteMicrophone(params) {
  return _log(22797, 'ClickMuteMicrophone', 'ClickMuteMicrophoneWebPreziAirWeb', params);
}
export function logClickNameTagEditorLinks(params) {
  return _log(23606, 'ClickNameTagEditorLinks', 'ClickNameTagEditorLinksWebPreziAirWeb', params);
}
export function logClickOpen() {
  return _log(23800, 'ClickOpen', 'ClickOpenWebPreziAirWeb');
}
export function logClickShowHideMenu() {
  return _log(22624, 'ClickShowHideMenu', 'ClickShowHideMenuWebPreziAirWeb');
}
export function logClickTryNameTag(params) {
  return _log(23483, 'ClickTryNameTag', 'ClickTryNameTagWebPreziAirWeb', params);
}
export function logClickVideoCardMenu(params) {
  return _log(21144, 'ClickVideoCardMenu', 'ClickVideoCardMenuWebPreziAirWeb', params);
}
export function logClickVideoCardOverlay(params) {
  return _log(21146, 'ClickVideoCardOverlay', 'ClickVideoCardOverlayWebPreziAirWeb', params);
}
export function logClickVideoWorksWithCompanyCard(params) {
  return _log(21148, 'ClickVideoWorksWithCompanyCard', 'ClickVideoWorksWithCompanyCardWebPreziAirWeb', params);
}
export function logClickVirtualBackgroundLinks(params) {
  return _log(23610, 'ClickVirtualBackgroundLinks', 'ClickVirtualBackgroundLinksWebPreziAirWeb', params);
}
export function logClickedCreateBrandkitCta(params) {
  return _log(23698, 'ClickedCreateBrandkitCta', 'ClickedCreateBrandkitCtaWebPreziAirWeb', params);
}
export function logClickedCreateNewVideoInMsTeams(params) {
  return _log(21123, 'ClickedCreateNewVideoInMsTeams', 'ClickedCreateNewVideoInMsTeamsWebPreziAirWeb', params);
}
export function logClickedPreziVideoInMsTeams(params) {
  return _log(21125, 'ClickedPreziVideoInMsTeams', 'ClickedPreziVideoInMsTeamsWebPreziAirWeb', params);
}
export function logClickedVideoDashboardEmptyCard() {
  return _log(21127, 'ClickedVideoDashboardEmptyCard', 'ClickedVideoDashboardEmptyCardWebPreziAirWeb');
}
export function logCloseFloatingVideoPlayer(params) {
  return _log(21154, 'CloseFloatingVideoPlayer', 'CloseFloatingVideoPlayerWebPreziAirWeb', params);
}
export function logCloseNameTagModal(params) {
  return _log(23487, 'CloseNameTagModal', 'CloseNameTagModalWebPreziAirWeb', params);
}
export function logCloseNameTagSidebar(params) {
  return _log(23491, 'CloseNameTagSidebar', 'CloseNameTagSidebarWebPreziAirWeb', params);
}
export function logCloseSubtitlesEditor(params) {
  return _log(22186, 'CloseSubtitlesEditor', 'CloseSubtitlesEditorWebPreziAirWeb', params);
}
export function logCloseTutorialVideo(params) {
  return _log(21163, 'CloseTutorialVideo', 'CloseTutorialVideoWebPreziAirWeb', params);
}
export function logCloseWidgetSidebar() {
  return _log(24303, 'CloseWidgetSidebar', 'CloseWidgetSidebarWebPreziAirWeb');
}
export function logCompletedNavigationOnboarding() {
  return _log(21165, 'CompletedNavigationOnboarding', 'CompletedNavigationOnboardingWebPreziAirWeb');
}
export function logCompletedPresentationModeOnboarding() {
  return _log(21167, 'CompletedPresentationModeOnboarding', 'CompletedPresentationModeOnboardingWebPreziAirWeb');
}
export function logContinueWithZoomOnWeb() {
  return _log(23976, 'ContinueWithZoomOnWeb', 'ContinueWithZoomOnWebWebPreziAirWeb');
}
export function logContinuedInBrowserFromDownloadAppSplashScreen() {
  return _log(23278, 'ContinuedInBrowserFromDownloadAppSplashScreen', 'ContinuedInBrowserFromDownloadAppSplashScreenWebPreziAirWeb');
}
export function logControlPlaybackVideoAsset(params) {
  return _log(22939, 'ControlPlaybackVideoAsset', 'ControlPlaybackVideoAssetWebPreziAirWeb', params);
}
export function logCopyEmbedCodeToClipboard(params) {
  return _log(21178, 'CopyEmbedCodeToClipboard', 'CopyEmbedCodeToClipboardWebPreziAirWeb', params);
}
export function logCopyVideoLink(params) {
  return _log(21183, 'CopyVideoLink', 'CopyVideoLinkWebPreziAirWeb', params);
}
export function logCopyVideo(params) {
  return _log(21180, 'CopyVideo', 'CopyVideoWebPreziAirWeb', params);
}
export function logCreateNewVideo(params) {
  return _log(21195, 'CreateNewVideo', 'CreateNewVideoWebPreziAirWeb', params);
}
export function logCreatedFromDesign(params) {
  return _log(21186, 'CreatedFromDesign', 'CreatedFromDesignWebPreziAirWeb', params);
}
export function logCreatedFromReuse(params) {
  return _log(17983, 'CreatedFromReuse', 'CreatedFromReuseWebPreziAirWeb', params);
}
export function logCreatedFromTemplate(params) {
  return _log(21188, 'CreatedFromTemplate', 'CreatedFromTemplateWebPreziAirWeb', params);
}
export function logCreatedVideo(params) {
  return _log(23651, 'CreatedVideo', 'CreatedVideoWebPreziAirWeb', params);
}
export function logDeleteAsset(params) {
  return _log(23070, 'DeleteAsset', 'DeleteAssetWebPreziAirWeb', params);
}
export function logDeleteFrame(params) {
  return _log(22722, 'DeleteFrame', 'DeleteFrameWebPreziAirWeb', params);
}
export function logDeleteImage(params) {
  return _log(21204, 'DeleteImage', 'DeleteImageWebPreziAirWeb', params);
}
export function logDeleteManualChapters(params) {
  return _log(22928, 'DeleteManualChapters', 'DeleteManualChaptersWebPreziAirWeb', params);
}
export function logDeleteVideoAsset(params) {
  return _log(22978, 'DeleteVideoAsset', 'DeleteVideoAssetWebPreziAirWeb', params);
}
export function logDeleteVideo(params) {
  return _log(21206, 'DeleteVideo', 'DeleteVideoWebPreziAirWeb', params);
}
export function logDeleteVirtualBackgroundMedia(params) {
  return _log(23548, 'DeleteVirtualBackgroundMedia', 'DeleteVirtualBackgroundMediaWebPreziAirWeb', params);
}
export function logDesignAssetsLoaded(params) {
  return _log(21212, 'DesignAssetsLoaded', 'DesignAssetsLoadedWebPreziAirWeb', params);
}
export function logDisableVirtualBackgroundContent(params) {
  return _log(23568, 'DisableVirtualBackgroundContent', 'DisableVirtualBackgroundContentWebPreziAirWeb', params);
}
export function logDismissInAppMsg(params) {
  return _log(22888, 'DismissInAppMsg', 'DismissInAppMsgWebPreziAirWeb', params);
}
export function logDismissWindowSizeWarning() {
  return _log(21217, 'DismissWindowSizeWarning', 'DismissWindowSizeWarningWebPreziAirWeb');
}
export function logDisplayedInAppMsg(params) {
  return _log(22892, 'DisplayedInAppMsg', 'DisplayedInAppMsgWebPreziAirWeb', params);
}
export function logDisplayedWindowSizeWarning(params) {
  return _log(21222, 'DisplayedWindowSizeWarning', 'DisplayedWindowSizeWarningWebPreziAirWeb', params);
}
export function logDownloadAppStarted() {
  return _log(21800, 'DownloadAppStarted', 'DownloadAppStartedWebPreziAirWeb');
}
export function logDownloadApp() {
  return _log(21851, 'DownloadApp', 'DownloadAppWebPreziAirWeb');
}
export function logDownloadSubtitle(params) {
  return _log(20939, 'DownloadSubtitle', 'DownloadSubtitleWebPreziAirWeb', params);
}
export function logDownloadVideoApp() {
  return _log(21233, 'DownloadVideoApp', 'DownloadVideoAppWebPreziAirWeb');
}
export function logDownloadVideo(params) {
  return _log(21231, 'DownloadVideo', 'DownloadVideoWebPreziAirWeb', params);
}
export function logDownloadedSubtitle(params) {
  return _log(21229, 'DownloadedSubtitle', 'DownloadedSubtitleWebPreziAirWeb', params);
}
export function logEditPresenterNote(params) {
  return _log(21240, 'EditPresenterNote', 'EditPresenterNoteWebPreziAirWeb', params);
}
export function logEditedPresenterNote(params) {
  return _log(21236, 'EditedPresenterNote', 'EditedPresenterNoteWebPreziAirWeb', params);
}
export function logEditedText(params) {
  return _log(24330, 'EditedText', 'EditedTextWebPreziAirWeb', params);
}
export function logEnableCamera(params) {
  return _log(22081, 'EnableCamera', 'EnableCameraWebPreziAirWeb', params);
}
export function logEnabledCameraAccess() {
  return _log(22093, 'EnabledCameraAccess', 'EnabledCameraAccessWebPreziAirWeb');
}
export function logEnabledMicrophoneAccess() {
  return _log(22618, 'EnabledMicrophoneAccess', 'EnabledMicrophoneAccessWebPreziAirWeb');
}
export function logEnterSearchBar() {
  return _log(21246, 'EnterSearchBar', 'EnterSearchBarWebPreziAirWeb');
}
export function logExitVirtualBackgroundSidebar(params) {
  return _log(23572, 'ExitVirtualBackgroundSidebar', 'ExitVirtualBackgroundSidebarWebPreziAirWeb', params);
}
export function logExploreVideos() {
  return _log(21249, 'ExploreVideos', 'ExploreVideosWebPreziAirWeb');
}
export function logExportVideoFromIntegration(params) {
  return _log(21261, 'ExportVideoFromIntegration', 'ExportVideoFromIntegrationWebPreziAirWeb', params);
}
export function logExportedVideoFromIntegration(params) {
  return _log(21251, 'ExportedVideoFromIntegration', 'ExportedVideoFromIntegrationWebPreziAirWeb', params);
}
export function logExportingDesignAssetsFailed(params) {
  return _log(21256, 'ExportingDesignAssetsFailed', 'ExportingDesignAssetsFailedWebPreziAirWeb', params);
}
export function logFailedExportVideoFromIntegration(params) {
  return _log(21263, 'FailedExportVideoFromIntegration', 'FailedExportVideoFromIntegrationWebPreziAirWeb', params);
}
export function logFilterBrandedTemplates() {
  return _log(23248, 'FilterBrandedTemplates', 'FilterBrandedTemplatesWebPreziAirWeb');
}
export function logFilterDefaultTemplates() {
  return _log(21270, 'FilterDefaultTemplates', 'FilterDefaultTemplatesWebPreziAirWeb');
}
export function logFilterPresentation(params) {
  return _log(23325, 'FilterPresentation', 'FilterPresentationWebPreziAirWeb', params);
}
export function logFilterReusableVideos() {
  return _log(21273, 'FilterReusableVideos', 'FilterReusableVideosWebPreziAirWeb');
}
export function logFinishTrim(params) {
  return _log(21278, 'FinishTrim', 'FinishTrimWebPreziAirWeb', params);
}
export function logFinishedTutorialVideo(params) {
  return _log(21275, 'FinishedTutorialVideo', 'FinishedTutorialVideoWebPreziAirWeb', params);
}
export function logGoToNextStep(params) {
  return _log(20451, 'GoToNextStep', 'GoToNextStepWebPreziAirWeb', params);
}
export function logHamburgerMenuMyVideosSaveProgress() {
  return _log(22101, 'HamburgerMenuMyVideosSaveProgress', 'HamburgerMenuMyVideosSaveProgressWebPreziAirWeb');
}
export function logHamburgerMenuMyVideos() {
  return _log(22099, 'HamburgerMenuMyVideos', 'HamburgerMenuMyVideosWebPreziAirWeb');
}
export function logHamburgerMenuNewVideo() {
  return _log(22103, 'HamburgerMenuNewVideo', 'HamburgerMenuNewVideoWebPreziAirWeb');
}
export function logHideFpsWarning(params) {
  return _log(24393, 'HideFpsWarning', 'HideFpsWarningWebPreziAirWeb', params);
}
export function logImportPowerPoint(params) {
  return _log(16367, 'ImportPowerPoint', 'ImportPowerPointWebPreziAirWeb', params);
}
export function logImportPresentation() {
  return _log(22947, 'ImportPresentation', 'ImportPresentationWebPreziAirWeb');
}
export function logImportSelectedPresentation(params) {
  return _log(21294, 'ImportSelectedPresentation', 'ImportSelectedPresentationWebPreziAirWeb', params);
}
export function logImportSlides(params) {
  return _log(22822, 'ImportSlides', 'ImportSlidesWebPreziAirWeb', params);
}
export function logImportedPresentation(params) {
  return _log(23208, 'ImportedPresentation', 'ImportedPresentationWebPreziAirWeb', params);
}
export function logInsertFrame(params) {
  return _log(22991, 'InsertFrame', 'InsertFrameWebPreziAirWeb', params);
}
export function logInsertIcon(params) {
  return _log(21307, 'InsertIcon', 'InsertIconWebPreziAirWeb', params);
}
export function logInsertImage(params) {
  return _log(22269, 'InsertImage', 'InsertImageWebPreziAirWeb', params);
}
export function logInsertNameTagText(params) {
  return _log(23495, 'InsertNameTagText', 'InsertNameTagTextWebPreziAirWeb', params);
}
export function logInsertPresenterNote(params) {
  return _log(21316, 'InsertPresenterNote', 'InsertPresenterNoteWebPreziAirWeb', params);
}
export function logInsertProviderAsset(params) {
  return _log(27803, 'InsertProviderAsset', 'InsertProviderAssetWebPreziAirWeb', params);
}
export function logInsertText(params) {
  return _log(24324, 'InsertText', 'InsertTextWebPreziAirWeb', params);
}
export function logInsertUnsupportedAsset(params) {
  return _log(23008, 'InsertUnsupportedAsset', 'InsertUnsupportedAssetWebPreziAirWeb', params);
}
export function logInsertUserAsset(params) {
  return _log(24426, 'InsertUserAsset', 'InsertUserAssetWebPreziAirWeb', params);
}
export function logInsertVideoAsset(params) {
  return _log(23016, 'InsertVideoAsset', 'InsertVideoAssetWebPreziAirWeb', params);
}
export function logInsertedAsset(params) {
  return _log(22385, 'InsertedAsset', 'InsertedAssetWebPreziAirWeb', params);
}
export function logInsertedPresentedNote(params) {
  return _log(21298, 'InsertedPresentedNote', 'InsertedPresentedNoteWebPreziAirWeb', params);
}
export function logInsertedSlides(params) {
  return _log(22986, 'InsertedSlides', 'InsertedSlidesWebPreziAirWeb', params);
}
export function logInsertedVideoAsset(params) {
  return _log(22024, 'InsertedVideoAsset', 'InsertedVideoAssetWebPreziAirWeb', params);
}
export function logJoinedGuestZoomOnWeb() {
  return _log(23978, 'JoinedGuestZoomOnWeb', 'JoinedGuestZoomOnWebWebPreziAirWeb');
}
export function logLearnMoreFpsWarning() {
  return _log(21327, 'LearnMoreFpsWarning', 'LearnMoreFpsWarningWebPreziAirWeb');
}
export function logLikeVideoPage(params) {
  return _log(21333, 'LikeVideoPage', 'LikeVideoPageWebPreziAirWeb', params);
}
export function logLikeVideo(params) {
  return _log(21331, 'LikeVideo', 'LikeVideoWebPreziAirWeb', params);
}
export function logLoadedAllowCameraDialog() {
  return _log(22095, 'LoadedAllowCameraDialog', 'LoadedAllowCameraDialogWebPreziAirWeb');
}
export function logLoadedCameraEnableScreen() {
  return _log(21340, 'LoadedCameraEnableScreen', 'LoadedCameraEnableScreenWebPreziAirWeb');
}
export function logLoadedCameraSuccessScreen() {
  return _log(21342, 'LoadedCameraSuccessScreen', 'LoadedCameraSuccessScreenWebPreziAirWeb');
}
export function logLoadedContentEditor(params) {
  return _log(23185, 'LoadedContentEditor', 'LoadedContentEditorWebPreziAirWeb', params);
}
export function logLoadedDashboard() {
  return _log(21804, 'LoadedDashboard', 'LoadedDashboardWebPreziAirWeb');
}
export function logLoadedDownloadAppSplashScreen() {
  return _log(23269, 'LoadedDownloadAppSplashScreen', 'LoadedDownloadAppSplashScreenWebPreziAirWeb');
}
export function logLoadedErrorScreen(params) {
  return _log(21351, 'LoadedErrorScreen', 'LoadedErrorScreenWebPreziAirWeb', params);
}
export function logLoadedPreziVideoHomePage() {
  return _log(21365, 'LoadedPreziVideoHomePage', 'LoadedPreziVideoHomePageWebPreziAirWeb');
}
export function logLoadedPublicEmbedVideoPage(params) {
  return _log(21367, 'LoadedPublicEmbedVideoPage', 'LoadedPublicEmbedVideoPageWebPreziAirWeb', params);
}
export function logLoadedPublicVideoPage(params) {
  return _log(21369, 'LoadedPublicVideoPage', 'LoadedPublicVideoPageWebPreziAirWeb', params);
}
export function logLoadedRecordScreen() {
  return _log(21371, 'LoadedRecordScreen', 'LoadedRecordScreenWebPreziAirWeb');
}
export function logLoadedTemplateChooser() {
  return _log(21373, 'LoadedTemplateChooser', 'LoadedTemplateChooserWebPreziAirWeb');
}
export function logLoadedUserVideoPage(params) {
  return _log(20650, 'LoadedUserVideoPage', 'LoadedUserVideoPageWebPreziAirWeb', params);
}
export function logLoadedVideoDashboard() {
  return _log(21376, 'LoadedVideoDashboard', 'LoadedVideoDashboardWebPreziAirWeb');
}
export function logLoadedVideoExplorePage() {
  return _log(21378, 'LoadedVideoExplorePage', 'LoadedVideoExplorePageWebPreziAirWeb');
}
export function logLoadedVideoExploreResultsPage() {
  return _log(21380, 'LoadedVideoExploreResultsPage', 'LoadedVideoExploreResultsPageWebPreziAirWeb');
}
export function logLoadedVideoPage(params) {
  return _log(21382, 'LoadedVideoPage', 'LoadedVideoPageWebPreziAirWeb', params);
}
export function logLoadedVideoSignupDialog(params) {
  return _log(22107, 'LoadedVideoSignupDialog', 'LoadedVideoSignupDialogWebPreziAirWeb', params);
}
export function logLoadedVideoWorksWithPage() {
  return _log(21384, 'LoadedVideoWorksWithPage', 'LoadedVideoWorksWithPageWebPreziAirWeb');
}
export function logLodadedNameTagModal(params) {
  return _log(23499, 'LodadedNameTagModal', 'LodadedNameTagModalWebPreziAirWeb', params);
}
export function logLogout() {
  return _log(21397, 'Logout', 'LogoutWebPreziAirWeb');
}
export function logManageSubtitles(params) {
  return _log(22188, 'ManageSubtitles', 'ManageSubtitlesWebPreziAirWeb', params);
}
export function logNavigatePresentationStructure(params) {
  return _log(23022, 'NavigatePresentationStructure', 'NavigatePresentationStructureWebPreziAirWeb', params);
}
export function logNavigateToOwnerProfile(params) {
  return _log(21405, 'NavigateToOwnerProfile', 'NavigateToOwnerProfileWebPreziAirWeb', params);
}
export function logNavigateToVideoPage(params) {
  return _log(21407, 'NavigateToVideoPage', 'NavigateToVideoPageWebPreziAirWeb', params);
}
export function logOpenCameraSwitcher(params) {
  return _log(21418, 'OpenCameraSwitcher', 'OpenCameraSwitcherWebPreziAirWeb', params);
}
export function logOpenChapterCardLinkedUrl(params) {
  return _log(19560, 'OpenChapterCardLinkedUrl', 'OpenChapterCardLinkedUrlWebPreziAirWeb', params);
}
export function logOpenChat(params) {
  return _log(16375, 'OpenChat', 'OpenChatWebPreziAirWeb', params);
}
export function logOpenColorPicker(params) {
  return _log(23580, 'OpenColorPicker', 'OpenColorPickerWebPreziAirWeb', params);
}
export function logOpenFinalizeHelper(params) {
  return _log(21435, 'OpenFinalizeHelper', 'OpenFinalizeHelperWebPreziAirWeb', params);
}
export function logOpenHelpMenu() {
  return _log(21437, 'OpenHelpMenu', 'OpenHelpMenuWebPreziAirWeb');
}
export function logOpenMicrophoneSwitcher(params) {
  return _log(21443, 'OpenMicrophoneSwitcher', 'OpenMicrophoneSwitcherWebPreziAirWeb', params);
}
export function logOpenNameTagSidebar(params) {
  return _log(23503, 'OpenNameTagSidebar', 'OpenNameTagSidebarWebPreziAirWeb', params);
}
export function logOpenPreziNextEditor() {
  return _log(21446, 'OpenPreziNextEditor', 'OpenPreziNextEditorWebPreziAirWeb');
}
export function logOpenPrivacyDialog(params) {
  return _log(21448, 'OpenPrivacyDialog', 'OpenPrivacyDialogWebPreziAirWeb', params);
}
export function logOpenSearchResult(params) {
  return _log(27807, 'OpenSearchResult', 'OpenSearchResultWebPreziAirWeb', params);
}
export function logOpenSocialShare(params) {
  return _log(21450, 'OpenSocialShare', 'OpenSocialShareWebPreziAirWeb', params);
}
export function logOpenTemplateChooser(params) {
  return _log(23193, 'OpenTemplateChooser', 'OpenTemplateChooserWebPreziAirWeb', params);
}
export function logOpenTimerSidebar(params) {
  return _log(24307, 'OpenTimerSidebar', 'OpenTimerSidebarWebPreziAirWeb', params);
}
export function logOpenTranscript(params) {
  return _log(21452, 'OpenTranscript', 'OpenTranscriptWebPreziAirWeb', params);
}
export function logOpenTutorialVideo(params) {
  return _log(21454, 'OpenTutorialVideo', 'OpenTutorialVideoWebPreziAirWeb', params);
}
export function logOpenVideoCardMenu(params) {
  return _log(21459, 'OpenVideoCardMenu', 'OpenVideoCardMenuWebPreziAirWeb', params);
}
export function logOpenVideoCard(params) {
  return _log(21457, 'OpenVideoCard', 'OpenVideoCardWebPreziAirWeb', params);
}
export function logOpenVirtualBackgroundSidebar(params) {
  return _log(23536, 'OpenVirtualBackgroundSidebar', 'OpenVirtualBackgroundSidebarWebPreziAirWeb', params);
}
export function logOpenWidgetSidebar(params) {
  return _log(24299, 'OpenWidgetSidebar', 'OpenWidgetSidebarWebPreziAirWeb', params);
}
export function logOpenedLogin(params) {
  return _log(21427, 'OpenedLogin', 'OpenedLoginWebPreziAirWeb', params);
}
export function logOpenedModalZoomOnWebSession() {
  return _log(23974, 'OpenedModalZoomOnWebSession', 'OpenedModalZoomOnWebSessionWebPreziAirWeb');
}
export function logPauseRecording(params) {
  return _log(21463, 'PauseRecording', 'PauseRecordingWebPreziAirWeb', params);
}
export function logPlayTutorialVideo(params) {
  return _log(21484, 'PlayTutorialVideo', 'PlayTutorialVideoWebPreziAirWeb', params);
}
export function logPlaybackBandwidth(params) {
  return _log(21466, 'PlaybackBandwidth', 'PlaybackBandwidthWebPreziAirWeb', params);
}
export function logPlaybackBuffering(params) {
  return _log(21468, 'PlaybackBuffering', 'PlaybackBufferingWebPreziAirWeb', params);
}
export function logPlaybackEnded(params) {
  return _log(21470, 'PlaybackEnded', 'PlaybackEndedWebPreziAirWeb', params);
}
export function logPlaybackHeartbeat(params) {
  return _log(21472, 'PlaybackHeartbeat', 'PlaybackHeartbeatWebPreziAirWeb', params);
}
export function logPlaybackStarted(params) {
  return _log(21474, 'PlaybackStarted', 'PlaybackStartedWebPreziAirWeb', params);
}
export function logPlaybackUserAction(params) {
  return _log(21476, 'PlaybackUserAction', 'PlaybackUserActionWebPreziAirWeb', params);
}
export function logPlaybackVideo(params) {
  return _log(21480, 'PlaybackVideo', 'PlaybackVideoWebPreziAirWeb', params);
}
export function logPracticeRecord() {
  return _log(22116, 'PracticeRecord', 'PracticeRecordWebPreziAirWeb');
}
export function logPreviewViewPage(params) {
  return _log(21486, 'PreviewViewPage', 'PreviewViewPageWebPreziAirWeb', params);
}
export function logProceedWithoutCamera() {
  return _log(22097, 'ProceedWithoutCamera', 'ProceedWithoutCameraWebPreziAirWeb');
}
export function logPublishSubtitles(params) {
  return _log(22190, 'PublishSubtitles', 'PublishSubtitlesWebPreziAirWeb', params);
}
export function logQueriedBrandKits(params) {
  return _log(21489, 'QueriedBrandKits', 'QueriedBrandKitsWebPreziAirWeb', params);
}
export function logRemoveNameTagBackground(params) {
  return _log(23507, 'RemoveNameTagBackground', 'RemoveNameTagBackgroundWebPreziAirWeb', params);
}
export function logRemoveVideoThumbnail(params) {
  return _log(22234, 'RemoveVideoThumbnail', 'RemoveVideoThumbnailWebPreziAirWeb', params);
}
export function logReorderFrame(params) {
  return _log(23035, 'ReorderFrame', 'ReorderFrameWebPreziAirWeb', params);
}
export function logReportIssue() {
  return _log(21500, 'ReportIssue', 'ReportIssueWebPreziAirWeb');
}
export function logResizePresenterNote(params) {
  return _log(21503, 'ResizePresenterNote', 'ResizePresenterNoteWebPreziAirWeb', params);
}
export function logResizedUiElement(params) {
  return _log(22620, 'ResizedUiElement', 'ResizedUiElementWebPreziAirWeb', params);
}
export function logRestartRecording(params) {
  return _log(21507, 'RestartRecording', 'RestartRecordingWebPreziAirWeb', params);
}
export function logResumeRecording(params) {
  return _log(21511, 'ResumeRecording', 'ResumeRecordingWebPreziAirWeb', params);
}
export function logReuseVideo(params) {
  return _log(19995, 'ReuseVideo', 'ReuseVideoWebPreziAirWeb', params);
}
export function logSaveChapter(params) {
  return _log(21903, 'SaveChapter', 'SaveChapterWebPreziAirWeb', params);
}
export function logSaveVideo(params) {
  return _log(22259, 'SaveVideo', 'SaveVideoWebPreziAirWeb', params);
}
export function logSearchAssetProviderFailed(params) {
  return _log(27815, 'SearchAssetProviderFailed', 'SearchAssetProviderFailedWebPreziAirWeb', params);
}
export function logSearchAssetProvider(params) {
  return _log(27811, 'SearchAssetProvider', 'SearchAssetProviderWebPreziAirWeb', params);
}
export function logSearchIcon(params) {
  return _log(21531, 'SearchIcon', 'SearchIconWebPreziAirWeb', params);
}
export function logSearchImage(params) {
  return _log(21535, 'SearchImage', 'SearchImageWebPreziAirWeb', params);
}
export function logSearchPresentation(params) {
  return _log(23331, 'SearchPresentation', 'SearchPresentationWebPreziAirWeb', params);
}
export function logSearchVideo(params) {
  return _log(21538, 'SearchVideo', 'SearchVideoWebPreziAirWeb', params);
}
export function logSearchedForVideosInMsTeams(params) {
  return _log(21528, 'SearchedForVideosInMsTeams', 'SearchedForVideosInMsTeamsWebPreziAirWeb', params);
}
export function logSeekChapter(params) {
  return _log(22932, 'SeekChapter', 'SeekChapterWebPreziAirWeb', params);
}
export function logSeekInTranscript(params) {
  return _log(21542, 'SeekInTranscript', 'SeekInTranscriptWebPreziAirWeb', params);
}
export function logSelectAdvancedCreate() {
  return _log(21544, 'SelectAdvancedCreate', 'SelectAdvancedCreateWebPreziAirWeb');
}
export function logSelectAssetProvider(params) {
  return _log(27819, 'SelectAssetProvider', 'SelectAssetProviderWebPreziAirWeb', params);
}
export function logSelectAsset() {
  return _log(21817, 'SelectAsset', 'SelectAssetWebPreziAirWeb');
}
export function logSelectCloudProvider(params) {
  return _log(24370, 'SelectCloudProvider', 'SelectCloudProviderWebPreziAirWeb', params);
}
export function logSelectEditorMenuItem(params) {
  return _log(22843, 'SelectEditorMenuItem', 'SelectEditorMenuItemWebPreziAirWeb', params);
}
export function logSelectFrame(params) {
  return _log(22763, 'SelectFrame', 'SelectFrameWebPreziAirWeb', params);
}
export function logSelectGoLive(params) {
  return _log(21559, 'SelectGoLive', 'SelectGoLiveWebPreziAirWeb', params);
}
export function logSelectIconsTab(params) {
  return _log(21563, 'SelectIconsTab', 'SelectIconsTabWebPreziAirWeb', params);
}
export function logSelectImagesTab(params) {
  return _log(21567, 'SelectImagesTab', 'SelectImagesTabWebPreziAirWeb', params);
}
export function logSelectPreziToImport(params) {
  return _log(23200, 'SelectPreziToImport', 'SelectPreziToImportWebPreziAirWeb', params);
}
export function logSelectSlideFileToImport(params) {
  return _log(22461, 'SelectSlideFileToImport', 'SelectSlideFileToImportWebPreziAirWeb', params);
}
export function logSelectTemplate(params) {
  return _log(23042, 'SelectTemplate', 'SelectTemplateWebPreziAirWeb', params);
}
export function logSelectText() {
  return _log(24580, 'SelectText', 'SelectTextWebPreziAirWeb');
}
export function logSelectVideoThumbnail(params) {
  return _log(22435, 'SelectVideoThumbnail', 'SelectVideoThumbnailWebPreziAirWeb', params);
}
export function logSelectVideo(params) {
  return _log(21600, 'SelectVideo', 'SelectVideoWebPreziAirWeb', params);
}
export function logSelectedSlidesToImport(params) {
  return _log(22481, 'SelectedSlidesToImport', 'SelectedSlidesToImportWebPreziAirWeb', params);
}
export function logSentUserToZoomLoginPage() {
  return _log(23980, 'SentUserToZoomLoginPage', 'SentUserToZoomLoginPageWebPreziAirWeb');
}
export function logSetPlaybackSpeed(params) {
  return _log(21604, 'SetPlaybackSpeed', 'SetPlaybackSpeedWebPreziAirWeb', params);
}
export function logSetPrivacy(params) {
  return _log(21606, 'SetPrivacy', 'SetPrivacyWebPreziAirWeb', params);
}
export function logShareScreen(params) {
  return _log(24386, 'ShareScreen', 'ShareScreenWebPreziAirWeb', params);
}
export function logShareToGoogleClassroom(params) {
  return _log(21611, 'ShareToGoogleClassroom', 'ShareToGoogleClassroomWebPreziAirWeb', params);
}
export function logShareVideo(params) {
  return _log(21614, 'ShareVideo', 'ShareVideoWebPreziAirWeb', params);
}
export function logShowFpsWarning(params) {
  return _log(16379, 'ShowFpsWarning', 'ShowFpsWarningWebPreziAirWeb', params);
}
export function logShowOnboardingHelper(params) {
  return _log(21623, 'ShowOnboardingHelper', 'ShowOnboardingHelperWebPreziAirWeb', params);
}
export function logShowTemplateList() {
  return _log(21625, 'ShowTemplateList', 'ShowTemplateListWebPreziAirWeb');
}
export function logShowTooltip(params) {
  return _log(21627, 'ShowTooltip', 'ShowTooltipWebPreziAirWeb', params);
}
export function logShowUploadImageOptions(params) {
  return _log(21629, 'ShowUploadImageOptions', 'ShowUploadImageOptionsWebPreziAirWeb', params);
}
export function logShowVideoList() {
  return _log(21631, 'ShowVideoList', 'ShowVideoListWebPreziAirWeb');
}
export function logShowedLeavePageWarning(params) {
  return _log(21616, 'ShowedLeavePageWarning', 'ShowedLeavePageWarningWebPreziAirWeb', params);
}
export function logSkipOnboarding() {
  return _log(21651, 'SkipOnboarding', 'SkipOnboardingWebPreziAirWeb');
}
export function logSlideImportError(params) {
  return _log(22469, 'SlideImportError', 'SlideImportErrorWebPreziAirWeb', params);
}
export function logSlidesConverted(params) {
  return _log(22506, 'SlidesConverted', 'SlidesConvertedWebPreziAirWeb', params);
}
export function logStartRecording(params) {
  return _log(21660, 'StartRecording', 'StartRecordingWebPreziAirWeb', params);
}
export function logStartVideoTrimmer(params) {
  return _log(21664, 'StartVideoTrimmer', 'StartVideoTrimmerWebPreziAirWeb', params);
}
export function logStartedFromNext(params) {
  return _log(21653, 'StartedFromNext', 'StartedFromNextWebPreziAirWeb', params);
}
export function logStopRecording(params) {
  return _log(21671, 'StopRecording', 'StopRecordingWebPreziAirWeb', params);
}
export function logStopShareScreen(params) {
  return _log(24390, 'StopShareScreen', 'StopShareScreenWebPreziAirWeb', params);
}
export function logStoppedWindowSizeWarning() {
  return _log(21667, 'StoppedWindowSizeWarning', 'StoppedWindowSizeWarningWebPreziAirWeb');
}
export function logSubmittedVideoToMsTeams(params) {
  return _log(21677, 'SubmittedVideoToMsTeams', 'SubmittedVideoToMsTeamsWebPreziAirWeb', params);
}
export function logSwitchContentDisplay(params) {
  return _log(21845, 'SwitchContentDisplay', 'SwitchContentDisplayWebPreziAirWeb', params);
}
export function logSwitchPresentationMode(params) {
  return _log(23951, 'SwitchPresentationMode', 'SwitchPresentationModeWebPreziAirWeb', params);
}
export function logSwitchShareScreenMode(params) {
  return _log(23963, 'SwitchShareScreenMode', 'SwitchShareScreenModeWebPreziAirWeb', params);
}
export function logSwitchedNameTagVisibility(params) {
  return _log(24352, 'SwitchedNameTagVisibility', 'SwitchedNameTagVisibilityWebPreziAirWeb', params);
}
export function logSwitchedTimerVisibility(params) {
  return _log(24311, 'SwitchedTimerVisibility', 'SwitchedTimerVisibilityWebPreziAirWeb', params);
}
export function logToggleAudioTranscriptOff(params) {
  return _log(21684, 'ToggleAudioTranscriptOff', 'ToggleAudioTranscriptOffWebPreziAirWeb', params);
}
export function logToggleAudioTranscriptOn(params) {
  return _log(21686, 'ToggleAudioTranscriptOn', 'ToggleAudioTranscriptOnWebPreziAirWeb', params);
}
export function logToggleCaptions(params) {
  return _log(21688, 'ToggleCaptions', 'ToggleCaptionsWebPreziAirWeb', params);
}
export function logToggleChromaKey(params) {
  return _log(23576, 'ToggleChromaKey', 'ToggleChromaKeyWebPreziAirWeb', params);
}
export function logToggleFlipTemplate(params) {
  return _log(23914, 'ToggleFlipTemplate', 'ToggleFlipTemplateWebPreziAirWeb', params);
}
export function logToggleTimer(params) {
  return _log(24319, 'ToggleTimer', 'ToggleTimerWebPreziAirWeb', params);
}
export function logToggleUiElement(params) {
  return _log(23061, 'ToggleUiElement', 'ToggleUiElementWebPreziAirWeb', params);
}
export function logTrimVideo(params) {
  return _log(21697, 'TrimVideo', 'TrimVideoWebPreziAirWeb', params);
}
export function logUnlikeVideoPage(params) {
  return _log(21705, 'UnlikeVideoPage', 'UnlikeVideoPageWebPreziAirWeb', params);
}
export function logUnlikeVideo(params) {
  return _log(21703, 'UnlikeVideo', 'UnlikeVideoWebPreziAirWeb', params);
}
export function logUpdateManualChapters(params) {
  return _log(22930, 'UpdateManualChapters', 'UpdateManualChaptersWebPreziAirWeb', params);
}
export function logUpdatedSubtitles(params) {
  return _log(22192, 'UpdatedSubtitles', 'UpdatedSubtitlesWebPreziAirWeb', params);
}
export function logUploadAsset(params) {
  return _log(22373, 'UploadAsset', 'UploadAssetWebPreziAirWeb', params);
}
export function logUploadImageCancelled(params) {
  return _log(22265, 'UploadImageCancelled', 'UploadImageCancelledWebPreziAirWeb', params);
}
export function logUploadImage(params) {
  return _log(22263, 'UploadImage', 'UploadImageWebPreziAirWeb', params);
}
export function logUploadNameTagBackground(params) {
  return _log(23522, 'UploadNameTagBackground', 'UploadNameTagBackgroundWebPreziAirWeb', params);
}
export function logUploadVideoThumbnailLimitError(params) {
  return _log(22228, 'UploadVideoThumbnailLimitError', 'UploadVideoThumbnailLimitErrorWebPreziAirWeb', params);
}
export function logUploadVideoThumbnail(params) {
  return _log(22230, 'UploadVideoThumbnail', 'UploadVideoThumbnailWebPreziAirWeb', params);
}
export function logUploadVirtualBackgroundMedia(params) {
  return _log(23540, 'UploadVirtualBackgroundMedia', 'UploadVirtualBackgroundMediaWebPreziAirWeb', params);
}
export function logUploadedAsset(params) {
  return _log(22400, 'UploadedAsset', 'UploadedAssetWebPreziAirWeb', params);
}
export function logUploadedImage(params) {
  return _log(22261, 'UploadedImage', 'UploadedImageWebPreziAirWeb', params);
}
export function logUploadedNameTagBackground(params) {
  return _log(23518, 'UploadedNameTagBackground', 'UploadedNameTagBackgroundWebPreziAirWeb', params);
}
export function logUploadedVideoAsset(params) {
  return _log(22020, 'UploadedVideoAsset', 'UploadedVideoAssetWebPreziAirWeb', params);
}
export function logUploadedVideoThumbnail(params) {
  return _log(22232, 'UploadedVideoThumbnail', 'UploadedVideoThumbnailWebPreziAirWeb', params);
}
export function logUploadedVirtualBackgroundMedia(params) {
  return _log(23614, 'UploadedVirtualBackgroundMedia', 'UploadedVirtualBackgroundMediaWebPreziAirWeb', params);
}
export function logUseContent(params) {
  return _log(21719, 'UseContent', 'UseContentWebPreziAirWeb', params);
}
export function logUsedScript(params) {
  return _log(21724, 'UsedScript', 'UsedScriptWebPreziAirWeb', params);
}
export function logVideoAssetError(params) {
  return _log(22001, 'VideoAssetError', 'VideoAssetErrorWebPreziAirWeb', params);
}
export function logVideoDownloadStarted(params) {
  return _log(21730, 'VideoDownloadStarted', 'VideoDownloadStartedWebPreziAirWeb', params);
}
export function logVideoNaviAbConditions(params) {
  return _log(21732, 'VideoNaviAbConditions', 'VideoNaviAbConditionsWebPreziAirWeb', params);
}
export function logViewVideoTag(params) {
  return _log(21734, 'ViewVideoTag', 'ViewVideoTagWebPreziAirWeb', params);
}
export function logVisitVideoDashboard() {
  return _log(21736, 'VisitVideoDashboard', 'VisitVideoDashboardWebPreziAirWeb');
}
export function logVisitVideoHomepage() {
  return _log(21738, 'VisitVideoHomepage', 'VisitVideoHomepageWebPreziAirWeb');
}
export function logVisitVideoViewPage(params) {
  return _log(21740, 'VisitVideoViewPage', 'VisitVideoViewPageWebPreziAirWeb', params);
}
export function logZoomOnWebSessionEnded(params) {
  return _log(23972, 'ZoomOnWebSessionEnded', 'ZoomOnWebSessionEndedWebPreziAirWeb', params);
}
export function logZoomOnWebSessionStarted() {
  return _log(23970, 'ZoomOnWebSessionStarted', 'ZoomOnWebSessionStartedWebPreziAirWeb');
}