import * as React from 'react';
import { Colors } from '@prezi/dragon';
export function Avatar(props) {
  const style = {
    fontFamily: 'RalewayBold, Helvetica, sans-serif',
    color: '#FFFFFF',
    width: props.size,
    height: props.size,
    borderRadius: props.size / 2,
    textAlign: 'center',
    fontSize: 0.44 * props.size,
    lineHeight: 1.05 * props.size + 'px',
    letterSpacing: 1,
    backgroundColor: calculateColor(props.username, props.email)
  };
  const className = props.className != null ? props.className : '';
  return React.createElement("div", {
    className: ('user-avatar ' + className).trim(),
    style: style,
    "data-lookup": props['data-lookup']
  }, getInitials(props.firstName, props.lastName, props.email));
}
const COLOR_PALETTE = [Colors.blueLight, '#4CD3D1', Colors.greenLight, '#E28BC0', Colors.orange, Colors.purple, Colors.magenta, Colors.gold];
const UNKNOWN_COLOR = Colors.gray4;
function calculateColor(username, email) {
  const _calculateColor = hashBase => COLOR_PALETTE[Math.abs(hash(hashBase)) % COLOR_PALETTE.length];
  if (email) {
    return _calculateColor(email);
  } else if (username) {
    return _calculateColor(username);
  } else {
    return UNKNOWN_COLOR;
  }
}
function hash(str) {
  if (str == null) {
    return 0;
  }
  let hashVal = 0;
  for (let i = 0; i < str.length; i++) {
    const charCode = str.charCodeAt(i);
    hashVal = (hashVal << 5) - hashVal + charCode;
    hashVal |= 0;
  }
  return hashVal;
}
function getInitials(firstName, lastName, email) {
  let initials = '';
  const firstNameInitials = firstName && firstName.charAt(0);
  const lastNameInitials = lastName && lastName.charAt(0);
  if (firstNameInitials || lastNameInitials) {
    initials = `${firstNameInitials}${lastNameInitials}`;
  } else if (email) {
    initials = email.charAt(0);
  }
  return initials.toLocaleUpperCase();
}