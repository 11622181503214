import * as React from 'react';
import { CTAButton } from './Styles';
import { glassboxWebJs } from '@prezi/client-logger-module';
export var CTA = props => {
  var className = props.className || '';
  if (props.isDeemphasized) {
    className += ' deemphasized';
  } else if (props.isGray) {
    className += ' gray';
  }
  React.useEffect(() => {
    if (props.isMakeYourOwnWithAi) {
      glassboxWebJs.logEnteredAbTestExperience({
        ab_test_name: 'gm-abtest-make-your-own-with-ai'
      });
    }
  }, []);
  return React.createElement(CTAButton, {
    onClick: props.onClick,
    href: props.href,
    className: className,
    "data-lookup": props.lookupName,
    target: props.openNewTab ? '_blank' : undefined
  }, props.innerText);
};