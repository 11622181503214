export var ResponsiveMode = function (ResponsiveMode) {
  ResponsiveMode["LARGE"] = "large";
  ResponsiveMode["MIDDLE"] = "middle";
  ResponsiveMode["SMALL"] = "small";
  return ResponsiveMode;
}({});
export var PreziSchema = function (PreziSchema) {
  PreziSchema["CLASSIC"] = "CLASSIC";
  PreziSchema["NEXT"] = "NEXT";
  return PreziSchema;
}({});