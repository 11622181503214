import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
export var SocialNetwork = function (SocialNetwork) {
  SocialNetwork["FACEBOOK"] = "FACEBOOK";
  SocialNetwork["LINKEDIN"] = "LINKEDIN";
  SocialNetwork["TWITTER"] = "TWITTER";
  SocialNetwork["GOOGLE_CLASSROOM"] = "GOOGLE_CLASSROOM";
  return SocialNetwork;
}({});
var SocialLinks = {
  [SocialNetwork.FACEBOOK]: 'https://www.facebook.com/sharer.php?u=',
  [SocialNetwork.LINKEDIN]: 'https://www.linkedin.com/shareArticle?url=',
  [SocialNetwork.TWITTER]: 'https://twitter.com/share?url=',
  [SocialNetwork.GOOGLE_CLASSROOM]: 'https://classroom.google.com/share?url='
};
var defaultPopUpDimensions = {
  width: 520,
  height: 570
};
export var openSocialNetworkPopup = (network, shareUrl) => {
  var popUpDimensions = network === 'LINKEDIN' ? {
    width: 1300,
    height: 570
  } : defaultPopUpDimensions;
  var left = window.innerWidth / 2 - popUpDimensions.width / 2 + window.screenX;
  var top = window.innerHeight / 2 - popUpDimensions.height / 2 + window.screenY;
  window.open(shareUrl, '_blank', `location=yes,height=${popUpDimensions.height}px,width=${popUpDimensions.width}px,scrollbars=yes,status=yes,left=${left},top=${top}`);
};
export var getSocialNetworkUrl = function getSocialNetworkUrl(network, preziTitle) {
  var tags = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  return getSocialNetworkUrlExplicit(window.location.href, network, preziTitle, tags);
};
export var getSocialNetworkUrlExplicit = function getSocialNetworkUrlExplicit(pageUrl, network, preziTitle) {
  var tags = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
  if (network === SocialNetwork.GOOGLE_CLASSROOM) {
    return SocialLinks[network] + encodeURIComponent(pageUrl) + `&title=${encodeURIComponent(preziTitle)}`;
  }
  var hashtags = tags.length > 0 && tags[0] !== '' ? tags.join(',') : null;
  return SocialLinks[network] + encodeURIComponent(pageUrl) + `&text=${encodeURIComponent(preziTitle)}` + (hashtags ? `&hashtags=${encodeURIComponent(hashtags)}` : '');
};
export var getSocialNetworkAltText = (network, preziTitle) => {
  return `Button to share ${preziTitle} in ${network.split('_').join(' ').toLowerCase()}`;
};