import { polyfill as promisePolyfill } from 'es6-promise';
import axios from 'axios';
promisePolyfill();
export let LogLevel = function (LogLevel) {
  LogLevel[LogLevel["INFO"] = 0] = "INFO";
  LogLevel[LogLevel["WARN"] = 1] = "WARN";
  LogLevel[LogLevel["ERROR"] = 2] = "ERROR";
  return LogLevel;
}({});
export class ClientLogger {
  constructor(config) {
    this.config = config;
    if (config.isProduction && !config.logApiEndpoint) {
      throw new Error('Missing logApiEndpoint in production mode!');
    }
  }
  sendLogRequest(data) {
    return Promise.resolve().then(() => axios.post(this.config.logApiEndpoint, [data], {
      headers: {
        'Content-Type': 'application/json'
      },
      responseType: 'json',
      timeout: 20000
    })).then(_response => {});
  }
  log(logMessage) {
    if (logMessage == null) {
      return Promise.reject('logMessage is null');
    }
    if (this.config.fillPayloadDefaults) {
      try {
        logMessage = this.config.fillPayloadDefaults(logMessage);
      } catch (e) {
        return Promise.reject(e);
      }
    }
    const preparedLogMessage = this.prepareLogMessage(logMessage);
    if (!this.config.isProduction) {
      if (console) {
        console.log(preparedLogMessage);
      }
      return Promise.resolve(undefined);
    } else {
      return this.sendLogRequest(preparedLogMessage).catch(error => {
        return Promise.reject(error);
      });
    }
  }
  prepareLogMessage(logMessage) {
    const currentDate = logMessage.currentDate || new Date();
    const currentTime = currentDate.getTime();
    return {
      ...logMessage.payload,
      current_time: currentTime,
      created: Math.floor(currentTime / 1000),
      msecs: currentTime % 1000,
      client_datetime: currentDate.toISOString(),
      _object: logMessage.object,
      object: logMessage.object,
      _action: logMessage.action,
      trigger: logMessage.trigger,
      _category: logMessage.category || 'website',
      source: logMessage.source || this.config.defaultSource,
      levelname: LogLevel[logMessage.logLevel || this.config.defaultLogLevel],
      name: logMessage.name || 'debug',
      levelno: logMessage.levelno || 20,
      relativeCreated: logMessage.relativeCreated || 0,
      msg: logMessage.msg || '',
      platform: logMessage.platform || 'website',
      session_id: logMessage.session_id || '',
      ...(logMessage.prezi_id ? {
        prezi_id: logMessage.prezi_id
      } : {})
    };
  }
}