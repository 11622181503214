import * as React from 'react';
import styled from 'styled-components';

import { WindowSizeBreakpoints, Spinner } from '@prezi/common-components';

import { VIEWER_ID } from '../../constants';
import { MountDOMAsync } from '../MountDOMAsync';
import { LinkID } from '../../models/appData';

const SLIDE_VIEW_ASPECT_RATIO = 2 / 3;

const FallbackWrapper = styled.div`
    background-color: white;
    opacity: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: ${SLIDE_VIEW_ASPECT_RATIO * 100}vw;

    @media (min-width: ${WindowSizeBreakpoints.LARGE}px) {
        height: 487px;
    }
`;

export interface ViewerProps {
    oid: string;
    linkID: LinkID;
}

async function renderDesignProject(oid: string, viewlink: LinkID, ref: HTMLDivElement | null) {
    const designRendererUrl = 'https://cdn-prezi.jifo.co/prezigram/viewer.js';
    const { renderProject } = await import(/* webpackIgnore: true */ /* @vite-ignore */ designRendererUrl);
    await renderProject(ref, { oid, viewlink });
}

export const Viewer: React.FC<ViewerProps> = (props: ViewerProps) => {
    return (
        <MountDOMAsync
            id={VIEWER_ID}
            fetchAndMount={(ref: HTMLDivElement | null) => renderDesignProject(props.oid, props.linkID, ref)}
            fallback={
                <FallbackWrapper id="viewer-fallback">
                    <Spinner size="lg" />
                </FallbackWrapper>
            }
        />
    );
};
