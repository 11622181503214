import React from 'react';
import { StyledSocialLink } from './Styles';
import { openSocialNetworkPopup, getSocialNetworkUrlExplicit, getSocialNetworkAltText } from '../../api/socialNetwork';
import { SocialNetworkIcon } from './SocialMediaSvgs';
import ToolTip from '../Tooltip/Tooltip';
export var SocialMediaButton = _ref => {
  var logSocialShare = _ref.logSocialShare,
    network = _ref.network,
    tags = _ref.tags,
    title = _ref.title,
    tooltipText = _ref.tooltipText,
    viewUrl = _ref.viewUrl,
    size = _ref.size,
    disabled = _ref.disabled;
  if (!network) {
    return null;
  }
  var pageUrl = viewUrl || window.location.href;
  var shareUrl = getSocialNetworkUrlExplicit(pageUrl, network, title, tags);
  var onClick = e => {
    e.preventDefault();
    if (!disabled) {
      openSocialNetworkPopup(network, shareUrl);
      logSocialShare(network);
    }
  };
  var dataLookup = network.toLowerCase() + '-network';
  var titleText = getSocialNetworkAltText(network, title);
  return React.createElement(StyledSocialLink, {
    className: 'social-btn',
    onClick: onClick,
    "data-lookup": dataLookup,
    "aria-label": titleText,
    tabIndex: 0,
    onKeyPress: event => event.key === 'Enter' && onClick(event)
  }, React.createElement(SocialNetworkIcon, {
    network: network,
    size: size
  }), tooltipText && React.createElement("div", {
    className: 'tooltip-container'
  }, React.createElement(ToolTip, {
    type: 'bottom',
    visible: true,
    tooltipText: tooltipText
  })));
};
export default SocialMediaButton;