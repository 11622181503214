import styled, { keyframes } from 'styled-components';
import { Colors } from '@prezi/dragon';
import { CDN_PREFIX } from '../Const';
import { BaseTheme } from '../../themes/baseTheme';
export var reportButtonClassName = 'report-button';
export var reuseButtonClassName = 'reuse-button';
export var embedButtonClassName = 'embed-button';
export var curationButtonClassName = 'curation-button';
var getFilter = hexColor => {
  var colorToFilterMap = {
    [Colors.neutral400]: 'invert(52%) sepia(10%) saturate(429%) hue-rotate(175deg) brightness(90%) contrast(85%)',
    [Colors.neutral600]: 'invert(22%) sepia(8%) saturate(1627%) hue-rotate(180deg) brightness(97%) contrast(89%)',
    [Colors.purple400]: 'invert(27%) sepia(46%) saturate(4059%) hue-rotate(284deg) brightness(99%) contrast(102%)'
  };
  return colorToFilterMap[hexColor];
};
export var ToolbarButtonBase = styled.a.attrs(props => {
  return {
    className: props.className
  };
}).withConfig({
  displayName: "Styles__ToolbarButtonBase",
  componentId: "sc-1dyyumc-0"
})(["font-size:18px;margin:0 6px 0 0;display:inline-flex;align-items:center;justify-content:center;height:42px;color:", ";text-decoration:none;cursor:pointer;user-select:none;&:hover{text-decoration:none;color:", ";}img{filter:", ";-webkit-filter:", ";}"], Colors.neutral600, Colors.neutral600, getFilter(Colors.neutral600), getFilter(Colors.neutral600));
export var StyledViews = styled(ToolbarButtonBase).withConfig({
  displayName: "Styles__StyledViews",
  componentId: "sc-1dyyumc-1"
})(["cursor:default;color:", ";text-decoration:none;font-size:16px;line-height:18px;margin:0 12px 0 0;&:hover{text-decoration:none;color:", ";}img{margin-right:6px;filter:", ";-webkit-filter:", ";}"], Colors.neutral400, Colors.neutral400, getFilter(Colors.neutral400), getFilter(Colors.neutral400));
var ActionButton = styled(ToolbarButtonBase).withConfig({
  displayName: "Styles__ActionButton",
  componentId: "sc-1dyyumc-2"
})(["border-radius:42px;border:1px solid ", ";transition:border 200ms;padding:12px 12px 12px 10px;color:", ";&.active{border-color:", ";}@media screen and (min-width:768px){&:hover{border-color:", ";}}"], Colors.neutral80, Colors.gray2, props => props.theme.buttonBorderColor, props => props.theme.buttonBorderColor);
ActionButton.defaultProps = {
  theme: BaseTheme
};
var likePulseAnimation = keyframes(["from{transform:scale3d(1,1,1);}50%{transform:scale3d(1.5,1.5,1.5);}to{transform:scale3d(1,1,1);}"]);
var errorShakeAnimation = keyframes(["from,to{transform:translate3d(0,0,0);}16.6667%,50%,83.3334%{transform:translate3d(-3px,0,0);}33.3334%,66.6667%{transform:translate3d(3px,0,0);}"]);
var unlikeButtonBorderAnimation = props => keyframes(["0%{border-color:", ";}50%{border-color:", ";}75%{border-color:", ";}100%{border-color:", ";}"], props.theme.buttonBorderColor, props.theme.buttonBorderColor, Colors.neutral80, Colors.neutral80);
var unlikeHeartBrokenAnimation = props => keyframes(["0%{filter:", ";-webkit-filter:", ";}25%{filter:", ";-webkit-filter:", ";}50%{filter:", ";-webkit-filter:", ";}100%{filter:", ";-webkit-filter:", ";}"], props.theme.iconFilterColor, props.theme.iconFilterColor, props.theme.iconFilterColor, props.theme.iconFilterColor, props.theme.iconFilterColor, props.theme.iconFilterColor, props.theme.iconFilterColor, props.theme.iconFilterColor);
export var Likes = styled(ActionButton).withConfig({
  displayName: "Styles__Likes",
  componentId: "sc-1dyyumc-3"
})(["::before{display:none;content:url(", ");}transition:all 200ms ease-in-out;padding:", ";min-width:42px;font-size:16px;line-height:18px;img{transition:filter 200ms ease-in-out;}&.liked{border:1px solid ", ";img{filter:", ";-webkit-filter:", ";}}&.error-animation{animation:", " 500ms;}&.liked-animation{img{animation:", " 350ms;}}&.unliked-animation{animation:", " 2000ms;img{animation:", " 2000ms;}}"], CDN_PREFIX + 'heartbroken.svg', props => props.hasLikes ? '0 12px' : '0', props => props.theme.buttonBorderColor, props => props.theme.iconFilterColor, props => props.theme.iconFilterColor, errorShakeAnimation, likePulseAnimation, props => unlikeButtonBorderAnimation(props), props => unlikeHeartBrokenAnimation(props));
var RoundActionButton = styled(ActionButton).withConfig({
  displayName: "Styles__RoundActionButton",
  componentId: "sc-1dyyumc-4"
})(["width:42px;padding:0;"]);
var MobileBaseButton = styled(RoundActionButton).withConfig({
  displayName: "Styles__MobileBaseButton",
  componentId: "sc-1dyyumc-5"
})(["display:inline-flex;&:active{border-color:", ";}"], props => props.theme.buttonBorderColor);
export var DesktopReuseButton = styled(RoundActionButton).attrs(props => ({
  className: `${props.className} ${reuseButtonClassName}`
})).withConfig({
  displayName: "Styles__DesktopReuseButton",
  componentId: "sc-1dyyumc-6"
})([""]);
export var MobileReuseButton = styled(MobileBaseButton).attrs(props => ({
  className: `${props.className} mobile-reuse-button`
})).withConfig({
  displayName: "Styles__MobileReuseButton",
  componentId: "sc-1dyyumc-7"
})([""]);
export var StyledEmbedButton = styled(RoundActionButton).attrs(props => ({
  className: `${props.className} ${embedButtonClassName}`
})).withConfig({
  displayName: "Styles__StyledEmbedButton",
  componentId: "sc-1dyyumc-8"
})([""]);
export var ShareButton = styled(MobileBaseButton).withConfig({
  displayName: "Styles__ShareButton",
  componentId: "sc-1dyyumc-9"
})([""]);
export var DesktopReportButton = styled(RoundActionButton).attrs({
  className: reportButtonClassName
}).withConfig({
  displayName: "Styles__DesktopReportButton",
  componentId: "sc-1dyyumc-10"
})([""]);
export var MobileReportButton = styled(MobileBaseButton).attrs({
  className: reportButtonClassName
}).withConfig({
  displayName: "Styles__MobileReportButton",
  componentId: "sc-1dyyumc-11"
})([""]);
export var StyledCurationButton = styled(RoundActionButton).attrs({
  className: curationButtonClassName
}).withConfig({
  displayName: "Styles__StyledCurationButton",
  componentId: "sc-1dyyumc-12"
})(["fill:", ";"], props => props.curationExists ? props.theme.buttonColor : Colors.neutral600);
export var StyledSocialLink = styled(ToolbarButtonBase).withConfig({
  displayName: "Styles__StyledSocialLink",
  componentId: "sc-1dyyumc-13"
})(["margin-right:28px;& > .tooltip-container{position:absolute;top:40px;display:none;}&:hover > .tooltip-container{display:inline-block;}"]);
export var CTAButton = styled(ToolbarButtonBase).withConfig({
  displayName: "Styles__CTAButton",
  componentId: "sc-1dyyumc-14"
})(["background-color:", ";border-color:", ";display:flex;cursor:pointer;text-align:center;border-radius:", ";border:1px solid transparent;font-family:RalewaySemiBold,Helvetica,sans-serif;font-weight:600;font-size:13px;line-height:1.5;letter-spacing:1px;padding:10px 20px 11px;user-select:none;color:", ";@media screen and (max-width:768px){padding:4px 12px 5px;}&:hover{background-color:", ";border-color:", ";color:", ";text-decoration:none;}&:focus{text-decoration:none;color:", ";outline:none;}&.gray{background-color:#475262;border-color:#475262;&:hover{background-color:#58667a;border-color:#58667a;}}&.deemphasized{background-color:#e4ebfd;border-color:#e4ebfd;color:#1e55eb;&:hover{background-color:#c4d3fa;border-color:#c4d3fa;}}"], props => props.theme.buttonColor, props => props.theme.buttonColor, props => props.theme.CTABorderRadius, props => props.theme.buttonTextColor, props => props.theme.buttonColorHover, props => props.theme.buttonColorHover, props => props.theme.buttonTextColor, props => props.theme.buttonTextColor);
CTAButton.defaultProps = {
  theme: BaseTheme
};
export var DownloadButtonStyled = styled.button.attrs(props => {
  return {
    className: props.className
  };
}).withConfig({
  displayName: "Styles__DownloadButtonStyled",
  componentId: "sc-1dyyumc-15"
})(["margin:0 6px 0 0;display:inline-flex;align-items:center;justify-content:center;gap:8px;height:42px;color:", ";text-decoration:none;cursor:pointer;user-select:none;background-color:", ";border-color:", ";display:flex;cursor:pointer;text-align:center;border-radius:", ";border:1px solid transparent;font-family:RalewaySemiBold,Helvetica,sans-serif;font-weight:600;font-size:13px;line-height:1.5;letter-spacing:1px;padding:10px 20px 11px;user-select:none;color:", ";@media screen and (max-width:768px){padding:4px 12px 5px;}&:hover{background-color:", ";border-color:", ";color:", ";text-decoration:none;}&:focus{text-decoration:none;color:", ";outline:none;}"], Colors.neutral600, props => props.theme.buttonColor, props => props.theme.buttonColor, props => props.theme.CTABorderRadius, props => props.theme.buttonTextColor, props => props.theme.buttonColorHover, props => props.theme.buttonColorHover, props => props.theme.buttonTextColor, props => props.theme.buttonTextColor);