import * as React from 'react';
import OutsideClickHandler from 'react-outside-click-handler';

import { Colors } from '@prezi/dragon';
import { glassboxPreziAirWeb, glassboxWebJs } from '@prezi/client-logger-module';
import { getSize, ResponsiveMode, Overlay } from '@prezi/common-components';

import * as Features from '../../globals/Features';
import { SearchBarData, HeaderProps } from '../../models/header';
import SearchBar, { SEARCH_BAR_TEXT_INPUT_ID } from '../SearchBar/SearchBar';
import { SearchBarButton, SearchBarButtonSpan, SearchIcon } from '../SearchBar/Styles';
import {
    CTAButton,
    CreateButton,
    DownArrowNav,
    LoggedInNavName,
    LoginLink,
    LogoImg,
    LogoLink,
    NavBarLinks,
    Row,
    VideoHeader,
} from './Styles';
import { NameContainer, DropdownMenuItem, DropdownMenu } from '../DropdownStyles';

// same as defined in /packages/video-view-page/src/cta-flows.ts
const FREEMIUM_SIGNUP_WITH_VIDEO_INTENT_URL = `/signup/?next=${encodeURIComponent(
    '/dashboard/next?after_segmentation_url=/v/create/',
)}`;
const CREATE_YOURS_URL = '/v/create/';
export interface VideoSearchHeaderProps extends HeaderProps {
    getStartedCTA: string;
    searchBarData: SearchBarData;
    handleSearchTerm?: (searchTerm: string) => void;
    showSearchOnMobile: boolean;
    createYourOwnCTA?: string;
    forwardRef?: React.RefObject<HTMLDivElement>;
}

export interface VideoSearchHeaderState {
    toggleMenu: boolean;
    mobileSearchExpanded: boolean;
}

function handleOnFocus(_: React.FocusEvent<HTMLInputElement>) {
    glassboxPreziAirWeb.logEnterSearchBar();
}

/**
 * Returns a promise that resolves in 0.5s
 */
const getGlassboxTimeoutPromise = () =>
    new Promise(resolve => {
        setTimeout(() => {
            resolve();
        }, 500);
    });

export class VideoSearchHeader extends React.Component<VideoSearchHeaderProps, VideoSearchHeaderState> {
    private node: any;
    private ctaTarget: string;
    private inputRef: HTMLElement | null;
    private searchBarButtonRef: HTMLButtonElement | null;

    constructor(props: VideoSearchHeaderProps) {
        super(props);
        Features.init(props.evaluatedFeatures || {});
        this.state = {
            toggleMenu: false,
            mobileSearchExpanded: false,
        };
        this.ctaTarget = props.ctaTarget
            ? props.ctaTarget
            : Features.isActive('ua-retest-ctas-lead-to-freemium-on-view-pages')
            ? '/pricing/'
            : FREEMIUM_SIGNUP_WITH_VIDEO_INTENT_URL;
        this.inputRef = null;
        this.searchBarButtonRef = null;
    }

    onCtaButtonClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        const targetUrl = this.ctaTarget;
        glassboxWebJs.logVisitPricingPage({
            cta_location: 'header',
            cta_text: this.props.getStartedCTA,
            cta_type: 'BUTTON',
            target_url: targetUrl,
        });
        window.location.assign(targetUrl);
    };

    onCreateButtonClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();
        window.location.assign(CREATE_YOURS_URL);
    };

    toggleDropdownMenu = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement>) => {
        event.preventDefault();

        const { toggleMenu } = this.state;
        this.setState({ toggleMenu: !toggleMenu });
    };

    onOutsideClick = (event: React.MouseEvent<HTMLElement>) => {
        event.preventDefault();

        if (this.node.contains(event.target)) {
            return;
        }

        const { toggleMenu } = this.state;
        if (toggleMenu) {
            this.setState({ toggleMenu: false });
        }
    };

    onQuerySubmit = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();
        const { mobileSearchExpanded } = this.state;

        if (getSize(window.innerWidth) === ResponsiveMode.EXTRA_SMALL && !mobileSearchExpanded) {
            this.setSearchExpanded(true);
            if (this.inputRef) {
                this.inputRef.focus();
            }
            return;
        }

        const searchBarTextInput = document.getElementById(SEARCH_BAR_TEXT_INPUT_ID) as HTMLInputElement;
        const searchQuery = (searchBarTextInput && searchBarTextInput.value) || '';
        const glassboxLoggingPromise = glassboxPreziAirWeb.logSearchVideo({
            search_term: searchQuery,
            search_mode: 'VIEW_PAGE',
        });
        // Wait at most 0.5s for Glassbox logger to fire off before proceeding
        const glassboxTimeoutPromise = getGlassboxTimeoutPromise();
        Promise.race([glassboxLoggingPromise, glassboxTimeoutPromise]).finally(() => {
            if (this.props.handleSearchTerm) {
                this.props.handleSearchTerm(searchQuery);
            } else {
                window.location.assign(`/video/explore/search/?search_term=${searchQuery}`);
            }
        });
    };

    onClickLogo = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        event.preventDefault();
        let glassboxLoggingPromise;
        let nextPageUrl: string;

        if (this.props.loggedIn) {
            glassboxLoggingPromise = glassboxPreziAirWeb.logVisitVideoDashboard();
            nextPageUrl = '/dashboard/next/#/videos/';
        } else {
            glassboxLoggingPromise = glassboxPreziAirWeb.logVisitVideoHomepage();
            nextPageUrl = '/video/';
        }
        // Wait at most 0.5s for Glassbox logger to fire off before proceeding
        const glassboxTimeoutPromise = getGlassboxTimeoutPromise();
        Promise.race([glassboxLoggingPromise, glassboxTimeoutPromise]).finally(() => {
            window.location.assign(nextPageUrl);
        });
    };

    setSearchExpanded = (expanded: boolean) => {
        if (!expanded) {
            this.searchBarButtonRef?.focus();
        }
        this.setState({ mobileSearchExpanded: expanded });
    };

    getLogo = () => {
        if (this.state.mobileSearchExpanded) {
            return null;
        }

        // tslint:disable:max-line-length
        const src =
            'https://assets.prezicdn.net/assets-versioned/prototypes-versioned/467-36b285dd50e7a3d58f79bc02f2bb98a8e855db09/prototypes/video/explore/v1/img/prezi_video_logo.svg';
        return (
            <LogoLink>
                <a
                    onClick={this.onClickLogo}
                    href="/video/"
                    title={this.props.searchBarData.homePageLinkTitle ?? 'Prezi Video homepage'}
                >
                    <LogoImg src={src} className="logo-img" alt="Prezi logo" />
                </a>
            </LogoLink>
        );
    };

    getNavBarLinks = () => {
        const { loggedIn, viewerInfo, links, getStartedCTA, createYourOwnCTA } = this.props;
        const { toggleMenu, mobileSearchExpanded } = this.state;
        const dropDownClass = toggleMenu ? 'open-pull-down' : 'close-pull-down';

        if (mobileSearchExpanded) {
            return null;
        }

        if (loggedIn) {
            return (
                <NavBarLinks>
                    {/video\/explore/.test(document.location.pathname) && (
                        <CreateButton type="button" onClick={this.onCreateButtonClick} href={CREATE_YOURS_URL}>
                            {createYourOwnCTA}
                        </CreateButton>
                    )}
                    <div
                        className="profile-dropdown"
                        role="menu"
                        ref={node => (this.node = node)}
                        onClick={this.toggleDropdownMenu}
                        tabIndex={0}
                        onKeyPress={event => {
                            if (event.key === 'Enter') {
                                this.toggleDropdownMenu(event);
                            }
                        }}
                    >
                        <div data-toggle="profile-dropdown">
                            <NameContainer>
                                <LoggedInNavName className="name">
                                    {viewerInfo && viewerInfo.viewerName}
                                </LoggedInNavName>
                                <DownArrowNav className="icon-triangle-down" />
                            </NameContainer>
                        </div>
                    </div>
                    <div>
                        <OutsideClickHandler onOutsideClick={this.onOutsideClick}>
                            <DropdownMenu className={dropDownClass} aria-hidden={!this.state.toggleMenu}>
                                <div className="top-triangle-with-shadow" />
                                <div className="logged-in-dropdown-menu">
                                    <DropdownMenuItem
                                        className="your-page"
                                        href="/dashboard/next/?click_source=logged_element&amp;page_location=header_dropdown&amp;element_type=link&amp;element_text=my_prezis"
                                    >
                                        {links.myPrezisLinkText}
                                    </DropdownMenuItem>
                                    <DropdownMenuItem
                                        className="settings-page"
                                        href="/settings/?click_source=logged_element&amp;page_location=header_dropdown&amp;element_type=link&amp;element_text=account_settings"
                                    >
                                        {links.accountSettingsLinkText}
                                    </DropdownMenuItem>
                                    <DropdownMenuItem
                                        className="logout-page"
                                        data-logout="true"
                                        data-click-source="logged_element&amp;page_location=header_dropdown&amp;element_text=logout"
                                        href="/logout"
                                    >
                                        {links.logoutLinkText}
                                    </DropdownMenuItem>
                                </div>
                            </DropdownMenu>
                        </OutsideClickHandler>
                    </div>
                </NavBarLinks>
            );
        }

        return (
            <NavBarLinks>
                <div>
                    <LoginLink href="/login/?next=/dashboard/next/#/video">{links.loginLinkText}</LoginLink>
                    <CTAButton
                        type="button"
                        id="get-started-cta"
                        onClick={this.onCtaButtonClick}
                        data-lookup="header-get-started-cta"
                        href={this.ctaTarget}
                    >
                        {getStartedCTA}
                    </CTAButton>
                </div>
            </NavBarLinks>
        );
    };

    receiveInputRef = (ref: HTMLElement | null) => {
        if (ref) {
            this.inputRef = ref;
        }
    };

    receiveSearchBarButtonRef = (ref: HTMLButtonElement | null) => {
        if (ref) {
            this.searchBarButtonRef = ref;
        }
    };

    render() {
        const { searchBarData, showSearchOnMobile } = this.props;
        const { mobileSearchExpanded } = this.state;

        const mobileAriaTags =
            getSize(window.innerWidth) === ResponsiveMode.EXTRA_SMALL
                ? {
                      'aria-expanded': this.state.mobileSearchExpanded,
                      'aria-controls': SEARCH_BAR_TEXT_INPUT_ID,
                      'aria-label': this.state.mobileSearchExpanded
                          ? this.props.searchBarData.startSearchLabel
                          : this.props.searchBarData.openSearchBarButtonLabel,
                  }
                : undefined;

        return (
            <>
                <Overlay isOpen={mobileSearchExpanded} />
                <VideoHeader ref={this.props.forwardRef}>
                    <Row>
                        {this.getLogo()}
                        <SearchBar
                            textInputHeight={'40px'}
                            onFocus={handleOnFocus}
                            searchBarPlaceholderText={searchBarData.searchBarPlaceholder}
                            searchBarPlaceholderTextShort={searchBarData.searchBarPlaceholderShort}
                            searchBarButtonText={searchBarData.searchBarButton}
                            onQuerySubmit={this.onQuerySubmit}
                            searchTerm={searchBarData.searchTerm}
                            showSearchOnMobile={showSearchOnMobile}
                            mobileSearchExpanded={mobileSearchExpanded}
                            setSearchExpanded={this.setSearchExpanded}
                            receiveInputRef={this.receiveInputRef}
                            searchBarButton={
                                <SearchBarButtonSpan className="input-group-btn" top={'1px'} right={'40px'}>
                                    <SearchBarButton
                                        ref={this.receiveSearchBarButtonRef}
                                        className="btn-xs search-btn"
                                        type="button"
                                        height={'38px'}
                                        onClick={this.onQuerySubmit}
                                        name={this.props.searchBarData.startSearchLabel ?? 'Search'}
                                        aria-label={this.props.searchBarData.startSearchLabel ?? 'Search'}
                                        {...mobileAriaTags}
                                    >
                                        <span>
                                            <SearchIcon
                                                className="btn-icon icon-search"
                                                color={Colors.purple400}
                                                fontSize={'22px'}
                                                fontWeight={'500'}
                                            />
                                        </span>
                                    </SearchBarButton>
                                </SearchBarButtonSpan>
                            }
                            closeSearchBarButtonLabel={this.props.searchBarData.closeSearchBarButtonLabel}
                            startSearchLabel={this.props.searchBarData.startSearchLabel}
                            searchBarLabel={this.props.searchBarData.searchBarLabel}
                        />
                        {this.getNavBarLinks()}
                    </Row>
                </VideoHeader>
            </>
        );
    }
}
