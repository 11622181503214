import "core-js/modules/es.object.to-string";
import "core-js/modules/es.promise";
import "regenerator-runtime/runtime";
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }
function _iterableToArrayLimit(arr, i) { var _i = null == arr ? null : "undefined" != typeof Symbol && arr[Symbol.iterator] || arr["@@iterator"]; if (null != _i) { var _s, _e, _x, _r, _arr = [], _n = !0, _d = !1; try { if (_x = (_i = _i.call(arr)).next, 0 === i) { if (Object(_i) !== _i) return; _n = !1; } else for (; !(_n = (_s = _x.call(_i)).done) && (_arr.push(_s.value), _arr.length !== i); _n = !0) { ; } } catch (err) { _d = !0, _e = err; } finally { try { if (!_n && null != _i.return && (_r = _i.return(), Object(_r) !== _r)) return; } finally { if (_d) throw _e; } } return _arr; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function asyncGeneratorStep(gen, resolve, reject, _next, _throw, key, arg) { try { var info = gen[key](arg); var value = info.value; } catch (error) { reject(error); return; } if (info.done) { resolve(value); } else { Promise.resolve(value).then(_next, _throw); } }
function _asyncToGenerator(fn) { return function () { var self = this, args = arguments; return new Promise(function (resolve, reject) { var gen = fn.apply(self, args); function _next(value) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "next", value); } function _throw(err) { asyncGeneratorStep(gen, resolve, reject, _next, _throw, "throw", err); } _next(undefined); }); }; }
import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { EmbeddedCheckoutProvider, EmbeddedCheckout } from '@stripe/react-stripe-js';
import { getRequestHeaders } from '../../api/likes';
import styled from 'styled-components';
import CloseButton from './CloseButton';
import * as Cookies from 'es-cookie';
function getStripePublicKey() {
  if (typeof Site !== 'undefined' && Site.stripePublicKey) {
    return Site.stripePublicKey;
  }
  return undefined;
}
var stripePublicKey = getStripePublicKey();
var stripePromise = stripePublicKey ? loadStripe(stripePublicKey) : undefined;
function createCheckoutSession(_x, _x2) {
  return _createCheckoutSession.apply(this, arguments);
}
function _createCheckoutSession() {
  _createCheckoutSession = _asyncToGenerator(regeneratorRuntime.mark(function _callee(preziOid, preziTitle) {
    var headers;
    return regeneratorRuntime.wrap(function _callee$(_context) {
      while (1) {
        switch (_context.prev = _context.next) {
          case 0:
            headers = getRequestHeaders();
            return _context.abrupt("return", fetch('/pdf-access/create-checkout-session/', {
              method: 'POST',
              credentials: 'include',
              headers,
              body: JSON.stringify({
                'putma-id': Cookies.get('__putma') || null,
                'prezi-oid': preziOid,
                'prezi-title': preziTitle
              })
            }).then(res => res.json()));
          case 2:
          case "end":
            return _context.stop();
        }
      }
    }, _callee);
  }));
  return _createCheckoutSession.apply(this, arguments);
}
var ContentContainer = styled.div.withConfig({
  displayName: "StripeCheckoutForm__ContentContainer",
  componentId: "sc-1tgad58-0"
})(["width:856px;margin:auto;padding:0 0 10px 0;@media (max-width:993px){width:inherit;padding:0 17px 6px;}"]);
export var StripeCheckoutForm = props => {
  var _useState = useState(''),
    _useState2 = _slicedToArray(_useState, 2),
    clientSecret = _useState2[0],
    setClientSecret = _useState2[1];
  useEffect(() => {
    createCheckoutSession(props.preziOid, props.preziTitle).then(data => {
      setClientSecret(data.client_secret);
    }).catch(() => {
      setClientSecret(null);
    });
  }, []);
  return clientSecret != null && stripePromise ? React.createElement("div", {
    className: "modal-content"
  }, React.createElement("div", {
    className: "modal-header"
  }, React.createElement(CloseButton, props)), React.createElement("div", {
    id: "checkout"
  }, React.createElement(ContentContainer, null, React.createElement("h2", {
    className: "prezi-title bold btm-0"
  }, props.texts.title), props.texts.body), React.createElement(EmbeddedCheckoutProvider, {
    stripe: stripePromise,
    options: {
      clientSecret
    }
  }, React.createElement(EmbeddedCheckout, null)))) : React.createElement("div", {
    className: "modal-content"
  }, React.createElement("div", {
    className: "modal-header"
  }, React.createElement(CloseButton, props)), React.createElement(ContentContainer, null, React.createElement("div", {
    className: "pdf-error-container"
  }, React.createElement("div", null, " ", props.texts.errorMsg))));
};