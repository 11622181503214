import * as Cookies from 'es-cookie';
import { v4 as uuidv4 } from 'uuid';
const SINGLE_PAGE_SESSION_ID = uuidv4();
export function getSinglePageSessionId() {
  return SINGLE_PAGE_SESSION_ID;
}
export const getSessionDomainFromHost = hostname => {
  if (hostname.indexOf('localhost') > -1) {
    return 'localhost';
  }
  if (hostname.indexOf('.prezi.dev') > -1) {
    return '.prezi.dev';
  }
  return '.prezi.com';
};
export function isEmbed() {
  return window.appData?.embedMode;
}
export function isCookieDisabledDomain() {
  return window.location.hostname.indexOf('prezi-nocookies.com') > -1;
}
const THIRTY_MIN_IN_MILLISEC = 30 * 60 * 1000;
const SESSION_COOKIE_NAME = 'glassbox-session-id';
const setSessionCookie = sessionId => {
  const now = Date.now();
  const expiryDate = new Date(now + THIRTY_MIN_IN_MILLISEC);
  const newCookie = {
    session_id: sessionId
  };
  Cookies.set(SESSION_COOKIE_NAME, btoa(JSON.stringify(newCookie)), {
    expires: expiryDate,
    domain: getSessionDomainFromHost(window.location.hostname),
    sameSite: 'none',
    secure: true
  });
};
const EVENT_PREZI_COOKIE_CONSENT_CHANGED = 'prezi.cookie.consent.changed';
let cookieConsentEventListenerSet = false;
const setSessionCookieBasedOnConsent = sessionId => {
  window.addEventListener(EVENT_PREZI_COOKIE_CONSENT_CHANGED, event => {
    if (event.detail.performanceCookiesAllowed) {
      if (!Cookies.get(SESSION_COOKIE_NAME)) {
        setSessionCookie(sessionId);
      }
    } else {
      if (Cookies.get(SESSION_COOKIE_NAME)) {
        Cookies.remove(SESSION_COOKIE_NAME, {
          domain: getSessionDomainFromHost(window.location.hostname)
        });
      }
    }
  });
  cookieConsentEventListenerSet = true;
};
const setNewSessionId = sessionId => {
  if (isCookieDisabledDomain() || isEmbed()) {
    return sessionId;
  }
  if (window.cookieConsentNeeded) {
    if (cookieConsentEventListenerSet) {
      return sessionId;
    }
    setSessionCookieBasedOnConsent(sessionId);
  } else if (!Cookies.get(SESSION_COOKIE_NAME)) {
    setSessionCookie(sessionId);
  }
  return sessionId;
};
export const getSessionId = () => {
  let sessionId = Cookies.get(SESSION_COOKIE_NAME);
  if (!sessionId) {
    sessionId = getSinglePageSessionId();
  } else {
    try {
      const sessionCookie = JSON.parse(atob(sessionId));
      sessionId = sessionCookie.session_id;
    } catch (e) {}
  }
  return setNewSessionId(sessionId);
};
export const getReferrerUrl = () => {
  return document.referrer || null;
};
export const getPageUrl = () => {
  return window.location.href;
};
export const getPutmaId = () => {
  return Cookies.get('__putma') || null;
};