import React from 'react';
import CloseButton from './CloseButton';
import { ProgressIndicator } from '../ProgressIndicator';
var LoadingModal = props => {
  return React.createElement("div", {
    className: "modal-content"
  }, React.createElement("div", {
    className: "modal-header"
  }, React.createElement(CloseButton, props)), React.createElement(ProgressIndicator, {
    size: 80,
    progressPercentage: 70
  }));
};
export default LoadingModal;