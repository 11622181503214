import * as React from 'react';
import classnames from 'classnames';
import styled from 'styled-components';

interface OverlayDivProps {
    visibleZIndex?: number;
}

interface OverlayProps extends OverlayDivProps {
    isOpen: boolean;
}

const OverlayDiv = styled.div<OverlayDivProps>`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
    transition: background-color 0.25s;
    background-color: rgba(1, 1, 1, 0);

    &.showing {
        z-index: ${props => props.visibleZIndex || 4};
        background-color: rgba(1, 1, 1, 0.4);
    }
`;

const Overlay = (props: OverlayProps) => {
    return <OverlayDiv className={classnames({ showing: props.isOpen })} visibleZIndex={props.visibleZIndex} />;
};

export default Overlay;
