import styled from 'styled-components';
import { Colors, Fonts } from '@prezi/dragon';
import { WindowSizeBreakpoints } from '@prezi/common-components/src/common/consts';

export const NavBarLinks = styled.div`
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    display: flex;
    white-space: nowrap;

    @media screen and (max-width: ${WindowSizeBreakpoints.SMALL}px) {
        flex: 0;
        margin: 0 6px 0 9px;
    }
`;

export const LogoLink = styled.div`
    flex: 1;
    justify-content: flex-start;
    display: flex;

    @media screen and (max-width: ${WindowSizeBreakpoints.SMALL}px) {
        flex: 0;
    }

    a:active,
    a:focus {
        outline: 1px solid;
        outline-offset: 2px;
    }
`;

export const LoginLink = styled.a`
    margin-right: 18px;
    color: white;
    font-family: ${Fonts.ralewayBold};
    font-size: 13px;
    line-height: 16px;
    letter-spacing: 0.5px;
    margin-bottom: 0;

    &:hover {
        color: ${Colors.gray3};
        text-decoration: none;
        cursor: pointer;
    }

    @media (min-width: 992px) {
        margin-right: 30px;
    }

    @media screen and (max-width: ${WindowSizeBreakpoints.SMALL}px) {
        display: none;
    }
`;

export const LogoImg = styled.img`
    height: 36px;

    @media all and (max-width: 992px) {
        height: 24px;
    }
`;

export const Row = styled.div`
    display: flex;
    position: relative;
    width: 100%;
    height: 49px;
    align-items: center;
`;

export const LoggedInNavName = styled.p`
    font-size: 13px;
    line-height: 12px;
    letter-spacing: 1px;
    margin: 0;
`;

export const DownArrowNav = styled.i`
    font-size: 18px;
`;

export const VideoHeader = styled.header`
    height: 72px;
    text-align: center;
    padding: 16px 30px;
    background-color: ${Colors.purple600};
    display: flex;
    align-items: center;
    position: relative;
    z-index: 5;

    #search-bar-text-input {
        border-color: transparent;

        &:focus {
            background-color: ${Colors.white};
        }
    }

    #search-bar-text-input:hover {
        border-color: ${Colors.purple400};
    }

    @media all and (max-width: 992px) {
        height: 48px;
        padding-left: 15px;
        padding-right: 15px;
    }

    @media screen and (max-width: ${WindowSizeBreakpoints.SMALL}px) {
        padding: 0 9px 0px 15px;

        .input-group:not(.expanded) {
            #search-bar-text-input {
                background: rgba(255, 255, 255, 0.2);
            }

            .input-group-btn > button i {
                color: ${Colors.white};
            }
        }

        .input-group .input-group-btn > button i {
            color: ${Colors.purple500};
            font-size: 18px;
            font-weight: 600;
            transition: color 0.25s;
        }
    }

    @media screen and (min-width: ${WindowSizeBreakpoints.SMALL + 1}px) {
        .search-btn {
            background-color: ${Colors.white};
            border-color: ${Colors.white};

            :hover {
                background-color: ${Colors.white};
                border-color: ${Colors.white};
            }
        }
    }
`;

export const CTAButton = styled.a.attrs({ className: 'btn-xs get-started-page' })`
    background-color: ${Colors.purple500};
    border-radius: 15px;
    font-size: 16px;
    padding: 2px 22px;
    outline: none;
    white-space: nowrap;

    @media all and (max-width: 992px) {
        font-size: 12px;
        padding: 5px 22px;
    }

    @media all and (max-width: 768px) {
        border-radius: 18px;
        padding: 5px 12px;
    }

    :hover {
        background-color: ${Colors.purple400};
    }

    :active {
        top: 0;
    }
`;

export const CreateButton = styled(CTAButton)`
    font-size: 12px;
    margin: 0 15px;
    padding: 12px 24px;
    border-radius: 30px;

    @media all and (max-width: 768px) {
        display: none;
    }
`;
