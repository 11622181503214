import * as React from 'react';
import classNames from 'classnames';

import {
    CloseButton,
    SearchBarContainer,
    SearchBarTextInput,
    SearchPlaceholderStyle,
    SearchInputAndButton,
} from './Styles';
import { getSize, ResponsiveMode } from '@prezi/common-components';

const ENTER_KEY_CODE = 13;
export const SEARCH_BAR_TEXT_INPUT_ID = 'search-bar-text-input';

export interface SearchBarProps {
    onClickSearchInput?: (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => void;
    onQuerySubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
    textInputHeight: string;
    searchBarButton: React.ReactNode;
    searchBarButtonText?: string;
    searchBarPlaceholderText: string;
    searchBarPlaceholderTextShort: string;
    searchTerm?: string;
    showSearchOnMobile: boolean;
    mobileSearchExpanded: boolean;
    setSearchExpanded: (expanded: boolean) => void;
    receiveInputRef: (ref: HTMLElement | null) => void;
    closeSearchBarButtonLabel?: string;
    startSearchLabel?: string;
    searchBarLabel?: string;
}

export interface SearchBarState {
    responsiveMode: ResponsiveMode;
}

class SearchBar extends React.Component<SearchBarProps, SearchBarState> {
    constructor(props: any) {
        super(props);
        this.state = {
            responsiveMode: getSize(window.innerWidth),
        };
    }

    private updateResponsiveMode = () => {
        const responsiveMode = getSize(window.innerWidth);
        this.setState({ responsiveMode });
    };

    componentDidMount() {
        window.addEventListener('resize', this.updateResponsiveMode);
        this.updateResponsiveMode();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateResponsiveMode);
    }

    onInputSubmit = (event: any) => {
        if (event.keyCode === ENTER_KEY_CODE) {
            this.props.onQuerySubmit(event);
        }
    };

    onFocus = (event: React.FocusEvent<HTMLInputElement>) => {
        if (this.props.onFocus) {
            this.props.onFocus(event);
        }
    };

    getSearchCloseButton = () => {
        if (!this.props.mobileSearchExpanded) {
            return null;
        }

        return (
            <CloseButton
                aria-label={this.props.closeSearchBarButtonLabel ?? 'Close search bar'}
                onClick={() => {
                    this.props.setSearchExpanded(false);
                }}
                aria-controls={SEARCH_BAR_TEXT_INPUT_ID}
                aria-expanded={this.props.mobileSearchExpanded}
            >
                <i className="btn-icon icon-close" />
            </CloseButton>
        );
    };

    getTextForResponsiveMode = (responsiveMode: ResponsiveMode) => {
        const isMediumOrLarge: boolean =
            responsiveMode === ResponsiveMode.MEDIUM || responsiveMode === ResponsiveMode.LARGE;

        if (this.isMobileAndClosed()) {
            return { placeholder: '', label: this.props.startSearchLabel ?? 'Start search' };
        } else if (isMediumOrLarge) {
            return {
                placeholder: this.props.searchBarPlaceholderText,
                label: this.props.searchBarLabel ?? 'Search bar',
            };
        } else {
            return {
                placeholder: this.props.searchBarPlaceholderTextShort,
                label: this.props.searchBarLabel ?? 'Search bar',
            };
        }
    };

    isMobileAndClosed = () => {
        return this.state.responsiveMode === ResponsiveMode.EXTRA_SMALL && !this.props.mobileSearchExpanded;
    };

    render() {
        const { mobileSearchExpanded, showSearchOnMobile } = this.props;
        const inputClasses = classNames('p-form-control search-placeholder-style search-input', {
            expanded: mobileSearchExpanded,
        });
        const searchTerm = this.isMobileAndClosed() ? '' : this.props.searchTerm || '';
        const { placeholder, label } = this.getTextForResponsiveMode(this.state.responsiveMode);

        return (
            <SearchBarContainer className={classNames('input-group', { expanded: mobileSearchExpanded })} role="search">
                <SearchInputAndButton className={classNames({ 'hidden-xs': !showSearchOnMobile })}>
                    <SearchPlaceholderStyle />
                    <SearchBarTextInput
                        height={this.props.textInputHeight}
                        id={SEARCH_BAR_TEXT_INPUT_ID}
                        className={inputClasses}
                        placeholder={placeholder}
                        onKeyDown={this.onInputSubmit}
                        onClick={this.props.onClickSearchInput}
                        defaultValue={searchTerm}
                        onFocus={this.props.onFocus}
                        ref={this.props.receiveInputRef}
                        aria-label={label}
                        disabled={
                            this.state.responsiveMode === ResponsiveMode.EXTRA_SMALL ? !mobileSearchExpanded : false
                        }
                    />
                    {this.props.searchBarButton}
                </SearchInputAndButton>
                {this.getSearchCloseButton()}
            </SearchBarContainer>
        );
    }
}

export default SearchBar;
