import axios from 'axios';
import { getSessionId, getReferrerUrl, getSinglePageSessionId, getPageUrl, getPutmaId, isCookieDisabledDomain } from './utils';
import { applyMiddleware } from './middleware';
const LOG_ENDPOINT = 'https://bandar-logger.prezi.com/log/json';
const FALLBACK_LOG_ENDPONT = 'https://bandar-logger.prezi.com/fallback/json';
export function logGlassbox(logMessage, {
  useFallback = false,
  disableBeacon = false
} = {}) {
  if (isCookieDisabledDomain()) {
    return Promise.resolve(true);
  }
  const requestFromPreprod = window.location.hostname.indexOf('preprod') > -1;
  const requestFromLocal = window.location.hostname.indexOf('localhost') > -1;
  const requestFromPde = window.location.hostname.indexOf('.prezi.dev') > -1;
  const logMessageTransformed = applyMiddleware(logMessage);
  if (!logMessageTransformed) {
    return Promise.resolve(false);
  }
  if (requestFromPreprod || requestFromLocal || requestFromPde) {
    if (process.env.NODE_ENV !== 'test') {
      console.log('glassbox', logMessage.core.event_type, JSON.stringify(logMessage));
    }
    return Promise.resolve(true);
  } else {
    if (!disableBeacon && window.navigator && window.navigator.sendBeacon) {
      return Promise.resolve(navigator.sendBeacon(LOG_ENDPOINT, JSON.stringify([logMessage])));
    } else {
      const endpoint = useFallback ? FALLBACK_LOG_ENDPONT : LOG_ENDPOINT;
      return axios.post(endpoint, [logMessage], {
        headers: {
          'Content-Type': 'application/json'
        },
        responseType: 'json',
        timeout: 20000,
        withCredentials: true
      }).then(() => true).catch(() => false);
    }
  }
}
function getCommonPayload(type, schemaId, eventSource, eventType, eventName, platformType, params) {
  return {
    core: {
      type,
      schema_id: schemaId,
      client_time: new Date().getTime(),
      event_source: eventSource,
      event_type: eventType
    },
    event_source: {
      single_page_session_id: getSinglePageSessionId()
    },
    platform: {
      type: platformType,
      putma_id: getPutmaId(),
      referer: getReferrerUrl(),
      url: getPageUrl()
    },
    user: {
      session_id: getSessionId()
    },
    body: {
      event_name: eventName,
      ...params
    }
  };
}
function getZoomAppPayload(type, schemaId, eventSource, eventType, eventName, platformType, params) {
  return {
    core: {
      type,
      schema_id: schemaId,
      client_time: new Date().getTime(),
      event_source: eventSource,
      event_type: eventType
    },
    event_source: {},
    platform: {
      type: platformType,
      os: window.ZOOM_APP_GLOBAL_CONFIG?.os,
      os_version: window.ZOOM_APP_GLOBAL_CONFIG?.os_version,
      user_agent: window.ZOOM_APP_GLOBAL_CONFIG?.user_agent,
      zoomapp_version: window.ZOOM_APP_GLOBAL_CONFIG?.zoomapp_version,
      zoom_version: window.ZOOM_APP_GLOBAL_CONFIG?.zoom_version
    },
    user: {
      session_id: getSessionId(),
      meeting_id: window.ZOOM_APP_GLOBAL_CONFIG?.meeting_id,
      zoomapp_user_id: window.ZOOM_APP_GLOBAL_CONFIG?.zoomapp_user_id
    },
    body: {
      event_name: eventName,
      ...params
    }
  };
}
export function buildGlassboxPayload(type, schemaId, eventSource, eventType, eventName, platformType, params) {
  switch (type) {
    case 'ZoomAppAnalytics':
      return getZoomAppPayload(type, schemaId, eventSource, eventType, eventName, platformType, params);
    default:
      return getCommonPayload(type, schemaId, eventSource, eventType, eventName, platformType, params);
  }
}